<template>

    <label class="form-label fs-7">Source</label>

    <el-select
        v-model="source"
        filterable
        reserve-keyword
        clearable
        placeholder="Select source..."
    >
        <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
        >{{ item.label }}
        </el-option>
    </el-select>

</template>

<script setup>
import {ref, watch, onMounted} from 'vue'

// Define props and emits
const props = defineProps({
    fetchUrl: String,
    source: String,
})

const emits = defineEmits(['update:source'])

// Define reactive properties using ref
const source = ref(props.source || '')

// Watch for changes in formData and emit them
watch(() => props.source, (newSource) => {
    source.value = newSource
})
watch(() => source.value, (newSource) => {
    emits('update:source', newSource)
})

/**
 * Remote fetch
 * */
const options = ref([])

// Fetch users data
onMounted(async () => {
    if (props.fetchUrl) {
        try {
            const response = await fetch(props.fetchUrl)
            if (response.ok) {
                const data = await response.json()
                options.value = data.map(item => ({
                    value: item.id,
                    label: item.name,
                }))
            } else {
                console.error('Fetch failed:', response.statusText)
            }
        } catch (error) {
            console.error('Fetch error:', error)
        }
    }
})
</script>
