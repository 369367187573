<template>

    <label class="form-label fs-7">Stock</label>

    <el-input-number
        v-model="stock"
        class="w-100"
    />

</template>

<script setup>
import { ref, watch } from 'vue'

// Define props
const props = defineProps({
    stock: String,
})


// Define emits
const emits = defineEmits(['update:stock'])


// Define refs
let stock = ref(props.stock || '')


// Update the local stock when the prop changes
watch(() => props.stock, (newStock) => {
    stock.value = newStock
})


// Watch for changes and emit them
watch(() => stock.value, (newStock) => {
    emits('update:stock', newStock)
})
</script>

