<template>
    <el-select
        v-model="sellingPriceUnit"
        filterable
        reserve-keyword
        :clearable="!props.required"
        placeholder="Unit..."
        style="width: 115px"
    >
        <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
        >{{ item.label }}
        </el-option>
    </el-select>
</template>

<script setup>
import { ref, watch, onMounted } from 'vue'

// Define props and emits
const props = defineProps({
    fetchUrl: String,
    required: { type: Boolean, default: false },
    sellingPriceUnit: String,
})

const emits = defineEmits(['update:sellingPriceUnit'])

// Define reactive properties using ref
const sellingPriceUnit = ref(props.sellingPriceUnit || '')

// Watch for changes in formData and emit them
watch(() => sellingPriceUnit.value, (newSellingPriceUnit) => {
    emits('update:sellingPriceUnit', newSellingPriceUnit)
})

watch(() => props.sellingPriceUnit, (newSellingPriceUnit) => {
    sellingPriceUnit.value = newSellingPriceUnit;
})

/**
 * Remote fetch
 * */
const options = ref([])

// Fetch users data
onMounted(async () => {
    if (props.fetchUrl) {
        try {
            const response = await fetch(props.fetchUrl)
            if (response.ok) {
                const data = await response.json()
                options.value = data.map(item => ({
                    value: item.value,
                    label: item.label,
                }))
            } else {
                console.error('Fetch failed:', response.statusText)
            }
        } catch (error) {
            console.error('Fetch error:', error)
        }
    }
})
</script>
