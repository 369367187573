<template>

    <label class="form-label fs-7">Project</label>

    <el-select
        v-model="project"
        ref="projectSelectRef"
        filterable
        clearable
        default-first-option
        :reserve-keyword="false"
        placeholder="Search for project..."

        :remote="isRemoteEnabled"
        :remote-method="remoteMethod"
        :loading="loading"
        loading-text="Loading projects..."
        no-data-text="No projects found."

        @focus="updateBadge"
        @blur="updateBadge"
        @visible-change="updateBadge"
        @change="updateProject"

        @input="searchText = $event.target.value"

    >

        <el-option
            v-for="item in projectOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
        >
            {{ item.label }} <span v-if="item.isNew" class="new-badge ms-2 badge badge-sm badge-primary">New</span>
        </el-option>

        <template #footer v-if="searchText && (props.company || props.contact)">
            <a class="btn btn-sm text-dark text-hover-primary m-0 p-0 d-flex align-items-center" @click="addAndSelectSearchText">
                <i class="ki-solid ki-plus me-0 text-dark"/>
                Add <span class="fw-bolder mx-1">'{{ searchText }}'</span> as new project
            </a>
        </template>

    </el-select>

</template>

<script setup>
import { computed, nextTick, onMounted, ref, watch } from 'vue'
import axios from 'axios'


// Define props
const props = defineProps({

    fetchUrl: String,

    project: String,

    company: String,
    companyIsNew: Boolean,

    contact: String,
    contactIsNew: Boolean,

    deal: String,
    dealIsNew: Boolean,

})


// Define refs
const project = ref(props.project || '')
const projectIsNew = ref(false)

const searchText = ref('')
const loading = ref(false)

const projectSelectRef = ref()
const projectOptions = ref([])


// Define emits
const emit = defineEmits([
    'update:project',
    'update:projectIsNew',
    'update:company',
    'update:contact',
    'update:deal',
])


// Define computed
const customerType = computed(() => props.company ? 'company' : 'contact')
const isRemoteEnabled = computed(() => {

    if (props.companyIsNew && props.contactIsNew && props.dealIsNew){
        return false;
    }

    if (props.company || props.contact || props.deal){
        return false;
    }

    return true;

})


// Define methods
const fetchProjects = async (search) => {

    try {

        let params = {}
        if (!isRemoteEnabled.value){
            if (props.company && !props.companyIsNew){
                params.company = props.company
            }
            if (props.contact && !props.contactIsNew){
                params.contact = props.contact
            }
            if (props.deal && !props.dealIsNew){
                params.deal = props.deal
            }
        } else {
            params.search = search
        }

        let projects = [];

        await axios.get(props.fetchUrl, { params: params }).then(response => {
            const data = response.data

            projects = data.map(item => ({
                value: item.id,
                label: item.name,
                company: item.company,
                contact: item.contact,
                deal: item.deal,
                isNew: false,
            }))

        }).catch(error => {
            console.error('Fout bij het ophalen van gegevens', error)
        })

        // Check if current project is new
        if (projectIsNew.value) {
            projects.push({
                value: project.value,
                label: project.value,
                isNew: true,
            })
        }

        return projects

    } catch (error) {
        console.error('Error fetching projects:', error)
        return []
    }
}

const remoteMethod = async (search) => {
    if (!search) return
    if (props.companyIsNew && props.contactIsNew && props.dealIsNew) return

    // Search for results
    loading.value = true
    projectOptions.value = await fetchProjects(search)
    loading.value = false

}

const addAndSelectSearchText = () => {

    // Prepare new project
    const newProject = searchText.value

    // Update refs
    projectIsNew.value = true
    project.value = newProject
    searchText.value = null

    // Blur
    nextTick(() => {
        projectSelectRef.value.blur()
    })

    // Set isNew property to true for the new project
    projectOptions.value.push({
        value: newProject,
        label: newProject,
        isNew: true,
    })

    // update badge
    updateBadge()

    // Emit changes
    emit('update:project', project.value)
    emit('update:projectIsNew', projectIsNew.value)
}

const updateProject = (value) => {

    // Filter out new
    projectOptions.value = projectOptions.value.filter(projectOption => !projectOption.isNew)

    // Find selected option
    const selectedOption = projectOptions.value.find(projectOption => projectOption.value === project.value)

    // Update refs
    searchText.value = null
    project.value = value
    projectIsNew.value = selectedOption?.isNew

    // Emits
    emit('update:project', value)
    emit('update:projectIsNew', projectIsNew.value)
    if (selectedOption?.company) emit('update:company', selectedOption?.company)
    if (selectedOption?.contact) emit('update:contact', selectedOption?.contact)
    if (selectedOption?.deal) emit('update:deal', selectedOption?.deal)
}

const updateBadge = () => {
    nextTick(() => {

        // Get placeholder
        const placeholder = projectSelectRef.value?.$el.querySelector('.el-select__placeholder')
        if (!placeholder) return

        // Check if current project is new
        const selectedOption = projectOptions.value.find(projectOption => projectOption.value === project.value)
        if (project.value && selectedOption && selectedOption.isNew) {

            // Set new project to true
            projectIsNew.value = true

            // Add classes
            placeholder.classList.add('d-flex', 'align-items-center', 'justify-content-between')

            // Check if badge exist
            const existingBadge = placeholder.querySelector('.new-badge')
            if (existingBadge) {
                return
            }

            // Add new badge
            const newBadge = document.createElement('span')
            newBadge.className = 'new-badge ms-2 badge badge-sm badge-primary'
            newBadge.textContent = 'New'
            placeholder.appendChild(newBadge)

            return
        }

        // Set new project to false
        projectIsNew.value = false

        // Remove existing badge
        const existingBadge = placeholder.querySelector('.new-badge')
        if (existingBadge) {
            placeholder.classList.remove('d-flex', 'align-items-center', 'justify-content-between')
            existingBadge.remove()
        }

    })
}


// Define watchers
watch(() => [props.company, props.contact, props.project], async () => {

    // Check if company and contact are empty
    if (!props.company && !props.contact) {

        // Reset refs
        project.value = ''
        projectIsNew.value = false
        projectOptions.value = [];
        searchText.value = '';

        // Emit changes
        emit('update:project', project.value)
        emit('update:projectIsNew', projectIsNew.value)

        // Update badge
        updateBadge()

        return

    }

    // Check if project present
    if (project.value) {

        // Find selected option
        const selectedOption = projectOptions.value.find(projectOption => projectOption.value === project.value)

        // Check if selected option has any relationship with customers
        if (!selectedOption.isNew && ((selectedOption.company !== props.company) && (selectedOption.contact !== props.contact))){

            // Reset refs
            project.value = ''
            projectIsNew.value = false
            projectOptions.value = [];
            searchText.value = '';

            // Emit changes
            emit('update:project', project.value)
            emit('update:projectIsNew', projectIsNew.value)

            // Update badge
            updateBadge()

            return

        }

    }

    loading.value = true
    projectOptions.value = await fetchProjects()
    loading.value = false
})


// Define on mounted
onMounted(async () => {

    // TODO

})


</script>
