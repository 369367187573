<template>

    <label class="form-label fs-7">Language</label>

    <el-select
        v-model="language"
        filterable
        reserve-keyword
        clearable
        placeholder="Select language..."
    >
        <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
        >{{ item.label }}
        </el-option>
    </el-select>

</template>

<script setup>
import {ref, watch, onMounted} from 'vue'

// Define props and emits
const props = defineProps({
    fetchUrl: String,
    language: String,
})

const emits = defineEmits(['update:language'])

// Define reactive properties using ref
const language = ref(props.language || '')

// Watch for changes in formData and emit them
watch(() => props.language, (value) => {
    language.value = value
})
watch(() => language.value, (value) => {
    emits('update:language', value)
})

/**
 * Remote fetch
 * */
const options = ref([])

// Fetch users data
onMounted(async () => {
    if (props.fetchUrl) {
        try {
            const response = await fetch(props.fetchUrl)
            if (response.ok) {
                const data = await response.json()
                options.value = data.map(item => ({
                    value: item.value,
                    label: item.label,
                }))
            } else {
                console.error('Fetch failed:', response.statusText)
            }
        } catch (error) {
            console.error('Fetch error:', error)
        }
    }
})
</script>
