<template>

    <div
        v-if="!props.minimizedOnly"
        id="kt_app_sidebar_toggle"
        class="app-sidebar-toggle btn btn-icon btn-shadow btn-sm btn-color-muted btn-active-color-primary body-bg h-30px w-30px position-absolute top-50 start-100 translate-middle rotate"
        data-kt-toggle="true"
        data-kt-toggle-state="active"
        data-kt-toggle-target="body"
        data-kt-toggle-name="app-sidebar-minimize"
    >
        <i class="ki-duotone ki-black-left-line fs-3 rotate-180">
            <span class="path1"></span>
            <span class="path2"></span>
        </i>
    </div>

</template>

<script setup>
import { onMounted, nextTick } from 'vue'
import { router, usePage } from '@inertiajs/vue3'

// Define variables
const page = usePage()

// Define props
const props = defineProps({
    minimizedOnly: Boolean,
})


// Define methods
function getCookie(name) {
    const value = `; ${document.cookie}`
    const parts = value.split(`; ${name}=`)
    if (parts.length === 2) return parts.pop().split(';').shift()
    return null
}

function updateSidebar(minimized) {
    nextTick(() => {
        const body = document.body
        const toggleButton = document.getElementById('kt_app_sidebar_toggle')

        if (minimized) {
            body.setAttribute('data-kt-app-sidebar-minimize', 'on')
            if (toggleButton) {
                toggleButton.setAttribute('data-kt-toggle-state', 'active')
                toggleButton.classList.add('active')
            }
        } else {
            body.removeAttribute('data-kt-app-sidebar-minimize')
            if (toggleButton) {
                toggleButton.setAttribute('data-kt-toggle-state', 'inactive')
                toggleButton.classList.remove('active')
            }
        }
    })
}

// Define router
router.on('finish', (event) => {

    const body = document.body

    if (props.minimizedOnly) {

        body.setAttribute('data-kt-app-sidebar-minimize', 'on')
        body.removeAttribute('data-kt-app-sidebar-hoverable')

    } else {

        body.removeAttribute('data-kt-app-sidebar-minimize')
        body.setAttribute('data-kt-app-sidebar-hoverable', 'true')

        const minimized = getCookie('sidebar_minimize_state') === 'on'
        updateSidebar(minimized)

    }

})

onMounted(() => {

    const body = document.body

    if (props.minimizedOnly) {

        nextTick(() => {
            body.setAttribute('data-kt-app-sidebar-minimize', 'on')
            body.removeAttribute('data-kt-app-sidebar-hoverable')
        })

    } else {

        body.removeAttribute('data-kt-app-sidebar-minimize')
        body.setAttribute('data-kt-app-sidebar-hoverable', 'true')

        const minimized = getCookie('sidebar_minimize_state') === 'on'
        updateSidebar(minimized)

    }

})

</script>
