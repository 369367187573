<template>
    <a href="#" class="d-flex align-items-center fs-7 text-primary" @click.prevent="addAddress">
        <i class="ki-duotone ki-plus fs-6 text-primary"></i>
        Add Address
    </a>
</template>

<script setup>

// Define emits
const emits = defineEmits(['addAddress'])

// Define methods
function addAddress() {
    emits('addAddress')
}

</script>

