<template>

    <el-select
        v-model="value"
        filterable
        multiple
        collapse-tags
        collapse-tags-tooltip
        :placeholder="computedPlaceholder"
        :required="props.required"
        :clearable="!props.required"
    >
        <el-option
            v-for="option in props.options"
            :key="option.id"
            :label="option.value"
            :value="option.id"
        />
    </el-select>

</template>

<script setup>
import { computed, ref, watch } from 'vue'


// Define props
const props = defineProps({
    value: String,
    placeholder: String,
    required: Boolean,
    options: Array,
})


// Define emits
const emits = defineEmits(['update:value'])


// Define refs
let value = ref(props.value || '')


// Define computed
const computedPlaceholder = computed(() => {
    return props.placeholder || 'Select options...';
})


// Define watchers
watch(() => props.value, (newValue) => {
    value.value = newValue
})

watch(() => value.value, (newValue) => {
    emits('update:value', newValue)
})

</script>
