<template>

    <label class="form-label fs-7">Sku</label>

    <input
        v-model="sku"
        type="text"
        class="form-control form-control-sm"
        placeholder="Enter sku..."
    />
</template>

<script setup>
import { ref, watch } from 'vue'

// Define props
const props = defineProps({
    sku: String,
})


// Define emits
const emits = defineEmits(['update:sku'])


// Define refs
let sku = ref(props.sku || '')


// Update the local sku when the prop changes
watch(() => props.sku, (newSku) => {
    sku.value = newSku
})


// Watch for changes and emit them
watch(() => sku.value, (newSku) => {
    emits('update:sku', newSku)
})
</script>
