<template>
    <Company
        v-for="(company, index) in companies"
        :key="index"
        :company="company.company"
        :position="company.position"
        :primary="company.primary"
        :decisionMaker="company.decisionMaker"
        :fetchUrl="props.fetchUrl"
        :errors="getErrors(index)"
        @update:company="updateCompany(index, $event)"
        @delete:company="deleteCompany(index)"
    />

    <AddButton @addCompany="addCompany"/>
</template>


<script setup>
import { ref, watch } from 'vue'
import AddButton from '@/Shares/App/Modules/Contacts/Modals/Partials/Form/Companies/AddButton.vue'
import Company from '@/Shares/App/Modules/Contacts/Modals/Partials/Form/Companies/Company.vue'
import Contact from '@/Shares/App/Modules/Companies/Modals/Partials/Form/Contacts/Contact.vue'
import Email from '@/Shares/App/Modules/Companies/Modals/Partials/Form/Emails/Email.vue'


// Define props
const props = defineProps({
    companies: Array,
    fetchUrl: String,
    errors: Array,
})


// Define refs
let companies = ref(props.companies ?? [])


// Define emits
const emits = defineEmits(['update:companies'])


// Define watchers
watch(() => props.companies, (value) => {
    companies.value = value
}, { deep: true })

watch(() => companies.value, (value) => {
    emits('update:companies', value)
}, { deep: true })


// Define methods
function addCompany() {
    companies.value.push({ 'company': null, 'position': null, 'primary': false, 'decisionMaker': false })
}

function updateCompany(index, updatedCompany) {
    companies.value[index] = updatedCompany
}

function deleteCompany(index) {
    companies.value.splice(index, 1)
}

function getErrors(index) {
    const errors = {};
    for (const [key, value] of Object.entries(props.errors)) {
        if (key.startsWith(`companies.${index}`)) {
            const newKey = key.replace(`companies.${index}.`, '');
            errors[newKey] = value;
        }
    }
    return errors;
}


</script>
