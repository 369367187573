<template>

    <el-checkbox
        v-model="value"
        :label="props.name"
        :placeholder="computedPlaceholder"
        :required="props.required"
        border
        class="w-100"
    />

</template>

<script setup>
import { computed, ref, watch } from 'vue'


// Define props
const props = defineProps({
    name: String,
    value: String,
    placeholder: String,
    required: Boolean,
})


// Define emits
const emits = defineEmits(['update:value'])


// Define refs
let value = ref(props.value || '')


// Define computed
const computedPlaceholder = computed(() => {
    return props.placeholder || 'Select option...';
})


// Define watchers
watch(() => props.value, (newValue) => {
    value.value = newValue
})

watch(() => value.value, (newValue) => {
    emits('update:value', newValue)
})

</script>
