<template>

    <label class="form-label fs-7">End at</label>

    <el-date-picker
        v-model="endAt"
        type="date"
        placeholder="Select a date"
        class="me-2 w-100 hide-icon"
        format="DD MMM. YYYY"
        value-format="YYYY-MM-DD"
        :disabled-date="datePickerOptions.disabledDate"
    />

</template>
<script setup>
import { ref, watch, reactive } from 'vue'


// Define props
const props = defineProps({
    endAt: [String, Date],
    startAt: [String, Date],
})

// Define emits
const emits = defineEmits(['update:endAt'])


// Define refs
let endAt = ref(props.endAt ? formatDate(new Date(props.endAt)) : '')
let startAt = ref(props.startAt || new Date())
const datePickerOptions = reactive({
    disabledDate(date) {
        // Zet startAt.value om naar het begin van de dag
        const startDate = new Date(startAt.value);
        startDate.setHours(0, 0, 0, 0);

        // Zet de gecontroleerde date ook om naar het begin van de dag voor een correcte vergelijking
        const currentDate = new Date(date);
        currentDate.setHours(0, 0, 0, 0);

        // Sta toe dat de einddatum op dezelfde dag valt als de startdatum
        return currentDate < startDate;
    }
});

// Define watchers
watch(() => props.endAt, (newEndAt) => {
    endAt.value = newEndAt
})
watch(() => props.startAt, (newStartAt) => {
    startAt.value = newStartAt
})

watch(() => endAt.value, (newEndAt) => {
    emits('update:endAt', newEndAt)
})

function formatDate(value) {
    const year = value.getFullYear().toString()
    const month = (value.getMonth() + 1).toString().padStart(2, '0') // Maanden zijn 0-geïndexeerd
    const day = value.getDate().toString().padStart(2, '0')
    return `${year}-${month}-${day}`
}

</script>
