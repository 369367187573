<template>

    <div class="menu menu-rounded menu-column menu-lg-row my-5 my-lg-0 align-items-stretch fw-semibold px-2 px-lg-0" id="kt_app_header_menu">

        <SubscriptionChoose v-if="$hasPermission('account_access') && page.props.auth.account.subscription.status === 'trial' && !subscriptionPage"/>

        <PlanDemo v-if="page.props.auth.account.subscription.status === 'trial'"/>

        <TrialDaysLeft v-if="page.props.auth.account.subscription.status === 'trial'"/>

    </div>

</template>

<script setup>
import SubscriptionChoose from '@/Layouts/App/Layout/Header/Menu/SubscriptionChoose.vue'
import { router, usePage } from '@inertiajs/vue3'
import PlanDemo from '@/Layouts/App/Layout/Header/Menu/PlanDemo.vue'
import TrialDaysLeft from '@/Layouts/App/Layout/Header/Menu/TrialDaysLeft.vue'
import { computed, getCurrentInstance, onMounted } from 'vue'

// Define variables
const internalInstance = getCurrentInstance();
const globalProperties = internalInstance.appContext.config.globalProperties;
const page = usePage();

// Define emits
const emits = defineEmits(['menu-items-are-visible'])

// Define computed
const subscriptionPage = computed(() => {
    return page.url.startsWith('/subscription')
})


// Define methods
const checkIfMenuItemAreVisible = () => {

    // Emit if any menu item is visible
    if (
        (globalProperties.$hasPermission('account_access') && page.props.auth.account.subscription.status === 'trial' && !subscriptionPage) ||
        (page.props.auth.account.subscription.status === 'trial') ||
        (page.props.auth.account.subscription.status === 'trial')
    ){
        emits('menu-items-are-visible', true)
    } else {
        emits('menu-items-are-visible', false)
    }

}

// Define on mounted
onMounted(() => {

    // Emit if any menu item is visible
    checkIfMenuItemAreVisible()

})

// Define on mounted
router.on('finish', () => {

    // Emit if any menu item is visible
    checkIfMenuItemAreVisible()

})

</script>
