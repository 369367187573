<template>

    <Link v-if="!trialExpired" href="/">
        <img alt="Logo" src="/assets/media/logos/default_wit.svg" class="h-30px app-sidebar-logo-default" />
        <img alt="Logo" src="/assets/media/logos/favicon.svg" class="h-25px app-sidebar-logo-minimize" />
    </Link>


    <div v-if="trialExpired">
        <img alt="Logo" src="/assets/media/logos/default_wit.svg" class="h-30px app-sidebar-logo-default" />
        <img alt="Logo" src="/assets/media/logos/favicon.svg" class="h-25px app-sidebar-logo-minimize" />
    </div>

</template>


<script setup>
import { computed } from 'vue'
import { usePage } from '@inertiajs/vue3'


// Define variables
const page = usePage();


// Define computed
const trialExpired = computed(() => {
    return page.props.auth.account.subscription.status === 'trial_expired'
})


</script>
