<template>

    <label class="form-label fs-7 required">Phase</label>

    <el-select
        v-model="phase"
        filterable
        reserve-keyword
        placeholder="Phase..."
        @change="updatePhase"
    >
        <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
        >{{ item.label }}
        </el-option>
    </el-select>

</template>

<script setup>
import { onMounted, ref, watch } from 'vue'
import axios from 'axios'


// Define props
const props = defineProps({
    fetchUrl: String,
    type: String,
    phase: String,
})


// Define emits
const emits = defineEmits(['update:phase', 'update:status'])


// Define refs
const phase = ref(props.phase || '')
const options = ref([])


// Define watchers
watch(() => props.phase, (newPhase) => {
    phase.value = newPhase
})

watch(() => [props.type, props.fetchUrl], () => {
    fetchData()
})


// Define methods
async function fetchData() {

    if (props.fetchUrl) {

        axios.get(props.fetchUrl, { params: { type: props.type } }).then(response => {
            const data = response.data

            options.value = data.map(item => ({
                value: item.id,
                label: item.name,
                status: item.status
            }))

            const foundedItem = data.some(item => item.id === phase.value);
            if (!foundedItem) {
                phase.value = '';
            }
        }).catch(error => {
            console.error('Fout bij het ophalen van gegevens', error)
        })

    }
}

const updatePhase = () => {
    const selectedOption = options.value.find(option => option.value === phase.value);
    emits('update:phase', phase.value)
    emits('update:status', selectedOption.status);
}


// Define on mounted
onMounted(async () => {
    await fetchData()
})
</script>
