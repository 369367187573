<template>

    <div
        id="kt_app_sidebar"
        class="app-sidebar flex-column"
        data-kt-drawer="true"
        data-kt-drawer-name="app-sidebar"
        data-kt-drawer-activate="{default: true, lg: false}"
        data-kt-drawer-overlay="true"
        data-kt-drawer-width="225px"
        data-kt-drawer-direction="start"
        data-kt-drawer-toggle="#kt_app_sidebar_mobile_toggle"
    >

        <div class="app-sidebar-logo px-6" id="kt_app_sidebar_logo">

            <Logo/>

            <SidebarToggle :minimizedOnly="subscriptionPage" />

        </div>


        <div class="app-sidebar-menu overflow-hidden flex-column-fluid">

            <div
                v-if="!subscriptionPage"
                id="kt_app_sidebar_menu_wrapper"
                class="app-sidebar-wrapper hover-scroll-overlay-y"
                data-kt-scroll="true"
                data-kt-scroll-activate="true"
                data-kt-scroll-height="auto"
                data-kt-scroll-dependencies="#kt_app_sidebar_header, #kt_app_sidebar_logo, #kt_app_sidebar_footer"
                data-kt-scroll-wrappers="#kt_app_sidebar_menu"
                data-kt-scroll-offset="5px"
                data-kt-scroll-save-state="true"
            >

                <div id="kt_app_sidebar_menu_scroll" class="scroll-y my-5 mx-3">

                    <div class="menu menu-column menu-rounded menu-sub-indention fw-semibold fs-6">

                        <div class="menu-item">

                            <Link
                                class="menu-link"
                                href="/dashboard"
                                :class="{ 'active': $page.url.startsWith('/dashboard') }"
                            >

                                <span class="menu-icon">

                                    <Icon type="dashboard" class="fs-2"/>

                                </span>

                                <span class="menu-title">Dashboard</span>

                            </Link>

                        </div>

                        <div
                            v-if="
                                $hasAccessToAnySubscriptionFeature(['deals', 'companies', 'contacts', 'meetings', 'calls', 'projects', 'tasks', 'products']) &&
                                $hasAnyPermission(['deals_index', 'companies_index', 'contacts_index', 'meetings_index', 'calls_index', 'projects_index', 'tasks_index', 'products_index'])
                            "
                            class="menu-item"
                        >

                            <Link
                                class="menu-link"
                                href="/statistics"
                                :class="{ 'active': $page.url.startsWith('/statistics') }"
                            >

                                <span class="menu-icon">

                                    <Icon type="statistics" class="fs-2"/>

                                </span>

                                <span class="menu-title">Statistics</span>

                            </Link>

                        </div>

                        <div class="menu-item pt-5">

                            <div class="menu-content">

                                <span class="menu-heading fw-bold text-uppercase fs-7">Activities</span>

                            </div>

                        </div>

                        <div class="menu-item">

                            <Link
                                class="menu-link"
                                href="/calendar"
                                :class="{ 'active': $page.url.startsWith('/calendar') }"
                            >

                                <span class="menu-icon">

                                    <Icon type="calendar" class="fs-2"/>

                                </span>

                                <span class="menu-title">Calendar</span>

                            </Link>
                        </div>

                        <div v-if="$hasAccessToSubscriptionFeature('calls') && $hasPermission('calls_index')" class="menu-item">

                            <Link
                                class="menu-link"
                                href="/calls"
                                :class="{ 'active': $page.url.startsWith('/calls') }"
                            >

                                <span class="menu-icon">

                                    <Icon type="call" class="fs-2"/>

                                </span>

                                <span class="menu-title">Calls</span>

                            </Link>

                        </div>

                        <div v-if="$hasAccessToSubscriptionFeature('meetings') && $hasPermission('meetings_index')" class="menu-item">

                            <Link
                                class="menu-link"
                                href="/meetings"
                                :class="{ 'active': $page.url.startsWith('/meetings') }"
                            >

                                <span class="menu-icon">

                                    <Icon type="meeting" class="fs-2"/>

                                </span>

                                <span class="menu-title">Meetings</span>

                            </Link>

                        </div>

                        <div v-if="$hasAccessToSubscriptionFeature('tasks') && $hasPermission('tasks_index')" class="menu-item">

                            <Link
                                class="menu-link"
                                href="/tasks"
                                :class="{ 'active': $page.url.startsWith('/tasks') }"
                            >

                                <span class="menu-icon">

                                    <Icon type="task" class="fs-2"/>

                                </span>

                                <span class="menu-title">Tasks</span>

                            </Link>

                        </div>

                        <div
                            v-if="
                                $hasAccessToAnySubscriptionFeature(['companies', 'contacts', 'deals', 'quotations', 'invoices', 'products', 'projects']) &&
                                $hasAnyPermission(['companies_index', 'contacts_index', 'deals_index', 'quotations_index', 'invoices_index', 'products_index', 'projects_index'])
                            "
                            class="menu-item pt-5"
                        >

                            <div class="menu-content">

                                <span class="menu-heading fw-bold text-uppercase fs-7">Apps</span>

                            </div>

                        </div>

                        <div v-if="$hasAccessToSubscriptionFeature('companies') && $hasPermission('companies_index')" class="menu-item">

                            <Link
                                class="menu-link"
                                href="/companies"
                                :class="{ 'active': $page.url.startsWith('/companies') }"
                            >

                                <span class="menu-icon">

                                    <Icon type="company" class="fs-2"/>

                                </span>

                                <span class="menu-title">Companies</span>

                            </Link>

                        </div>

                        <div v-if="$hasAccessToSubscriptionFeature('contacts') && $hasPermission('contacts_index')" class="menu-item">

                            <Link
                                class="menu-link"
                                href="/contacts"
                                :class="{ 'active': $page.url.startsWith('/contacts') }"
                            >

                                <span class="menu-icon">

                                    <Icon type="contact" class="fs-2"/>

                                </span>

                                <span class="menu-title">Contacts</span>

                            </Link>

                        </div>

                        <div v-if="$hasAccessToSubscriptionFeature('deals') && $hasPermission('deals_index')" class="menu-item">

                            <Link
                                class="menu-link"
                                href="/deals"
                                :class="{ 'active': $page.url.startsWith('/deals') }"
                            >

                                <span class="menu-icon">

                                    <Icon type="deal" class="fs-2"/>

                                </span>

                                <span class="menu-title">Deals</span>

                            </Link>

                        </div>

                        <div v-if="$hasAccessToSubscriptionFeature('quotations') && $hasPermission('quotations_index')" class="menu-item">

                            <Link
                                class="menu-link"
                                href="/quotations"
                                :class="{ 'active': $page.url.startsWith('/quotations') }"
                            >

                                <span class="menu-icon">

                                    <Icon type="quotation" class="fs-2"/>

                                </span>

                                <span class="menu-title">Quotations</span>
                            </Link>

                        </div>

                        <div v-if="$hasAccessToSubscriptionFeature('invoices') && $hasPermission('invoices_index')" class="menu-item">

                            <Link
                                class="menu-link"
                                href="/invoices"
                                :class="{ 'active': $page.url.startsWith('/invoices') }"
                            >

                                <span class="menu-icon">

                                    <Icon type="invoice" class="fs-2"/>

                                </span>

                                <span class="menu-title">Invoices</span>

                            </Link>

                        </div>

                        <div v-if="$hasAccessToSubscriptionFeature('products') && $hasPermission('products_index')" class="menu-item">

                            <Link
                                class="menu-link"
                                href="/products"
                                :class="{ 'active': $page.url.startsWith('/products') }"
                            >

                                <span class="menu-icon">

                                    <Icon type="product" class="fs-2"/>

                                </span>

                                <span class="menu-title">Products</span>

                            </Link>

                        </div>

                        <div v-if="$hasAccessToSubscriptionFeature('projects') && $hasPermission('projects_index')" class="menu-item">

                            <Link
                                class="menu-link"
                                href="/projects"
                                :class="{ 'active': $page.url.startsWith('/projects') }"
                            >

                                <span class="menu-icon">

                                    <Icon type="project" class="fs-2"/>

                                </span>

                                <span class="menu-title">Projects</span>

                            </Link>

                        </div>

                        <div v-if="$hasPermission('account_access')" class="menu-item pt-5">

                            <div class="menu-content">

                                <span class="menu-heading fw-bold text-uppercase fs-7">Other</span>

                            </div>

                        </div>

                        <div v-if="$hasPermission('account_access')" class="menu-item">

                            <Link
                                class="menu-link"
                                href="/account"
                                :class="{ 'active': $page.url.startsWith('/account') }"
                            >

                                <span class="menu-icon">

                                    <i class="ki-duotone ki-wrench fs-2">
                                        <i class="path1"></i>
                                        <i class="path2"></i>
                                    </i>

                                </span>

                                <span class="menu-title">My Account</span>

                            </Link>

                        </div>

                    </div>

                </div>

            </div>

        </div>

        <div v-if="subscriptionPage && !trialExpired" class="app-sidebar-footer flex-column-auto pt-2 pb-6 px-6" id="kt_app_sidebar_footer">

            <Link href="/" class="btn btn-flex flex-center btn-custom btn-primary overflow-hidden text-nowrap px-0 h-40px w-100">
                <i class="ki-duotone ki-arrow-left btn-icon fs-2 m-0">
                    <span class="path1"></span>
                    <span class="path2"></span>
                </i>
            </Link>

        </div>

    </div>

</template>

<script setup>
import Logo from '@/Layouts/App/Layout/Sidebar/Logo.vue'
import Icon from '@/Shares/General/Icon.vue'
import { computed } from 'vue'
import { usePage } from '@inertiajs/vue3'
import SidebarToggle from '@/Layouts/App/Layout/Sidebar/SidebarToggle.vue'

// Define variables
const page = usePage();

// Define computed
const subscriptionPage = computed(() => {
    return page.url.startsWith('/subscription')
})

const trialExpired = computed(() => {
    return page.props.auth.account.subscription.status === 'trial_expired'
})


</script>

<style scoped>
[data-kt-app-layout="dark-sidebar"] .app-sidebar {
    background-color: #1e1e2d;
    border-right: 0 !important;
}

[data-kt-app-layout="dark-sidebar"] .app-sidebar .menu .menu-item .menu-link.active {
    transition: color .2s ease;
    background-color: #2a2a3c;
    color: var(--bs-primary-inverse);
}

[data-kt-app-layout="dark-sidebar"] .app-sidebar .menu .menu-item.here > .menu-link {
    transition: color .2s ease;
    color: var(--bs-primary-inverse);
}

[data-kt-app-layout="dark-sidebar"] .app-sidebar .menu .menu-item .menu-link {
    color: #9d9da6;
}

[data-kt-app-layout="dark-sidebar"] .app-sidebar .menu .menu-item .menu-link.active .menu-icon, [data-kt-app-layout="dark-sidebar"] .app-sidebar .menu .menu-item .menu-link.active .menu-icon .svg-icon, [data-kt-app-layout="dark-sidebar"] .app-sidebar .menu .menu-item .menu-link.active .menu-icon i {
    color: var(--bs-primary-inverse);
}

[data-kt-app-layout="dark-sidebar"] .app-sidebar .menu .menu-item.here > .menu-link .menu-icon, [data-kt-app-layout="dark-sidebar"] .app-sidebar .menu .menu-item.here > .menu-link .menu-icon .svg-icon, [data-kt-app-layout="dark-sidebar"] .app-sidebar .menu .menu-item.here > .menu-link .menu-icon i {
    color: var(--bs-primary-inverse);
}

[data-kt-app-layout="dark-sidebar"] .app-sidebar .menu .menu-item .menu-link .menu-icon, [data-kt-app-layout="dark-sidebar"] .app-sidebar .menu .menu-item .menu-link .menu-icon .svg-icon, [data-kt-app-layout="dark-sidebar"] .app-sidebar .menu .menu-item .menu-link .menu-icon i {
    color: #c5c5d8;
}

[data-kt-app-layout="dark-sidebar"] .app-sidebar .menu .menu-item .menu-link.active .menu-title {
    color: var(--bs-primary-inverse);
}

[data-kt-app-layout="dark-sidebar"] .app-sidebar .menu .menu-item.here > .menu-link .menu-title {
    color: var(--bs-primary-inverse);
}

[data-kt-app-layout="dark-sidebar"] .app-sidebar .menu .menu-item .menu-link .menu-title {
    color: #9d9da6;
}

.app-sidebar-menu .menu > .menu-item > .menu-link > .menu-title {
    font-size: 13px !important;
    font-weight: 400;
}

</style>
