<template>

    <Modal :size="modalSize" title="Add Deal">

        <div class="modal-body py-0 px-0">

            <form @submit.prevent="submit" class="form">

                <div class="row g-0">

                    <div
                        class="py-4 px-0"
                        :class="{
                            'col-5 border border-top-0 border-bottom-0 border-left-0 border-right-1 border-gray-300' : useLines,
                            'col-12' : !useLines,
                        }"
                    >

                        <div class="px-6">

                            <div v-if="!modalStore.props.hideCompany || !modalStore.props.hideContact" class="row mb-3">

                                <div class="col-md-12">

                                    <Customer

                                        v-if="
                                            $hasAccessToSubscriptionFeatures(['companies', 'contacts']) &&
                                            $hasPermissions(['companies_index', 'contacts_index'])
                                        "

                                        :required="true"

                                        fetch-url-companies="/deals/create/get/companies"
                                        fetch-url-contacts="/deals/create/get/contacts"

                                        :company="form.company"
                                        :contact="form.contact"

                                        @update:customerType="customerType = $event"
                                        @update:customerName="customerName = $event"

                                        @update:company="form.company = $event"
                                        @update:companyIsNew="companyIsNew = $event"

                                        @update:contact="form.contact = $event"
                                        @update:contactIsNew="contactIsNew = $event"

                                    />
                                    <div v-if="form.errors.company" v-text="form.errors.company" class="text-danger small mt-1"/>
                                    <div v-if="form.errors.contact" v-text="form.errors.contact" class="text-danger small mt-1"/>

                                </div>

                            </div>

                            <el-divider v-if="!modalStore.props.hideCompany || !modalStore.props.hideContact" class="my-3"/>

                            <div v-if="!modalStore.props.hideName || !modalStore.props.hideAssignedTo" class="row" :class="{ 'mb-3' : form.assignedTo === currentUser.id }">

                                <div v-if="!modalStore.props.hideName" class="col-md-6">

                                    <Name :name="form.name" @update:name="form.name = $event"/>
                                    <div v-if="form.errors.name" v-text="form.errors.name" class="text-danger small mt-1"/>

                                </div>

                                <div v-if="!modalStore.props.hideAssignedTo" class="col-md-6">

                                    <AssignedTo
                                        fetch-url="/deals/create/get/users"
                                        :assigned-to="form.assignedTo"
                                        @update:assignedTo="form.assignedTo = $event"
                                    />
                                    <div v-if="form.errors.assignedTo" v-text="form.errors.assignedTo" class="text-danger small mt-1"/>

                                </div>

                            </div>

                            <div v-if="multipleTypes && !modalStore.props.hideType" class="row mb-3">

                                <div class="col-md-12">

                                    <Type
                                        fetch-url="/deals/create/get/types"
                                        :type="form.type"
                                        @update:type="form.type = $event"
                                        @update:multipleTypes="multipleTypes = $event"
                                    />
                                    <div v-if="form.errors.type" v-text="form.errors.type" class="text-danger small mt-1"/>

                                </div>

                            </div>

                            <div v-if="form.type">

                                <div v-if="!modalStore.props.hideSource || !modalStore.props.hideExpectedClosingDate" class="row mb-3">

                                    <div v-if="!modalStore.props.hideSource" class="col-md-6">

                                        <Source
                                            fetch-url="/deals/create/get/sources"
                                            :source="form.source"
                                            :type="form.type"
                                            @update:source="form.source = $event"
                                        />
                                        <div v-if="form.errors.source" v-text="form.errors.source" class="text-danger small mt-1"/>

                                    </div>

                                    <div v-if="!modalStore.props.hideExpectedClosingDate" class="col-md-6">

                                        <ExpectedClosingDate :expectedClosingDate="form.expectedClosingDate" @update:expectedClosingDate="form.expectedClosingDate = $event"/>
                                        <div v-if="form.errors.expectedClosingDate" v-text="form.errors.expectedClosingDate" class="text-danger small mt-1"/>

                                    </div>

                                </div>

                                <div v-if="!modalStore.props.hideAmount" class="row">

                                    <div v-if="!modalStore.props.hideTags" class="col-md-6">

                                        <Tags
                                            fetch-url="/deals/create/get/tags"
                                            :tags="form.tags"
                                            :type="form.type"
                                            @update:tags="form.tags = $event"
                                        />
                                        <div v-if="form.errors.tags" v-text="form.errors.tags" class="text-danger small mt-1"/>

                                    </div>

                                    <div v-if="!modalStore.props.hideAmount" class="col-md-6">

                                        <Amount
                                            fetch-url="/deals/create/get/amount-currencies"
                                            :amount-value="form.amountValue"
                                            :amount-currency="form.amountCurrency"
                                            :disabled="useLines"
                                            @update:amount-value="form.amountValue = $event"
                                            @update:amount-currency="form.amountCurrency = $event"
                                        />

                                        <div class="d-flex justify-content-between">

                                            <div>
                                                <div v-if="form.errors.amountValue" v-text="form.errors.amountValue" class="text-danger small mt-1"/>
                                                <div v-if="form.errors.amountCurrency" v-text="form.errors.amountCurrency" class="text-danger small mt-1"/>
                                            </div>

                                            <a href="#" v-text="toggleLinesText" class="text-primary fs-8" @click.prevent="toggleLines"/>

                                        </div>

                                    </div>

                                </div>

                                <div v-if="form.type && !modalStore.props.hidePhase || !modalStore.props.hideStatus" class="row mb-3">

                                    <div v-if="!modalStore.props.hidePhase" class="col-md-6">

                                        <Phase
                                            fetch-url="/deals/create/get/phases"
                                            :phase="form.phase"
                                            :type="form.type"
                                            @update:phase="form.phase = $event"
                                            @update:status="form.status = $event"
                                        />
                                        <div v-if="form.errors.phase" v-text="form.errors.phase" class="text-danger small mt-1"/>

                                    </div>

                                    <div v-if="!modalStore.props.hideStatus" class="col-md-6">

                                        <Status
                                            fetch-url="/deals/create/get/statuses"
                                            :status="form.status"
                                            @update:status="form.status = $event"
                                        />
                                        <div v-if="form.errors.status" v-text="form.errors.status" class="text-danger small mt-1"/>

                                    </div>

                                </div>

                                <div v-if="!modalStore.props.hideDescription" class="row mb-3">

                                    <div class="col-md-12">

                                        <Description :description="form.description" @update:description="form.description = $event"/>
                                        <div v-if="form.errors.description" v-text="form.errors.description" class="text-danger small mt-1"/>

                                    </div>

                                </div>

                                <CustomFields
                                    v-if="form.type"
                                    :fetchUrl="`/deals/create/get/${form.type}/custom-fields`"
                                    :customFields="form.customFields"
                                    :errors="form.errors"
                                    @update:customFields="form.customFields = $event"
                                />

                            </div>

                        </div>

                        <el-divider class="mb-4"></el-divider>

                        <div class="d-flex justify-content-end px-8 py-0">

                            <button
                                type="submit"
                                class="btn btn-sm btn-primary"
                                :disabled="form.processing"
                                :data-kt-indicator="form.processing ? 'on' : 'off'"
                            >

                                <span class="indicator-label">
                                    <i class="ki-duotone ki-plus fs-6 text-white me-1"></i> Create deal
                                </span>

                                <span class="indicator-progress">
                                    Please wait... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>

                            </button>

                        </div>

                    </div>

                    <div v-if="useLines" class="col-7 pt-2 pb-0 px-6">

                        <div class="d-flex flex-column h-100">

                            <LinesTable
                                v-if="!modalStore.props.hideLines"
                                :lines="form.lines"
                                :discounts="form.discounts"
                                fetchLineProductsUrl="/deals/create/get/products"
                                @update:lines="form.lines = $event"
                                @update:discounts="form.discounts = $event"
                            />

                        </div>

                    </div>

                </div>

            </form>

        </div>

    </Modal>

</template>
<script setup>
import Modal from '@/Shares/General/Modal.vue'
import { useForm, usePage } from '@inertiajs/vue3'
import { ref, watch, onMounted, computed } from 'vue'
import Name from '@/Shares/App/Modules/Deals/Modals/Partials/Form/Name.vue'
import Description from '@/Shares/App/Modules/Deals/Modals/Partials/Form/Description.vue'
import Customer from '@/Shares/General/Form/Customer.vue'
import AssignedTo from '@/Shares/General/Form/AssignedTo.vue'
import Tags from '@/Shares/General/Form/Tags.vue'
import Source from '@/Shares/App/Modules/Deals/Modals/Partials/Form/Source.vue'
import ExpectedClosingDate from '@/Shares/App/Modules/Deals/Modals/Partials/Form/ExpectedClosingDate.vue'
import Amount from '@/Shares/App/Modules/Deals/Modals/Partials/Form/Amount.vue'
import Type from '@/Shares/App/Modules/Deals/Modals/Partials/Form/Type.vue'
import Phase from '@/Shares/App/Modules/Deals/Modals/Partials/Form/Phase.vue'
import Status from '@/Shares/App/Modules/Deals/Modals/Partials/Form/Status.vue'
import { useModalStore } from '@/Stores/modalStore.js'
import LinesTable from '@/Shares/General/Form/LinesTable.vue'
import axios from 'axios'
import CustomFields from '@/Shares/General/Form/CustomFields.vue'

// Define variables
const modalStore = useModalStore()
const page = usePage();
const currentUser = page.props.auth.user


// Define form
const form = useForm({
    name: '',
    source: '',
    company: '',
    contact: '',
    assignedTo: '',
    amountValue: '',
    amountCurrency: '',
    expectedClosingDate: '',
    tags: '',
    description: '',
    status: '',
    phase: '',
    type: '',
    lines: [],
    discounts: [],
    customFields: {},
})

// Define refs
let useLines = ref(false)
let multipleTypes = ref(true)

let customerType = ref('')
let customerName = ref('')

let companyIsNew = ref(false);
let contactIsNew = ref(false);


// Define watchers
watch(() => customerName.value, () => {
    if (!form.name && customerName.value) {
        form.name = 'Deal ' + customerName.value
    }
})

watch(() => form.type, () => {
    fetchDefaultValues()
})


// Define computed
const modalSize = computed(() => {
    return useLines.value ? 'xl' : 'md'
})

const toggleLinesText = computed(() => {
    return useLines.value ? 'Dont`t add lines' : 'Add lines'
})


// Define methods
function submit() {

    // Check if using lines
    if (useLines.value){
        form.amountValue = ''
        form.amountCurrency = ''
    } else {
        form.lines = []
        form.discounts = []
    }

    form.post(modalStore.props.storeUrl, {
        preserveState: (page) => Object.keys(page.props.errors).length > 0,
        onSuccess: () => modalStore.closeModal(),
    })
}

function toggleLines() {
    useLines.value = !useLines.value

    if (useLines.value === true && form.lines.length === 0) {

        // Add line
        form.lines = [{ 'product': null }]

    }

}

async function fetchDefaultValues() {

    // Check if type is present
    if (!form.type){
        return;
    }

    // Determine if it is necessary to retrieve the default values
    const shouldFetchDefaultValues = !form.name || !form.source || !form.phase || !form.status || !form.assignedTo || !form.tags || !form.description || !form.amountCurrency;

    // Fetch default values only if necessary
    if (shouldFetchDefaultValues) {
        await axios.get(`/deals/create/get/${form.type}/default-values`).then(response => {
            const data = response.data

            // Only fill in the values if they are not already filled in
            form.name = form.name || data.name;
            form.source = form.source || data.source;
            form.phase = form.phase || data.phase;
            form.status = form.status || data.status;
            form.assignedTo = form.assignedTo || data.assigned_to;
            form.tags = form.tags || data.tags;
            form.description = form.description || data.description;
            form.amountCurrency = form.amountCurrency || data.amount_currency;

        }).catch(error => {
            console.error('Fout bij het ophalen van gegevens', error)
        })
    }

}


// Define on mounted
onMounted(async () => {

    // Check if any form value is predefined
    Object.keys(modalStore.props).forEach(key => {
        if (form.hasOwnProperty(key)) {
            form[key] = modalStore.props[key]
        }
    })

    if (modalStore.props.customerName) {
        customerName.value = modalStore.props.customerName
    }

    if (modalStore.props.customerType) {
        customerType.value = modalStore.props.customerType
    }

    if (form.lines.length > 0) {
        useLines.value = true
    }

    if (form.discounts.length > 0) {
        useLines.value = true
    }

    // Fetch default values
    await fetchDefaultValues()

})

</script>
