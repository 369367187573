<template>
    <a href="#" class="d-flex align-items-center fs-7 text-primary" @click.prevent="addCompany">
        <i class="ki-duotone ki-plus fs-6 text-primary"></i>
        Add Company
    </a>
</template>

<script setup>

// Define emits
const emits = defineEmits(['addCompany'])

// Define methods
function addCompany() {
    emits('addCompany')
}

</script>

