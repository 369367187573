import './bootstrap'
import '../css/app.css';
import '../sass/app.scss';

import { createApp, h } from 'vue'
import { createInertiaApp, Link, usePage } from '@inertiajs/vue3'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'
import { createPinia } from 'pinia'
import moment from 'moment-timezone';

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import ErrorLayoutFile from '@/Layouts/Error.vue'
import AuthLayoutFile from '@/Layouts/Auth/Layout.vue'
import AppLayoutFile from '@/Layouts/App/Layout.vue'
import AppHead from '@/Shares/App/Layout/Header.vue'
import AppToolbar from '@/Shares/App/Layout/Toolbar.vue'
import AppContent from '@/Shares/App/Layout/Content.vue'

import dayjs from 'dayjs';
import 'dayjs/locale/en';

const dayjsLocale = {
    ...dayjs.Ls.en,
    weekStart: 1 // Set the first day of the week to Monday
};
dayjs.locale(dayjsLocale);

createInertiaApp({

    resolve: async name => {

        const page = await resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue'))

        if (name.startsWith('Auth/')) {

            // Except Sign Up
            if (!name.startsWith('Auth/SignUp') && !name.startsWith('Auth/Verify')) {

                page.default.layout ??= AuthLayoutFile

            }

        } else if (name.startsWith('App/')) {

            // Excludes
            if (!name.startsWith('App/Welcome') && !name.startsWith('App/Profile/Setup')) {
                page.default.layout ??= AppLayoutFile
            }

            if (name.startsWith('App/Profile/Setup')){
                page.default.layout ??= AuthLayoutFile
            }

        } else if(name === 'Error'){
            page.default.layout ??= ErrorLayoutFile
        }

        return page

    },

    setup({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(ElementPlus, { zIndex: 9999 })
            .use(VueSweetalert2)
            .use(createPinia())
            .component('Link', Link)
            .component('AppHead', AppHead)
            .component('AppToolbar', AppToolbar)
            .component('AppContent', AppContent);

        const page = usePage();

        // Add currency formatter
        app.config.globalProperties.$filters = {
            formatCurrency(number, currency = 'EUR') {
                return new Intl.NumberFormat('nl-NL', { style: 'currency', currency }).format(number);
            }
        };

        // Add permission check
        app.config.globalProperties.$hasPermission = (permission) => {
            return page.props.auth.user.permissions.includes(permission);
        };
        app.config.globalProperties.$hasAnyPermission = (permissions) => {
            return permissions.some(permission => page.props.auth.user.permissions.includes(permission));
        };
        app.config.globalProperties.$hasPermissions = (permissions) => {
            return permissions.every(permission => page.props.auth.user.permissions.includes(permission));
        };

        // Add subscription feature check
        app.config.globalProperties.$getSubscriptionFeature = (feature) => {
            if (!page.props.auth.account.subscription.features.hasOwnProperty(feature)){
                return null;
            }
            return page.props.auth.account.subscription.features[feature]
        };
        app.config.globalProperties.$hasAccessToSubscriptionFeature = (feature) => {
            if (!page.props.auth.account.subscription.features.hasOwnProperty(feature)){
                return null;
            }
            return page.props.auth.account.subscription.features[feature]
        };
        app.config.globalProperties.$hasAccessToSubscriptionFeatures = (features) => {
            const subscriptionFeatures = page.props.auth.account.subscription.features;
            return features.every(feature => subscriptionFeatures.hasOwnProperty(feature) && subscriptionFeatures[feature] === true);
        };
        app.config.globalProperties.$hasAccessToAnySubscriptionFeature = (features) => {
            const subscriptionFeatures = page.props.auth.account.subscription.features;
            return features.some(feature => subscriptionFeatures.hasOwnProperty(feature) && subscriptionFeatures[feature] === true);
        };

        app.mount(el);
    },

})

document.addEventListener('inertia:success', (event) => {
    const page = event.detail.page;
    const userTimezone = page.props.auth && page.props.auth.user ? page.props.auth.user.time_zone : 'Europe/Amsterdam';
    moment.tz.setDefault(userTimezone);
});
