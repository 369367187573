<script setup>
import {onMounted} from "vue";
import Footer from "@/Layouts/Auth/Layout/Footer.vue";
import Aside from "@/Layouts/Auth/Layout/Aside.vue";

onMounted(() => {

    // Remove and add attributes
    removeBodyAttributes();
    addNewBodyAttributes();

    KTComponents.init();
    KTThemeMode.init();

});

let addNewBodyAttributes = () => {
    const body = document.body;
    body.setAttribute('id', 'kt_body');
    body.classList.add('app-blank');
}

let removeBodyAttributes = () => {
    document.body.removeAttribute('id');
    document.body.className = '';
    Array.from(document.body.attributes).forEach(attr => document.body.removeAttribute(attr.name));
}

</script>

<template>

    <div class="d-flex flex-column flex-root h-100" id="kt_app_root">

        <div class="d-flex flex-column flex-lg-row flex-column-fluid">

            <div class="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1">

                <div class="d-flex flex-center flex-column flex-lg-row-fluid">

                    <div class="w-lg-500px p-10">

                        <slot />

                    </div>

                </div>

                <Footer />

            </div>

            <Aside />

        </div>

    </div>

</template>

<style scoped>

</style>
