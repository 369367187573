<template>

    <label class="form-label fs-7">Template</label>

    <el-select
        v-model="template"
        filterable
        reserve-keyword
        clearable
        placeholder="Select template..."
        @change="updateTemplate"
    >
        <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
        >{{ item.label }}
        </el-option>
    </el-select>

</template>

<script setup>
import { onMounted, ref, watch } from 'vue'
import axios from 'axios'

// Define props
const props = defineProps({
    fetchUrl: String,
    type: String,
    template: String,
})


// Define emits
const emits = defineEmits(['update:template'])


// Define refs
const template = ref(props.template || '')
const options = ref([])

// Define watchers
watch(() => props.template, (newTemplate) => {
    template.value = newTemplate
})

watch(() => [props.type, props.fetchUrl], () => {
    fetchData()
})



// Define methods
async function fetchData() {

    if (props.fetchUrl) {

        axios.get(props.fetchUrl, { params: { type: props.type } })
            .then(response => {
                const data = response.data

                options.value = data.map(item => ({
                    value: item.id,
                    label: item.name,
                }))

                const foundedItem = data.some(item => item.id === template.value);
                if (!foundedItem) {
                    template.value = '';
                }
            }).catch(error => {
            console.error('Fout bij het ophalen van gegevens', error)
        })

    }
}

const updateTemplate = () => {
    emits('update:template', template.value)
}


// Define on mounted
onMounted(async () => {
    await fetchData()
})

</script>
