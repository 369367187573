<template>
    <Email
        v-for="(email, index) in emails"
        :key="index"
        :email="email.email"
        :types="email.types"
        :errors="getErrors(index)"
        @update:email="updateEmail(index, $event)"
        @delete:email="deleteEmail(index)"
    />

    <AddButton @addEmail="addEmail"/>
</template>

<script setup>
import { ref, watch } from 'vue'
import AddButton from '@/Shares/App/Modules/Companies/Modals/Partials/Form/Emails/AddButton.vue'
import Email from '@/Shares/App/Modules/Companies/Modals/Partials/Form/Emails/Email.vue'


// Define props
const props = defineProps({
    emails: Array,
    errors: Array,
})


// Define refs
let emails = ref(props.emails ?? [])


// Define emits
const emits = defineEmits(['update:emails'])


// Define watchers
watch(() => props.emails, (value) => {
    emails.value = value
}, { deep: true })

watch(() => emails.value, (value) => {
    emits('update:emails', value)
}, { deep: true })


// Define methods
function addEmail() {
    emails.value.push({ 'email': null, 'types': [] })
}

function updateEmail(index, updatedEmail) {
    emails.value[index] = updatedEmail
}

function deleteEmail(index) {
    emails.value.splice(index, 1)
}

function getErrors(index) {
    const errors = {};
    for (const [key, value] of Object.entries(props.errors)) {
        if (key.startsWith(`contacts.${index}`)) {
            const newKey = key.replace(`contacts.${index}.`, '');
            errors[newKey] = value;
        }
    }
    return errors;
}

</script>
