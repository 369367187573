<template>

    <div class="d-flex flex-column flex-root app-root h-100" id="kt_app_root">

        <div class="app-page flex-column flex-column-fluid" id="kt_app_page">

            <Header/>

            <div class="app-wrapper flex-column flex-row-fluid" id="kt_app_wrapper">

                <Sidebar/>

                <div class="app-main flex-column flex-row-fluid" id="kt_app_main">

                    <div class="d-flex flex-column flex-column-fluid">

                        <slot/>

                    </div>

                    <Footer/>

                </div>

            </div>

        </div>

    </div>

    <div id="kt_scrolltop" class="scrolltop mb-10" data-kt-scrolltop="true">
        <i class="ki-duotone ki-arrow-up">
            <span class="path1"></span>
            <span class="path2"></span>
        </i>
    </div>

    <div id="modal-container">
        <component :is="currentModal"></component>
    </div>

    <Crisp/>

    <Flasher/>

</template>

<script setup>
import { computed, onMounted } from 'vue'
import Header from '@/Layouts/App/Layout/Header.vue'
import Sidebar from '@/Layouts/App/Layout/Sidebar.vue'
import Footer from '@/Layouts/App/Layout/Footer.vue'
import { useModalStore } from '@/Stores/modalStore'
import Crisp from '@/Layouts/App/Layout/Crisp.vue'
import Flasher from '@/Layouts/App/Layout/Flasher.vue'
import { router } from '@inertiajs/vue3'


// Define variables
const modalStore = useModalStore()


// Define computed
const currentModal = computed(() => modalStore.currentModal)


// Define methods
let addNewBodyAttributes = () => {
    const body = document.body
    body.setAttribute('id', 'kt_app_body')
    body.setAttribute('data-kt-app-layout', 'dark-sidebar')
    body.setAttribute('data-kt-app-header-fixed', 'true')
    body.setAttribute('data-kt-app-sidebar-enabled', 'true')
    body.setAttribute('data-kt-app-sidebar-fixed', 'true')
    body.setAttribute('data-kt-app-sidebar-push-header', 'true')
    body.setAttribute('data-kt-app-sidebar-push-toolbar', 'true')
    body.setAttribute('data-kt-app-sidebar-push-footer', 'true')
    body.setAttribute('data-kt-app-toolbar-enabled', 'true')
    body.classList.add('app-default')
}

let removeBodyAttributes = () => {
    document.body.removeAttribute('id')
    document.body.className = ''
    Array.from(document.body.attributes).forEach(attr => document.body.removeAttribute(attr.name))
}

let initialLoadActions = () => {

    // Remove and add attributes
    removeBodyAttributes()
    addNewBodyAttributes()

    KTComponents.init()
    KTThemeMode.init()

}


// Define on mounted
router.on('finish', () => {

    // Remove and add attributes
    removeBodyAttributes()
    addNewBodyAttributes()

})

router.reload({ only: ['auth.user.permissions'] })

// Define on mounted
onMounted(() => {

    initialLoadActions();

})


</script>
