<template>

    <label class="form-label fs-7">Assigned to</label>

    <el-select
        v-model="assignedTo"
        filterable
        clearable
        placeholder="Select assigned to..."
    >

        <el-option
            v-for="user in users"
            :key="user.value"
            :label="user.label"
            :value="user.value"
            class="d-flex d-flex align-items-center"
        >

            <div class="me-2 d-flex d-flex align-items-center">

                <div v-if="user.image" class="symbol symbol-25px symbol-circle">
                    <img alt="Pic" :src="user.image">
                </div>

                <div v-else class="symbol symbol-25px symbol-circle">
                    <span class="symbol-label bg-light-primary text-primary fw-bold fs-8 text-uppercase">{{ user.initials }}</span>
                </div>

            </div>

            <span>{{ user.label }}</span>

        </el-option>

    </el-select>
    <div v-if="assignedTo !== currentUser.id" class="text-end">
        <span class="text-primary fs-8 cursor-pointer" @click="assignedTo = currentUser.id">Assign to me</span>
    </div>

</template>

<script setup>
import { ref, watch, onMounted } from 'vue'
import { usePage } from '@inertiajs/vue3'


// Define variables
const page = usePage();
const currentUser = page.props.auth.user;


// Define props
const props = defineProps({
    fetchUrl: String,
    assignedTo: String,
})


// Define emits
const emits = defineEmits(['update:assignedTo'])


// Define refs
const assignedTo = ref(props.assignedTo || '')
const users = ref([])


// Define watchers
watch(() => props.assignedTo, (newAssignedTo) => {
    assignedTo.value = newAssignedTo
})
watch(() => assignedTo.value, (newAssignedTo) => {
    emits('update:assignedTo', newAssignedTo)
})


// Define on mounts
onMounted(async () => {
    if (props.fetchUrl) {
        try {
            const response = await fetch(props.fetchUrl)
            if (response.ok) {
                const data = await response.json()
                users.value = data.map(item => ({
                    value: item.id,
                    label: item.name,
                    initials: item.initials,
                    image: item.image,
                }))
            } else {
                console.error('Fetch failed:', response.statusText)
            }
        } catch (error) {
            console.error('Fetch error:', error)
        }
    }
})

</script>

