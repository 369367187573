<template>

    <el-select
        v-model="company"
        ref="companySelectRef"
        filterable
        clearable
        default-first-option
        :reserve-keyword="false"
        placeholder="Search for company..."

        :remote="isRemoteEnabled"
        :remote-method="remoteMethod"
        :loading="loading"
        loading-text="Loading companies..."
        no-data-text="No companies found."

        @focus="updateBadge"
        @blur="updateBadge"
        @visible-change="updateBadge"
        @change="updateCompany"

        @input="searchText = $event.target.value"

    >

        <el-option
            v-for="item in companyOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
        >
            {{ item.label }} <span v-if="item.isNew" class="new-badge ms-2 badge badge-sm badge-primary">New</span>
        </el-option>

        <template #footer v-if="searchText">
            <a class="btn btn-sm text-dark text-hover-primary m-0 p-0 d-flex align-items-center" @click="addAndSelectSearchText">
                <i class="ki-solid ki-plus me-0 text-dark"/> Add <span class="fw-bolder mx-1">'{{ searchText }}'</span>as new company
            </a>
        </template>

    </el-select>

</template>

<script setup>
import { computed, nextTick, onMounted, ref, watch } from 'vue'


// Define props
const props = defineProps({
    fetchUrl: String,
    company: String,
    contact: String,
    companyIsNew: Boolean,
    contactIsNew: Boolean,
})


// Define refs
const company = ref(props.company || '')
const companyIsNew = ref(props.companyIsNew || false)

const searchText = ref('')
const loading = ref(false)

const companySelectRef = ref()
const companyOptions = ref([])


// Define emits
const emit = defineEmits(['update:company', 'update:companyIsNew'])


// Define computed
const customerType = computed(() => company.value ? 'company' : 'contact')
const isRemoteEnabled = computed(() => {
    return customerType.value === 'company' || props.contactIsNew
})


// Define methods
const fetchCompanies = async (query) => {
    try {
        const response = await fetch(`${props.fetchUrl}?${query}`)
        if (!response.ok) throw new Error('Network response was not ok')
        const data = await response.json()

        // Map contacts
        let companies = [];
        if (Array.isArray(data)){
            companies = data.map(item => ({
                value: item.id,
                label: item.name,
                isNew: false,
            }))
        } else {
            companies = [{
                value: data.id,
                label: data.name,
                isNew: false,
            }]
        }

        // Check if current company is new
        if (companyIsNew.value){
            companies.push({
                value: company.value,
                label: company.value,
                isNew: true,
            })
        }

        return companies

    } catch (error) {
        console.error('Error fetching companies:', error)
        return []
    }
}

const remoteMethod = async (search) => {
    if (!search) return
    if (search) {
        loading.value = true
        companyOptions.value = await fetchCompanies(`search=${search}`)
        loading.value = false
    }
}

const addAndSelectSearchText = () => {

    // Prepare new company
    const newCompany = searchText.value

    // Update refs
    companyIsNew.value = true
    company.value = newCompany
    searchText.value = null

    // Blur
    nextTick(() => {
        companySelectRef.value.blur()
    })

    // Set isNew property to true for the new company
    companyOptions.value.push({
        value: newCompany,
        label: newCompany,
        isNew: true,
    })

    // update badge
    updateBadge()

    // Emit changes
    emit('update:company', company.value)
    emit('update:companyName', company.value)
    emit('update:companyIsNew', companyIsNew.value)
}

const updateCompany = (value) => {

    // Filter out new
    companyOptions.value = companyOptions.value.filter(companyOption => !companyOption.isNew);

    // Find selected option
    const selectedOption = companyOptions.value.find(companyOption => companyOption.value === company.value)

    // Update refs
    searchText.value = null
    company.value = value
    companyIsNew.value = selectedOption?.isNew;

    // Emits
    emit('update:company', value)
    emit('update:companyIsNew', companyIsNew.value)

    // Emit company name
    emitCompanyName();
}

const updateBadge = () => {
    nextTick(() => {

        // Get placeholder
        const placeholder = companySelectRef.value?.$el.querySelector('.el-select__placeholder')
        if (!placeholder) return

        // Check if current company is new
        const selectedOption = companyOptions.value.find(companyOption => companyOption.value === company.value)
        if (company.value && selectedOption && selectedOption.isNew) {

            // Set new company to true
            companyIsNew.value = true

            // Add classes
            placeholder.classList.add('d-flex', 'align-items-center', 'justify-content-between')

            // Check if badge exist
            const existingBadge = placeholder.querySelector('.new-badge')
            if (existingBadge) {
                return
            }

            // Add new badge
            const newBadge = document.createElement('span')
            newBadge.className = 'new-badge ms-2 badge badge-sm badge-primary'
            newBadge.textContent = 'New'
            placeholder.appendChild(newBadge)

            return
        }

        // Set new company to false
        companyIsNew.value = false

        // Remove existing badge
        const existingBadge = placeholder.querySelector('.new-badge')
        if (existingBadge) {
            placeholder.classList.remove('d-flex', 'align-items-center', 'justify-content-between')
            existingBadge.remove()
        }

    })
}

const emitCompanyName = () => {

    // Find selected option
    const selectedOption = companyOptions.value.find(companyOption => companyOption.value === company.value)

    // Emit
    emit('update:companyName', selectedOption?.label)

}


// Define watchers
watch(() => props.contact, async () => {

    // Check if new
    if (!props.contactIsNew && customerType.value === 'contact') {
        loading.value = true
        let query = 'contact=' + props.contact
        companyOptions.value = await fetchCompanies(query)
        loading.value = false
    } else {
        companyOptions.value = companyOptions.value.filter(companyOption => companyOption.isNew);
    }

})

watch(() => props.company, async () => {

    loading.value = true
    let query = 'company=' + props.company
    companyOptions.value = await fetchCompanies(query)
    loading.value = false

    // Emit company name
    emitCompanyName();

})


// Define on mounted
onMounted(async () => {

    if (props.company) {

        // Check if new
        if (!props.companyIsNew) {
            loading.value = true
            let query = 'company=' + props.company
            companyOptions.value = await fetchCompanies(query)
            loading.value = false

            // Emit company name
            emitCompanyName();

            return;
        }

        // Add company as option
        companyOptions.value.push({
            value: props.company,
            label: props.company,
            isNew: true,
        })

        // Update badge
        updateBadge()

        // Emit company name
        emitCompanyName();

    } else if (props.contact && !props.contactIsNew) {

        loading.value = true
        let query = 'contact=' + props.contact
        companyOptions.value = await fetchCompanies(query)
        loading.value = false

    }

})


</script>
