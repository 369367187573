import { defineStore } from 'pinia'
import { nextTick } from 'vue'

export const useModalStore = defineStore('modal', {
    state: () => ({
        currentModal: null,
        props: {},
        emits: {},
        isClosing: false,
        closePromiseResolver: null,
    }),
    actions: {
        async openModal(modalComponent) {
            if (this.isClosing) {
                await new Promise((resolve) => {
                    this.closePromiseResolver = resolve
                })
            } else {

                // Check if modal is open
                if (this.currentModal) {

                    // Close modal
                    this.closeModal()

                    await new Promise((resolve) => {
                        this.closePromiseResolver = resolve
                    })

                }

            }
            this.currentModal = modalComponent
            this.isClosing = false
        },
        closeModal() {
            this.isClosing = true
            this.props = {}
            this.emits = {}
            this.currentModal = null
            setTimeout(() => {
                nextTick(() => {
                    this.isClosing = false
                })
                if (this.closePromiseResolver) {
                    this.closePromiseResolver()
                    this.closePromiseResolver = null
                }
            }, 300)
        },
        setProps(props = {}) {
            this.props = props
        },
        setEmits(emits = {}) {
            this.emits = emits
        },
    },
})

// TODO ["Wel of geen fade", "Mogelijke replace functie"]
