<template>

    <label class="form-label fs-7">Birthdate</label>

    <el-date-picker
        v-model="birthdate"
        type="date"
        class="w-100"
        placeholder="Select birthdate..."
        format="DD MMM. YYYY"
        value-format="YYYY-MM-DD"
    />

</template>

<script setup>
import {ref, watch} from 'vue'

// Define props and emits
const props = defineProps({
    birthdate: String,
})

const emits = defineEmits(['update:birthdate'])

let birthdate = ref(props.birthdate);

// Update the local birthdate when the prop changes
watch(() => props.birthdate, (newBirthdate) => {
    birthdate.value = newBirthdate;
});

// Watch for changes in the local birthdate and emit an event
watch(birthdate, (newBirthdate) => {
    emits('update:birthdate', newBirthdate);
});
</script>
