<template>

    <label class="form-label fs-7">Vat number</label>

    <input
        v-model="vatNumber"
        type="text"
        class="form-control form-control-sm"
        placeholder="Enter vat number..."
    />

</template>

<script setup>
import { ref, watch } from 'vue'


// Define props
const props = defineProps({
    vatNumber: String,
})


// Define emits
const emits = defineEmits(['update:vatNumber'])


// Define refs
let vatNumber = ref(props.vatNumber ?? '')


// Update the local vatNumber when the prop changes
watch(() => props.vatNumber, (newVatNumber) => {
    vatNumber.value = newVatNumber
})


// Watch for changes in the local vatNumber and emit an event
watch(() => vatNumber.value, (newVatNumber) => {
    emits('update:vatNumber', newVatNumber)
})

</script>
