<template>

    <Modal size="md" title="Add Project">

        <div class="modal-body py-0 px-0">

            <form
                @submit.prevent="submit"
                class="form py-4 px-0"
            >

                <div class="px-6">

                    <div v-if="!modalStore.props.hideCompany || !modalStore.props.hideContact" class="row mb-3">

                        <div class="col-md-12">

                            <Customer

                                v-if="
                                    $hasAccessToSubscriptionFeatures(['companies', 'contacts']) &&
                                    $hasPermissions(['companies_index', 'contacts_index'])
                                "

                                fetch-url-companies="/projects/create/get/companies"
                                fetch-url-contacts="/projects/create/get/contacts"

                                :company="form.company"
                                :contact="form.contact"

                                @update:customerType="customerType = $event"
                                @update:customerName="customerName = $event"

                                @update:company="form.company = $event"
                                @update:companyIsNew="companyIsNew = $event"

                                @update:contact="form.contact = $event"
                                @update:contactIsNew="contactIsNew = $event"

                            />
                            <div v-if="form.errors.company" v-text="form.errors.company" class="text-danger small mt-1"/>
                            <div v-if="form.errors.contact" v-text="form.errors.contact" class="text-danger small mt-1"/>

                        </div>

                    </div>

                    <el-divider v-if="!modalStore.props.hideCompany || !modalStore.props.hideContact" class="my-3"/>

                    <div v-if="!modalStore.props.hideName || !modalStore.props.hideType" class="row mb-3">

                        <div v-if="!modalStore.props.hideName" class="col-md-6">

                            <Name :name="form.name" @update:name="form.name = $event"/>
                            <div v-if="form.errors.name" v-text="form.errors.name" class="text-danger small mt-1"/>

                        </div>

                        <div v-if="multipleTypes && !modalStore.props.hideType" class="col-md-6">

                            <Type
                                fetch-url="/projects/create/get/types"
                                :type="form.type"
                                @update:type="form.type = $event"
                                @update:multipleTypes="multipleTypes = $event"
                            />
                            <div v-if="form.errors.type" v-text="form.errors.type" class="text-danger small mt-1"/>

                        </div>

                    </div>

                    <div v-if="!modalStore.props.hideStartAt || !modalStore.props.hideEndAt" class="row mb-3">

                        <div v-if="!modalStore.props.hideStartAt" class="col-md-6">

                            <StartAt
                                :startAt="form.startAt"
                                :endAt="form.endAt"
                                @update:startAt="form.startAt = $event"
                            />
                            <div v-if="form.errors.startAt" v-text="form.errors.startAt" class="text-danger small mt-1"/>

                        </div>

                        <div v-if="!modalStore.props.hideEndAt" class="col-md-6">

                            <EndAt
                                :startAt="form.startAt"
                                :endAt="form.endAt"
                                @update:startAt="form.startAt = $event"
                                @update:endAt="form.endAt = $event"
                            />
                            <div v-if="form.errors.endAt" v-text="form.errors.endAt" class="text-danger small mt-1"/>

                        </div>

                    </div>

                    <div v-if="!modalStore.props.hideDescription" class="row mb-3">

                        <div class="col-md-12">

                            <Description :description="form.description" @update:description="form.description = $event"/>
                            <div v-if="form.errors.description" v-text="form.errors.description" class="text-danger small mt-1"/>

                        </div>

                    </div>

                    <div v-if="!modalStore.props.hideDeal || !modalStore.props.hideAssignedTo" class="row" :class="{ 'mb-3' : form.assignedTo === currentUser.id }">

                        <div v-if="$hasAccessToSubscriptionFeature('deals') && $hasPermission('deals_index') && !modalStore.props.hideDeal" class="col-md-6">

                            <Deal

                                fetch-url="/projects/create/get/deals"

                                :company="form.company"
                                :companyIsNew="companyIsNew"

                                :contact="form.contact"
                                :contactIsNew="contactIsNew"

                                :project="form.project"
                                :projectIsNew="projectIsNew"

                                :deal="form.deal"

                                @update:deal="form.deal = $event"
                                @update:dealIsNew="dealIsNew = $event"

                                @update:company="form.company = $event"
                                @update:contact="form.contact = $event"

                            />
                            <div v-if="form.errors.deal" v-text="form.errors.deal" class="text-danger small mt-1"/>

                        </div>

                        <div v-if="!modalStore.props.hideAssignedTo" class="col-md-6">

                            <AssignedTo
                                fetch-url="/projects/create/get/users"
                                :assignedTo="form.assignedTo"
                                @update:assignedTo="form.assignedTo = $event"
                            />
                            <div v-if="form.errors.assignedTo" v-text="form.errors.assignedTo" class="text-danger small mt-1"/>

                        </div>

                    </div>

                    <div v-if="form.type && (!modalStore.props.hideTemplate || !modalStore.props.hidePhase)" class="row mb-3">

                        <div v-if="!modalStore.props.hideTemplate" class="col-md-6">

                            <Template
                                fetch-url="/projects/create/get/templates"
                                :type="form.type"
                                :template="form.template"
                                @update:template="form.template = $event"
                            />
                            <div v-if="form.errors.template" v-text="form.errors.template" class="text-danger small mt-1"/>

                        </div>

                        <div v-if="!modalStore.props.hidePhase" class="col-md-6">

                            <Phase
                                fetch-url="/projects/create/get/phases"
                                :type="form.type"
                                :phase="form.phase"
                                @update:phase="form.phase = $event"
                            />
                            <div v-if="form.errors.phase" v-text="form.errors.phase" class="text-danger small mt-1"/>

                        </div>

                    </div>

                    <div v-if="form.type && !modalStore.props.hideTags" class="row mb-3">

                        <div class="col-md-6">

                            <Tags
                                fetch-url="/projects/create/get/tags"
                                :tags="form.tags"
                                :type="form.type"
                                @update:tags="form.tags = $event"
                            />
                            <div v-if="form.errors.tags" v-text="form.errors.tags" class="text-danger small mt-1"/>

                        </div>

                    </div>

                    <CustomFields
                        v-if="form.type"
                        :fetchUrl="`/projects/create/get/${form.type}/custom-fields`"
                        :customFields="form.customFields"
                        :errors="form.errors"
                        @update:customFields="form.customFields = $event"
                    />

                </div>

                <el-divider class="mb-4"></el-divider>

                <div class="d-flex justify-content-end px-8 py-0">

                    <button
                        type="submit"
                        class="btn btn-sm btn-primary"
                        :disabled="form.processing"
                        :data-kt-indicator="form.processing ? 'on' : 'off'"
                    >

                        <span class="indicator-label">
                            <i class="ki-duotone ki-plus fs-6 text-white"></i> Create project
                        </span>

                        <span class="indicator-progress">
                            Please wait... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>

                    </button>

                </div>

            </form>

        </div>

    </Modal>

</template>

<script setup>
import Modal from '@/Shares/General/Modal.vue'
import { useForm, usePage } from '@inertiajs/vue3'
import { onMounted, ref, watch } from 'vue'
import Name from '@/Shares/App/Modules/Projects/Modals/Partials/Form/Name.vue'
import Description from '@/Shares/App/Modules/Projects/Modals/Partials/Form/Description.vue'
import Customer from '@/Shares/General/Form/Customer.vue'
import AssignedTo from '@/Shares/General/Form/AssignedTo.vue'
import Deal from '@/Shares/General/Form/Deal.vue'
import StartAt from '@/Shares/App/Modules/Projects/Modals/Partials/Form/StartAt.vue'
import EndAt from '@/Shares/App/Modules/Projects/Modals/Partials/Form/EndAt.vue'
import Type from '@/Shares/App/Modules/Projects/Modals/Partials/Form/Type.vue'
import Template from '@/Shares/App/Modules/Projects/Modals/Partials/Form/Template.vue'
import Phase from '@/Shares/App/Modules/Projects/Modals/Partials/Form/Phase.vue'
import { useModalStore } from '@/Stores/modalStore.js'
import Tags from '@/Shares/General/Form/Tags.vue'
import axios from 'axios'
import CustomFields from '@/Shares/General/Form/CustomFields.vue'

// Define variables
const modalStore = useModalStore()
const page = usePage();
const currentUser = page.props.auth.user


// Define form
const form = useForm({
    name: '',
    type: '',
    startAt: new Date(),
    endAt: '',
    assignedTo: '',
    company: '',
    contact: '',
    deal: '',
    template: '',
    phase: '',
    description: '',
    tags: [],
    customFields: {},
})


// Define refs
let multipleTypes = ref(true)

let customerType = ref('')
let customerName = ref('')

let companyIsNew = ref(false);
let contactIsNew = ref(false);
let dealIsNew = ref(false);


// Define watchers
watch(() => customerName.value, () => {
    if (!form.name && customerName.value) {
        form.name = 'Project ' + customerName.value
    }
})


watch(() => form.type, () => {
    fetchDefaultValues()
})


// Define methods
function submit() {
    form.post(modalStore.props.storeUrl, {
        preserveState: (page) => Object.keys(page.props.errors).length > 0,
        onSuccess: () => modalStore.closeModal(),
    })
}

async function fetchDefaultValues() {

    // Check if type is present
    if (!form.type){
        return;
    }

    // Determine if it is necessary to retrieve the default values
    const shouldFetchDefaultValues = !form.name || !form.template || !form.phase || !form.description || !form.assignedTo || !form.tags;

    // Fetch default values only if necessary
    if (shouldFetchDefaultValues) {
        await axios.get(`/projects/create/get/${form.type}/default-values`).then(response => {
            const data = response.data

            // Only fill in the values if they are not already filled in
            form.name = form.name || data.name;
            form.template = form.template || data.template;
            form.phase = form.phase || data.phase;
            form.description = form.description || data.description;
            form.assignedTo = form.assignedTo || data.assigned_to;
            form.tags = form.tags || data.tags;

        }).catch(error => {
            console.error('Fout bij het ophalen van gegevens', error)
        })
    }

}


// Define on mounted
onMounted(async () => {

    // Check if any form value is predefined
    Object.keys(modalStore.props).forEach(key => {
        if (form.hasOwnProperty(key)) {
            form[key] = modalStore.props[key]
        }
    })

    if (modalStore.props.customerName) {
        customerName.value = modalStore.props.customerName
    }

    if (modalStore.props.customerType) {
        customerType.value = modalStore.props.customerType
    }

    // Fetch default values
    await fetchDefaultValues()

})

</script>
