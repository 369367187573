<template>
    <div class="app-navbar flex-shrink-0">

        <SearchBar v-if="$hasAnyPermission(['calls_index', 'meetings_index', 'tasks_index', 'companies_index', 'contacts_index', 'deals_index', 'quotations_index', 'invoices_index', 'projects_index', 'products_index']) && !trialExpired"/>

        <QuickActions v-if="$hasAnyPermission(['calls_index', 'meetings_index', 'tasks_index', 'companies_index', 'contacts_index', 'deals_index', 'quotations_index', 'invoices_index', 'projects_index', 'products_index']) && !trialExpired"/>

        <Notifications v-if="false && !trialExpired"/>

        <UserMenu/>

        <div v-if="props.showMobileMenuToggle" class="app-navbar-item d-lg-none ms-2 me-n2">

            <div class="btn btn-flex btn-icon btn-active-color-primary w-30px h-30px" id="kt_app_header_menu_toggle">

                <i class="ki-duotone ki-element-4 fs-1">
                    <span class="path1"></span>
                    <span class="path2"></span>
                </i>

            </div>

        </div>

    </div>
</template>

<script setup>
import SearchBar from '@/Layouts/App/Layout/Header/Navbar/SearchBar.vue'
import Notifications from '@/Layouts/App/Layout/Header/Navbar/Notifications.vue'
import UserMenu from '@/Layouts/App/Layout/Header/Navbar/UserMenu.vue'
import QuickActions from '@/Layouts/App/Layout/Header/Navbar/QuickActions.vue'
import { usePage } from '@inertiajs/vue3'
import { computed } from 'vue'

// Define variables
const page = usePage();

// Define props
const props = defineProps({
    showMobileMenuToggle: { type: Boolean, default: false }
})

// Define computed
const trialExpired = computed(() => {
    return page.props.auth.account.subscription.status === 'trial_expired'
})

</script>
