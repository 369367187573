<template>

    <Link
        :href="`/projects/${props.id}`"
        class="d-flex align-items-center bg-hover-gray-100 py-2 text-hover-primary text-gray-800"
    >

        <div class="symbol symbol-40px me-4">
            <span class="symbol-label bg-light">
                <Icon type="project" class="fs-2x ms-3 me-2"/>
            </span>
        </div>


        <div class="d-flex flex-column w-100">

            <span class="fs-6 fw-semibold">{{ props.name }}</span>

            <div class="d-flex" v-if="props.type || props.progress">
                <div class="d-flex align-items-center w-75">
                    <template v-if="props.type">
                        {{ props.type }} -
                    </template>
                    <template v-if="props.progress">
                        <span class="ms-3 me-2 fs-7">{{ props.progress }}%</span>
                        <div class="progress my-auto flex-grow-1 h-10px">
                            <div class="progress-bar" :style="{ width: props.progress + '%' }"></div>
                        </div>
                    </template>
                </div>
            </div>


        </div>

    </Link>

    <el-divider border-style="dashed" class="my-2"/>

</template>

<script setup>
import Icon from '@/Shares/General/Icon.vue'

// Define props
const props = defineProps({
    id: String,
    name: String,
    type: String,
    progress: String,
})
</script>
