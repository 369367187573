<template>

    <Address
        v-for="(address, index) in addresses"
        :key="index"
        :countryOptions="countryOptions"
        :addressLine1="address.addressLine1"
        :addressLine2="address.addressLine2"
        :postalCode="address.postalCode"
        :city="address.city"
        :state="address.state"
        :country="address.country"
        :types="address.types"
        :errors="getErrors(index)"
        @update:address="updateAddress(index, $event)"
        @delete:address="deleteAddress(index)"
    />

    <AddButton @addAddress="addAddress"/>

</template>

<script setup>
import { onMounted, ref, watch } from 'vue'
import AddButton from '@/Shares/App/Modules/Companies/Modals/Partials/Form/Addresses/AddButton.vue'
import Address from '@/Shares/App/Modules/Companies/Modals/Partials/Form/Addresses/Address.vue'
import Email from '@/Shares/App/Modules/Companies/Modals/Partials/Form/Emails/Email.vue'


// Define props
const props = defineProps({
    addresses: Array,
    addressesFetchUrl: String,
    errors: Array,
})


// Define refs
let addresses = ref(props.addresses ?? [])
let countryOptions = ref([])


// Define emits
const emits = defineEmits(['update:addresses'])


// Define watchers
watch(() => props.addresses, (value) => {
    addresses.value = value
}, { deep: true })

watch(() => addresses.value, (value) => {
    emits('update:addresses', value)
}, { deep: true })


// Define methods
function updateAddress(index, updatedAddress) {
    addresses.value[index] = updatedAddress
}

function deleteAddress(index) {
    addresses.value.splice(index, 1)
}

function addAddress() {
    addresses.value.push({
        'addressLine1': null,
        'addressLine2': null,
        'postalCode': null,
        'city': null,
        'state': null,
        'country': null,
        'types': []
    })
}

function getErrors(index) {
    const errors = {};
    for (const [key, value] of Object.entries(props.errors)) {
        if (key.startsWith(`addresses.${index}`)) {
            const newKey = key.replace(`addresses.${index}.`, '');
            errors[newKey] = value;
        }
    }
    return errors;
}


// Define on mounted
onMounted(async () => {
    try {
        const response = await axios.get(props.addressesFetchUrl)
        countryOptions.value = response.data.map(item => ({
            value: item.value,
            label: item.label,
        }))
    } catch (error) {
        console.error('Network response was not ok:', error)
    }
})

</script>
