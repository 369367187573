<template>

    <label class="form-label fs-7 required">Name</label>

    <input
        v-model="name"
        type="text"
        class="form-control form-control-sm"
        placeholder="Enter name..."
    />

</template>

<script setup>
import { ref, watch } from 'vue'

// Define props
const props = defineProps({
    name: String,
})


// Define emits
const emits = defineEmits(['update:name'])


// Define refs
let name = ref(props.name || '');


// Update the local name when the prop changes
watch(() => props.name, (newName) => {
    name.value = newName;
});


// Watch for changes and emit them
watch(() => name.value, (newName) => {
    emits('update:name', newName);
});

</script>
