<template>

    <label class="form-label fs-7 required">Due date</label>

    <el-date-picker
        v-model="dueDate"
        ref="dueDateRef"
        type="date"
        placeholder="Due date"
        class="me-2 w-100 hide-icon"
        format="DD MMM. YYYY"
        value-format="YYYY-MM-DD"
        :clearable="false"
        :teleported="false"
        :disabled-date="datePickerOptions.disabledDate"
        @change="updateDueDate"
        popper-class="due-date-popper"
    />

</template>

<script setup>
import { ref, watch, onMounted, onUnmounted, nextTick, createApp, reactive } from 'vue'
import Footer from '@/Shares/App/Modules/Invoices/Modals/Partials/Form/DueDate/Footer.vue'

// Define props
const props = defineProps({
    dueDate: Date,
    date: Date,
    conditionsFetchUrl: String,
    type: String,
})

// Define emits
const emits = defineEmits(['update:dueDate'])

// Define refs
let dueDate = ref(props.dueDate)
const dueDateRef = ref(null)
const footerApp = ref(null)
const datePickerOptions = reactive({
    disabledDate(date) {
        return date < new Date(props.date);
    }
});

watch(() => props.dueDate, (value) => {
    dueDate.value = value
})

const updateDueDate = (value) => {
    dueDate.value = value
    emits('update:dueDate', value)
    if (dueDateRef.value) {
        dueDateRef.value.handleClose();
    }
}

const handleConditionSelected = (daysValid) => {
    const newDate = new Date()
    newDate.setDate(newDate.getDate() + daysValid)
    updateDueDate(newDate.toISOString().split('T')[0])
}

const addCustomFooterWhenReady = async () => {
    await nextTick();
    const observer = new MutationObserver((mutations, obs) => {
        const popperBody = document.querySelector('.due-date-popper .el-picker-panel__body');
        if (popperBody) {
            const footerContainer = document.createElement('div');
            popperBody.appendChild(footerContainer);
            footerApp.value = createApp(Footer, {
                fetchUrl: props.conditionsFetchUrl,
                type: props.type,
                onConditionSelected: handleConditionSelected
            });
            footerApp.value.mount(footerContainer);
            obs.disconnect(); // Stop observing when done
        }
    });
    observer.observe(document.body, {
        childList: true,
        subtree: true
    });
};

const removeCustomFooter = () => {
    if (footerApp.value) {
        footerApp.value.unmount()
    }
}

onMounted(() => {
    addCustomFooterWhenReady()
})

onUnmounted(() => {
    removeCustomFooter()
})
</script>
