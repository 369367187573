<template>

    <div id="kt_app_content" class="app-content flex-column-fluid">

        <div
            id="kt_app_content_container"
            :class="[
                'app-container',
                'container-' + props.container,
                'h-100'
            ]"
            :style="{ padding: !props.withPadding ? '0 !important' : '' }"
        >

            <slot />

        </div>

    </div>

</template>

<script setup>
let props = defineProps({
    container: { type: String, default: 'fluid' },
    withPadding: { type: Boolean, default: true }
})
</script>
