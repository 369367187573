<template>

    <label class="form-label fs-7">Search for company</label>

    <el-select
        v-model="search"
        filterable
        remote
        reserve-keyword
        placeholder="Please enter a keyword"
        :remote-method="remoteMethod"
        :loading="loading"
    >
        <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
        />
    </el-select>
</template>

<script setup>
import { ref, watch } from 'vue'

// Define props
const props = defineProps({
    search: String,
})

// Define emits
const emits = defineEmits(['update:search'])


// Define refs
let search = ref(props.search)
const options = ref([])
const loading = ref(false)


// Define watchers
watch(() => props.search, (value) => {
    search.value = value
})

watch(() => search.value, (value) => {
    if(value) {
        fetchBusinessNumberDetails(value)
    }
})


// Define methods
const remoteMethod = async (query) => {
    if (query) {
        loading.value = true
        try {
            const response = await axios.get(`/companies/create/search?search=${query}`)
            options.value = response.data.map(item => ({
                value: item.value,
                label: item.label,
            }))
        } catch (error) {
            console.error('Network response was not ok:', error)
        }
        loading.value = false
    } else {
        options.value = []
    }
}

async function fetchBusinessNumberDetails(businessNumber) {
    try {
        const response = await axios.post(`/companies/create/search`, {
            businessNumber: businessNumber
        });

        emits('update:search', response.data)

    } catch (error) {
        console.error('Fetch error:', error);
    }
}

</script>
