<template>

    <el-select
        v-model="vat"
        filterable
        reserve-keyword
        placeholder="Select vat..."
        @change="updateVat"
    >
        <el-option
            v-for="item in options"
            :key="item.value"
            :label="`${item.label} (${item.amount}%)`"
            :value="item.value"
        >{{ item.label }}
        </el-option>
    </el-select>

</template>

<script setup>
import { ref, onMounted } from 'vue'

// Define props and emits
const props = defineProps({
    fetchUrl: String,
})

const emits = defineEmits(['update:vat'])

// Define refs
const vat = ref('')
const options = ref([])


// Define methods
const updateVat = () => {
    const selectedOption = options.value.find(opt => opt.value === vat.value)
    emits('update:vat', {
        'id': selectedOption.value,
        'value': selectedOption.amount
    })
}


// Fetch vat options


// Fetch users data
onMounted(async () => {
    if (props.fetchUrl) {
        try {
            const response = await fetch(props.fetchUrl)
            if (response.ok) {
                const data = await response.json()
                options.value = data.map(item => ({
                    value: item.id,
                    label: item.name,
                    amount: item.value
                }))

                // Select first vat
                vat.value = options.value[0].value
                updateVat();

            } else {
                console.error('Fetch failed:', response.statusText)
            }
        } catch (error) {
            console.error('Fetch error:', error)
        }
    }
})
</script>
