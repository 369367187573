<template>

    <Link
        :href="`/meetings/${props.id}`"
        class="d-flex align-items-center bg-hover-gray-100 py-2 text-hover-primary text-gray-800"
    >

        <div class="symbol symbol-40px me-4">
            <span class="symbol-label bg-light">
                <Icon type="meeting" class="fs-2x ms-3 me-2"/>
            </span>
        </div>

        <div class="d-flex flex-column">

            <span class="fs-6 fw-semibold">{{ props.name }}</span>

            <div class="d-flex" v-if="subText">
                <span class="fs-7 text-muted fw-semibold" v-text="subText"/>
            </div>

        </div>

    </Link>

    <el-divider border-style="dashed" class="my-2"/>

</template>

<script setup>
import Icon from '@/Shares/General/Icon.vue'
import { computed } from 'vue'

// Define props
const props = defineProps({
    id: String,
    name: String,
    company: Object,
    contact: Object,
})

// Define computed
const subText = computed(() => {
    let result = ''
    if (props.company && props.contact) {
        result = `${props.company.name}, ${props.contact.name}`
    } else if (props.company) {
        result = props.company.name
    } else if (props.contact) {
        result = props.contact.name
    }
    return result
})

</script>
