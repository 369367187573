<template>
    <el-select
        v-model="customer"
        ref="customerSelectRef"
        filterable
        clearable
        remote
        :reserve-keyword="false"
        default-first-option
        placeholder="Search for customer..."
        :remote-method="remoteMethod"
        :loading="loading"
        loading-text="Loading customers..."
        no-data-text="No customers found."
    >
        <el-option-group
            v-for="group in groupedOptions"
            :key="group.label"
            :label="group.label"
        >
            <el-option
                v-for="item in group.options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                :data-type="item.type"
                class="d-flex align-items-center"
            >
                <div class="me-2 d-flex align-items-center">
                    <i :class="item.icon" class="fs-3">
                        <span v-for="n in item.paths" :class="'path' + n"></span>
                    </i>
                </div>
                {{ item.label }}
            </el-option>
        </el-option-group>
        <template #footer v-if="searchText">
            <a class="btn btn-sm text-dark text-hover-primary m-0 p-0 d-flex align-items-center mb-4"
               @click="addAndSelectSearchTextAsCompany">
                <i class="ki-solid ki-plus me-0 text-dark"/>Add <span class="fw-bolder mx-1">'{{ searchText }}'</span>as new company
            </a>
            <a class="btn btn-sm text-dark text-hover-primary m-0 p-0 d-flex align-items-center"
               @click="addAndSelectSearchTextAsContact">
                <i class="ki-solid ki-plus me-0 text-dark"/>Add <span class="fw-bolder mx-1">'{{ searchText }}'</span>as new contact
            </a>
        </template>
    </el-select>
</template>

<script setup>
import { nextTick, ref, watch, computed } from 'vue'
import Icon from '@/Shares/General/Icon.vue'

const props = defineProps({
    fetchUrlCompanies: String,
    fetchUrlContacts: String,
})

const customer = ref(null)
const searchText = ref('')
const loading = ref(false)
const customerOptions = ref([])

const emit = defineEmits([
    'update:customer',
])

watch(() => customer.value, (value) => {
    if (!value) {
        emit('update:customer', {
            'customer': '',
            'customerType': '',
            'customerName': '',
            'customerIsNew': false,
        })
        return
    }

    const selectedCustomerOption = customerOptions.value.find(option => option.value === value)
    emit('update:customer', {
        'customer': value,
        'customerType': selectedCustomerOption.type,
        'customerName': selectedCustomerOption.label,
        'customerIsNew': selectedCustomerOption.isNew ?? false,
    })
})

const remoteMethod = async (search) => {
    searchText.value = search
    if (search) {
        loading.value = true
        const query = encodeURIComponent(search)
        const companiesData = await fetchData('company', props.fetchUrlCompanies, `search=${query}`)
        const contactsData = await fetchData('contact', props.fetchUrlContacts, `search=${query}`)
        customerOptions.value = [...companiesData, ...contactsData]
        loading.value = false
    }
}

const fetchData = async (type, fetchUrl, query) => {
    const response = await fetch(`${fetchUrl}?${query}`)
    if (!response.ok) throw new Error('Network response was not ok')
    const data = await response.json()
    return data.map(item => ({
        type,
        value: item.id,
        label: item.name,
        icon: type === 'company' ? 'ki-duotone ki-bank fs-2' : 'ki-duotone ki-address-book fs-2',
        paths: type === 'company' ? [1, 2] : [1, 2, 3],
        isNew: false, // This will be set to true manually when adding new entities
    }))
}

const addAndSelectSearchTextAsEntity = (type) => {
    const newCustomer = {
        value: searchText.value,
        label: searchText.value,
        type,
        isNew: true,
    }
    customerOptions.value.push(newCustomer)
    customer.value = searchText.value
}

const addAndSelectSearchTextAsCompany = () => addAndSelectSearchTextAsEntity('company')
const addAndSelectSearchTextAsContact = () => addAndSelectSearchTextAsEntity('contact')

const groupedOptions = computed(() => {
    const groups = { 'Companies': [], 'Contacts': [] }
    for (const option of customerOptions.value) {
        if (option.type === 'company') {
            groups.Companies.push(option)
        } else {
            groups.Contacts.push(option)
        }
    }
    return Object.keys(groups).map(label => ({ label, options: groups[label] }))
})
</script>
