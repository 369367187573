<template>

    <label class="form-label fs-7">Vat</label>

    <el-select
        v-model="vat"
        filterable
        reserve-keyword
        clearable
        placeholder="Select vat..."
    >
        <el-option
            v-for="item in options"
            :key="item.value"
            :label="`${item.label} (${item.amount}%)`"
            :value="item.value"
        />
    </el-select>

</template>

<script setup>
import { ref, watch, onMounted } from 'vue'

// Define props and emits
const props = defineProps({
    fetchUrl: String,
    vat: String,
})

const emits = defineEmits(['update:vat'])

// Define reactive properties using ref
const vat = ref(props.vat || '')

// Watch for changes in formData and emit them
watch(() => vat.value, (newVat) => {
    emits('update:vat', newVat)
})

watch(() => props.vat, (newVat) => {
    vat.value = newVat
})

/**
 * Remote fetch
 * */
const options = ref([])

// Fetch users data
onMounted(async () => {
    if (props.fetchUrl) {
        try {
            const response = await fetch(props.fetchUrl)
            if (response.ok) {
                const data = await response.json()
                options.value = data.map(item => ({
                    value: item.id,
                    label: item.name,
                    amount: item.value
                }))
            } else {
                console.error('Fetch failed:', response.statusText)
            }
        } catch (error) {
            console.error('Fetch error:', error)
        }
    }
})
</script>
