<template>

    <label class="form-label fs-7 required">Date</label>

    <el-date-picker
        v-model="date"
        type="date"
        placeholder="Select a date"
        class="me-2 w-100 hide-icon"
        format="DD MMM. YYYY"
        value-format="YYYY-MM-DD"
        :clearable="false"
        :disabled-date="datePickerOptions.disabledDate"
        @change="updateDate"
    />

</template>

<script setup>
import { reactive, ref, watch } from 'vue'


// Define props
const props = defineProps({
    date: Date,
    dueDate: Date,
})


// Define emits
const emits = defineEmits(['update:date'])


// Define refs
let date = ref(props.date);
const datePickerOptions = reactive({
    disabledDate(date) {
        if(props.dueDate){
            return date > new Date(props.dueDate);
        }
    }
});


// Define watchers
watch(() => props.date, (newDate) => {
    date.value = newDate;
});


// Define methods
function updateDate(value){
    emits('update:date', value);
}
</script>

