<template>

    <label class="form-label fs-7">Source</label>

    <el-select
        v-model="source"
        filterable
        reserve-keyword
        placeholder="Source..."
        @change="updateSource"
    >
        <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
        >{{ item.label }}
        </el-option>
    </el-select>

</template>

<script setup>
import { onMounted, ref, watch } from 'vue'
import axios from 'axios'


// Define props
const props = defineProps({
    fetchUrl: String,
    type: String,
    source: String,
})


// Define emits
const emits = defineEmits(['update:source'])


// Define refs
const source = ref(props.source || '')
const options = ref([])


// Define watchers
watch(() => props.source, (newSource) => {
    source.value = newSource
})

watch(() => [props.type, props.fetchUrl], () => {
    fetchData()
})


// Define methods
async function fetchData() {

    if (props.fetchUrl) {

        axios.get(props.fetchUrl, { params: { type: props.type } })
            .then(response => {
                const data = response.data

                options.value = data.map(item => ({
                    value: item.id,
                    label: item.name,
                }))

                const foundedItem = data.some(item => item.id === source.value);
                if (!foundedItem) {
                    source.value = '';
                }
            }).catch(error => {
            console.error('Fout bij het ophalen van gegevens', error)
        })

    }
}

const updateSource = () => {
    emits('update:source', source.value)
}


// Define on mounted
onMounted(async () => {
    await fetchData()
})
</script>
