<template>
    <Contact
        v-for="(contact, index) in contacts"
        :key="index"
        :contact="contact.contact"
        :position="contact.position"
        :primary="contact.primary"
        :decisionMaker="contact.decisionMaker"
        :fetchUrl="props.fetchUrl"
        :errors="getErrors(index)"
        @update:contact="updateContact(index, $event)"
        @delete:contact="deleteContact(index)"
    />

    <AddButton @addContact="addContact"/>
</template>


<script setup>
import { ref, watch } from 'vue'
import AddButton from '@/Shares/App/Modules/Companies/Modals/Partials/Form/Contacts/AddButton.vue'
import Contact from '@/Shares/App/Modules/Companies/Modals/Partials/Form/Contacts/Contact.vue'
import Telephone from '@/Shares/App/Modules/Companies/Modals/Partials/Form/Telephones/Telephone.vue'


// Define props
const props = defineProps({
    contacts: Array,
    fetchUrl: String,
    errors: Array,
})


// Define refs
let contacts = ref(props.contacts ?? [])


// Define emits
const emits = defineEmits(['update:contacts'])


// Define watchers
watch(() => props.contacts, (value) => {
    contacts.value = value
}, { deep: true })

watch(() => contacts.value, (value) => {
    emits('update:contacts', value)
}, { deep: true })


// Define methods
function addContact() {
    contacts.value.push({ 'contact': null, 'position': null, 'primary': false, 'decisionMaker': false })
}

function updateContact(index, updatedContact) {
    contacts.value[index] = updatedContact
}

function deleteContact(index) {
    contacts.value.splice(index, 1)
}

function getErrors(index) {
    const errors = {};
    for (const [key, value] of Object.entries(props.errors)) {
        if (key.startsWith(`contacts.${index}`)) {
            const newKey = key.replace(`contacts.${index}.`, '');
            errors[newKey] = value;
        }
    }
    return errors;
}

</script>
