<template>

    <label class="form-label fs-7">Weight</label>

    <input
        v-model="weight"
        type="text"
        class="form-control form-control-sm"
        placeholder="Enter weight..."
    />
</template>

<script setup>
import { ref, watch } from 'vue'

// Define props
const props = defineProps({
    weight: String,
})


// Define emits
const emits = defineEmits(['update:weight'])


// Define refs
let weight = ref(props.weight || '')


// Update the local weight when the prop changes
watch(() => props.weight, (newWeight) => {
    weight.value = newWeight
})


// Watch for changes and emit them
watch(() => weight.value, (newWeight) => {
    emits('update:weight', newWeight)
})
</script>
