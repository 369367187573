<template>

    <label class="form-label fs-7">Website</label>

    <input
        v-model="website"
        type="text"
        class="form-control form-control-sm"
        placeholder="Enter website..."
    />

</template>

<script setup>
import { ref, watch } from 'vue'

// Define props
const props = defineProps({
    website: String,
})


// Define emits
const emits = defineEmits(['update:website'])


// Define refs
let website = ref(props.website ?? '')


// Update the local name when the prop changes
watch(() => props.website, (newWebsite) => {
    website.value = newWebsite
})


// Watch for changes in the local website and emit an event
watch(() => website.value, (newWebsite) => {
    emits('update:website', newWebsite)
})

</script>
