<template>

    <div id="kt_app_footer" class="app-footer mt-10">

        <div class="app-container container-fluid d-flex flex-column flex-md-row flex-center flex-md-stack py-3">

            <div class="text-gray-900 order-2 order-md-1">
                <span class="text-muted fw-semibold me-1">{{ new Date().getFullYear() }}&copy;</span>
                <a href="https://virtupply.com" target="_blank" class="text-gray-800 text-hover-primary">Virtupply</a>
            </div>

            <ul class="menu menu-gray-600 menu-hover-primary fw-semibold order-1">
                <li class="menu-item">
                    <a href="https://support.virtupply.com" target="_blank" class="menu-link px-2">Knowledge base</a>
                </li>
                <li class="menu-item">
                    <a href="https://virtupply.com/contact" target="_blank" class="menu-link px-2">Contact</a>
                </li>
                <li class="menu-item">
                    <a href="https://virtupply.com" target="_blank" class="menu-link px-2">Website</a>
                </li>
            </ul>

        </div>

    </div>

</template>
