<template>
    <div class="menu-item me-0 me-lg-2 d-flex align-items-center">

        <div v-text="daysLeftText" />

    </div>
</template>

<script setup>
import { computed } from 'vue'
import { usePage } from '@inertiajs/vue3'
import moment from 'moment'

// Define variables
const page = usePage();

// Define computed
const daysLeftText = computed(() => {
    const today = moment();
    const trialEndsAt = moment(page.props.auth.account.subscription.trial_ends_at);
    const daysDiff = trialEndsAt.diff(today, 'days');
    const daysLeft = daysDiff > 0 ? daysDiff : 0;
    let text = '';
    if (daysLeft > 1 || daysLeft === 0){
        text = daysDiff +' days left';
    } else {
        text = daysDiff +' day left';
    }

    return text;
});

</script>
