<template>

    <Crisp/>

    <Flasher/>

    <div class="d-flex flex-column flex-root h-100" id="kt_app_root">

        <slot/>

    </div>

</template>

<script setup>
import { onMounted } from 'vue'
import Crisp from '@/Layouts/App/Layout/Crisp.vue'
import Flasher from '@/Layouts/App/Layout/Flasher.vue'
import { router } from '@inertiajs/vue3'

// Define methods
let addNewBodyAttributes = () => {
    const body = document.body
    body.setAttribute('id', 'kt_body')
    body.classList = 'app-blank bgi-size-cover bgi-position-center bgi-no-repeat';
    body.style.backgroundImage = "url('/assets/media/backgrounds/default.jpg')"
}

let removeBodyAttributes = () => {
    document.body.removeAttribute('id')
    document.body.className = ''
    Array.from(document.body.attributes).forEach(attr => document.body.removeAttribute(attr.name))
}


// Define on mounted
onMounted(() => {

    // Remove and add attributes
    removeBodyAttributes()
    addNewBodyAttributes()

    KTComponents.init()
    KTThemeMode.init()

})

router.on('start', (event) => {
    KTComponents.init()
    KTThemeMode.init()
})

</script>
