<template>
    <Discount
        v-for="(discount, index) in discounts"
        :key="index"
        :id="discount.id"
        :description="discount.description"
        :type="discount.type"
        :value="discount.value"
        @update:discount="updateDiscount(index, $event)"
        @delete:discount="deleteDiscount(index)"
    />
</template>

<script setup>
import Discount from '@/Shares/General/Form/LinesTable/Discounts/Discount.vue'
import { ref, watch } from 'vue'
import Line from '@/Shares/General/Form/LinesTable/Lines/Line.vue'

// Define props
const props = defineProps({
    discounts: Array,
})


// Define refs
let discounts = ref(props.discounts ?? [])


// Define emits
const emits = defineEmits(['update:discounts'])


// Define watchers
watch(() => props.discounts, (value) => {
    discounts.value = value
}, { deep: true })

watch(() => discounts.value, (value) => {
    emits('update:discounts', value)
}, { deep: true })


// Define methods
function updateDiscount(index, updatedDiscount) {
    discounts.value[index] = updatedDiscount
}

function deleteDiscount(index) {
    discounts.value.splice(index, 1)
}
</script>
