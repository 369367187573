<template>

    <label class="form-label fs-7">Business number</label>

    <input
        v-model="businessNumber"
        type="text"
        class="form-control form-control-sm"
        placeholder="Enter business Number..."
    />

</template>

<script setup>
import { ref, watch } from 'vue'

// Define props
const props = defineProps({
    businessNumber: String,
})


// Define emits
const emits = defineEmits(['update:businessNumber'])


// Define refs
let businessNumber = ref(props.businessNumber || '')


// Update the local businessNumber when the prop changes
watch(() => props.businessNumber, (newBusinessNumber) => {
    businessNumber.value = newBusinessNumber
})


// Watch for changes and emit them
watch(() => businessNumber.value, (newBusinessNumber) => {
    emits('update:businessNumber', newBusinessNumber)
})
</script>
