<template>

    <label class="form-label fs-7">Categories</label>

    <el-select
        v-model="categories"
        filterable
        multiple
        reserve-keyword
        collapse-tags
        collapse-tags-tooltip
        clearable
        placeholder="Select categories..."
    >
        <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
        >{{ item.label }}
        </el-option>
    </el-select>
</template>

<script setup>
import { ref, watch, onMounted } from 'vue'

// Define props
const props = defineProps({
    fetchUrl: String,
    categories: String,
})


// Define emits
const emits = defineEmits(['update:categories'])


// Define refs
const categories = ref(props.categories || [])
const options = ref([])


// Define watch
watch(() => props.categories, (value) => {
    categories.value = value
})
watch(() => categories.value, (value) => {
    emits('update:categories', value)
})


// Define on mounted
onMounted(async () => {
    if (props.fetchUrl) {
        try {
            const response = await fetch(props.fetchUrl)
            if (response.ok) {
                const data = await response.json()
                options.value = data.map(item => ({
                    value: item.id,
                    label: item.name,
                }))
            } else {
                console.error('Fetch failed:', response.statusText)
            }
        } catch (error) {
            console.error('Fetch error:', error)
        }
    }
})
</script>
