<template>

    <div class="app-navbar-item align-items-stretch ms-1 ms-md-4">

        <div class="d-flex align-items-center w-300px">

            <el-dropdown
                ref="dropdownRef"
                trigger="contextmenu"
                class="w-100"
                :hide-on-click="false"
                placement="bottom-end"
                :trigger-keys="[]"
                :persistent="true"
                @visible-change="handleVisibleChange"
            >

                <div class="d-lg-block w-100 position-relative mb-5 mb-lg-0">

                    <i class="ki-duotone ki-magnifier fs-2 text-gray-500 position-absolute top-50 translate-middle-y ms-5">
                        <span class="path1"></span>
                        <span class="path2"></span>
                    </i>

                    <input
                        v-model="search"
                        ref="searchInputRef"
                        type="text"
                        class="form-control form-control-sm form-control-solid ps-14 w-100"
                        placeholder="Search..."
                        @focus="dropdownRef.handleOpen()"
                        @keydown="handleKeydown($event)"
                    />

                    <span v-if="loading" class="position-absolute top-50 end-0 translate-middle-y lh-0 me-6">
                        <span class="spinner-border h-15px w-15px align-middle text-gray-500"></span>
                    </span>

                    <span
                        v-if="hasSearchValue && !loading"
                        class="btn btn-flush btn-active-color-primary position-absolute top-50 end-0 translate-middle-y lh-0 me-3"
                        @click="clearSearchValue"
                    >
                        <i class="ki-duotone ki-cross fs-2">
                            <span class="path1"></span>
                            <span class="path2"></span>
                        </i>
                    </span>

                </div>

                <template #dropdown>

                    <div v-if="!hasSearchValue" class="w-300px py-7 px-7">

                        <div class="text-center">

                            <div class="d-flex flex-stack fw-semibold mb-8">
                                <span class="text-muted fs-6 me-2">Recently viewed:</span>
                            </div>

                            <div v-if="false" class="scroll-y mh-200px mh-lg-325px">

                                <div v-for="item in recentlyViewedItems" class="d-flex align-items-center mb-5">

                                    <div class="symbol symbol-40px me-4">
                                        <span class="symbol-label bg-light">
                                            <Icon :type="item.icon" class="fs-2"/>
                                        </span>
                                    </div>

                                    <div class="d-flex flex-column">
                                        <Link
                                            :href="item.href"
                                            class="fs-6 text-gray-800 text-hover-primary fw-semibold"
                                            v-text="item.name"
                                        />
                                        <span class="fs-7 text-muted fw-semibold">{{ item.type }}</span>
                                    </div>

                                </div>

                            </div>

                            <div v-else>

                                <div class="pt-10 pb-10">
                                    <i class="ki-duotone ki-search-list fs-4x opacity-50">
                                        <span class="path1"></span>
                                        <span class="path2"></span>
                                        <span class="path3"></span>
                                    </i>
                                </div>

                                <div class="pb-15 fw-semibold">
                                    <h3 class="text-gray-600 fs-5 mb-2">No recent results</h3>
                                    <div class="text-muted fs-7">You haven't viewed anything recently</div>
                                </div>

                            </div>

                        </div>

                    </div>

                    <div v-else class="w-800px row m-0 min-h-300px">

                        <div class="col-4 bg-light py-4">

                            <a
                                href="#"
                                class="btn btn-light w-100 text-start py-3 mb-1"
                                :class="{ 'active' : activeTab === 'all' }"
                                @click="openTab('all')"
                            >
                                <i class="ki-duotone ki-abstract-26 me-2 fs-3 text-gray-600">
                                    <span class="path1"></span>
                                    <span class="path2"></span>
                                </i>
                                <span class="text-dark fs-7 fw-semibold">All categories <span v-if="allRecords.length">({{ allRecords.length }})</span></span>
                            </a>

                            <el-divider class="mt-1 mb-2"/>

                            <a
                                v-if="$hasAccessToSubscriptionFeature('calls') && $hasPermission('calls_index')"
                                href="#"
                                class="btn btn-light w-100 text-start py-3 mb-1"
                                :class="{ 'active' : activeTab === 'calls' }"
                                @click="openTab('calls')"
                            >
                                <Icon type="call" class="me-2 fs-3 text-gray-600"/>
                                <span class="text-dark fs-7 fw-semibold">Calls <span v-if="calls.length">({{ calls.length }})</span></span>
                            </a>

                            <a
                                v-if="$hasAccessToSubscriptionFeature('meetings') && $hasPermission('meetings_index')"
                                href="#"
                                class="btn btn-light w-100 text-start py-3 mb-1"
                                :class="{ 'active' : activeTab === 'meetings' }"
                                @click="openTab('meetings')"
                            >
                                <Icon type="meeting" class="me-2 fs-3 text-gray-600"/>
                                <span class="text-dark fs-7 fw-semibold">Meetings <span v-if="meetings.length">({{ meetings.length }})</span></span>
                            </a>

                            <a
                                v-if="$hasAccessToSubscriptionFeature('tasks') && $hasPermission('tasks_index')"
                                href="#"
                                class="btn btn-light w-100 text-start py-3 mb-1"
                                :class="{ 'active' : activeTab === 'tasks' }"
                                @click="openTab('tasks')"
                            >
                                <Icon type="task" class="me-2 fs-3 text-gray-600"/>
                                <span class="text-dark fs-7 fw-semibold">Tasks <span v-if="tasks.length">({{ tasks.length }})</span></span>
                            </a>

                            <a
                                v-if="$hasAccessToSubscriptionFeature('companies') && $hasPermission('companies_index')"
                                href="#"
                                class="btn btn-light w-100 text-start py-3 mb-1"
                                :class="{ 'active' : activeTab === 'companies' }"
                                @click="openTab('companies')"
                            >
                                <Icon type="company" class="me-2 fs-3 text-gray-600"/>
                                <span class="text-dark fs-7 fw-semibold">Companies <span v-if="companies.length">({{ companies.length }})</span></span>
                            </a>

                            <a
                                v-if="$hasAccessToSubscriptionFeature('contacts') && $hasPermission('contacts_index')"
                                href="#"
                                class="btn btn-light w-100 text-start py-3 mb-1"
                                :class="{ 'active' : activeTab === 'contacts' }"
                                @click="openTab('contacts')"
                            >
                                <Icon type="contact" class="me-2 fs-3 text-gray-600"/>
                                <span class="text-dark fs-7 fw-semibold">Contacts <span v-if="contacts.length">({{ contacts.length }})</span></span>
                            </a>

                            <a
                                v-if="$hasAccessToSubscriptionFeature('deals') && $hasPermission('deals_index')"
                                href="#"
                                class="btn btn-light w-100 text-start py-3 mb-1"
                                :class="{ 'active' : activeTab === 'deals' }"
                                @click="openTab('deals')"
                            >
                                <Icon type="deal" class="me-2 fs-3 text-gray-600"/>
                                <span class="text-dark fs-7 fw-semibold">Deals <span v-if="deals.length">({{ deals.length }})</span></span>
                            </a>

                            <a
                                v-if="$hasAccessToSubscriptionFeature('quotations') && $hasPermission('quotations_index')"
                                href="#"
                                class="btn btn-light w-100 text-start py-3 mb-1"
                                :class="{ 'active' : activeTab === 'quotations' }"
                                @click="openTab('quotations')"
                            >
                                <Icon type="quotation" class="me-2 fs-3 text-gray-600"/>
                                <span class="text-dark fs-7 fw-semibold">Quotations <span v-if="quotations.length">({{ quotations.length }})</span></span>
                            </a>

                            <a
                                v-if="$hasAccessToSubscriptionFeature('invoices') && $hasPermission('invoices_index')"
                                href="#"
                                class="btn btn-light w-100 text-start py-3 mb-1"
                                :class="{ 'active' : activeTab === 'invoices' }"
                                @click="openTab('invoices')"
                            >
                                <Icon type="invoice" class="me-2 fs-3 text-gray-600"/>
                                <span class="text-dark fs-7 fw-semibold">Invoices <span v-if="invoices.length">({{ invoices.length }})</span></span>
                            </a>

                            <a
                                v-if="$hasAccessToSubscriptionFeature('products') && $hasPermission('products_index')"
                                href="#"
                                class="btn btn-light w-100 text-start py-3 mb-1"
                                :class="{ 'active' : activeTab === 'products' }"
                                @click="openTab('products')"
                            >
                                <Icon type="product" class="me-2 fs-3 text-gray-600"/>
                                <span class="text-dark fs-7 fw-semibold">Products <span v-if="products.length">({{ products.length }})</span></span>
                            </a>

                            <a
                                v-if="$hasAccessToSubscriptionFeature('projects') && $hasPermission('projects_index')"
                                href="#"
                                class="btn btn-light w-100 text-start py-3 mb-1"
                                :class="{ 'active' : activeTab === 'projects' }"
                                @click="openTab('projects')"
                            >
                                <Icon type="project" class="me-2 fs-3 text-gray-600"/>
                                <span class="text-dark fs-7 fw-semibold">Projects <span v-if="projects.length">({{ projects.length }})</span></span>
                            </a>

                        </div>
                        <div class="col-8 ps-4">

                            <div v-if="activeTab === 'all'" class="h-100 pt-10">

                                <div v-if="allRecords.length > 0">

                                    <div class="d-flex flex-stack fw-semibold mb-4">
                                        <span class="fs-5 me-2">All search results</span>
                                    </div>

                                    <div class="scroll-y mh-500px">

                                        <template v-for="record in allRecords" :key="record.id">
                                            <Call
                                                v-if="record.record_type === 'call'"
                                                :id="record.id"
                                                :name="record.name"
                                                :company="record.company"
                                                :contact="record.contact"
                                            />
                                            <Meeting
                                                v-else-if="record.record_type === 'meeting'"
                                                :id="record.id"
                                                :name="record.name"
                                                :company="record.company"
                                                :contact="record.contact"
                                            />
                                            <Task
                                                v-else-if="record.record_type === 'task'"
                                                :id="record.id"
                                                :name="record.name"
                                                :company="record.company"
                                                :contact="record.contact"
                                            />
                                            <Company
                                                v-else-if="record.record_type === 'company'"
                                                :id="record.id"
                                                :name="record.name"
                                                :assignedTo="record.assignedTo"
                                            />
                                            <Contact
                                                v-else-if="record.record_type === 'contact'"
                                                :id="record.id"
                                                :name="record.name"
                                                :assignedTo="record.assignedTo"
                                            />
                                            <Deal
                                                v-else-if="record.record_type === 'deal'"
                                                :id="record.id"
                                                :name="record.name"
                                                :status="record.status"
                                                :amount="record.amount"
                                                :type="record.type"
                                                :company="record.company"
                                                :contact="record.contact"
                                            />
                                            <Quotation
                                                v-else-if="record.record_type === 'quotation'"
                                                :id="record.id"
                                                :name="record.name"
                                                :status="record.status"
                                                :number="record.number"
                                                :type="record.type"
                                                :amount="record.amount"
                                                :company="record.company"
                                                :contact="record.contact"
                                            />
                                            <Invoice
                                                v-else-if="record.record_type === 'invoice'"
                                                :id="record.id"
                                                :name="record.name"
                                                :status="record.status"
                                                :number="record.number"
                                                :type="record.type"
                                                :amount="record.amount"
                                                :company="record.company"
                                                :contact="record.contact"
                                            />
                                            <Product
                                                v-else-if="record.record_type === 'product'"
                                                :id="record.id"
                                                :name="record.name"
                                                :sellingAmount="record.selling_amount"
                                                :stock="record.stock"
                                            />
                                            <Project
                                                v-else-if="record.record_type === 'project'"
                                                :id="record.id"
                                                :name="record.name"
                                                :type="record.type"
                                                :progress="record.progress"
                                            />
                                        </template>

                                    </div>

                                </div>

                                <div v-else class="d-flex flex-column flex-center">

                                    <i class="ki-duotone ki-magnifier fs-5x mb-8">
                                        <span class="path1"></span>
                                        <span class="path2"></span>
                                    </i>

                                    <div class="fs-2 fw-semibold text-center mb-5">
                                        No results for <span class="fw-bold">`{{ search }}`</span>
                                    </div>

                                    <a v-if="$hasAccessToSubscriptionFeature('companies') && $hasPermission('companies_create')" href="#" class="d-flex align-items-center text-primary mb-3">
                                        <Icon type="create" class="me-2 fs-4 text-primary"/>
                                        <span
                                            class="fs-7 fw-bold"
                                            @click="openCreateModal('company')"
                                        >Add company <span class="fw-bolder">`{{ search }}`</span></span>
                                    </a>

                                    <a v-if="$hasAccessToSubscriptionFeature('contacts') && $hasPermission('contacts_create')" href="#" class="d-flex align-items-center text-primary mb-3">
                                        <Icon type="create" class="me-2 fs-4 text-primary"/>
                                        <span
                                            class="fs-7 fw-bold"
                                            @click="openCreateModal('contact')"
                                        >Add contact <span class="fw-bolder">`{{ search }}`</span></span>
                                    </a>

                                    <a v-if="$hasAccessToSubscriptionFeature('deals') && $hasPermission('deals_create')" href="#" class="d-flex align-items-center text-primary">
                                        <Icon type="create" class="me-2 fs-4 text-primary"/>
                                        <span
                                            class="fs-7 fw-bold"
                                            @click="openCreateModal('deal')"
                                        >Add deal <span class="fw-bolder">`{{ search }}`</span></span>
                                    </a>

                                </div>

                            </div>

                            <div v-if="activeTab === 'calls'" class="h-100 pt-10">

                                <div v-if="calls.length > 0">

                                    <div class="d-flex flex-stack fw-semibold mb-4">
                                        <span class="fs-5 me-2">Search results for calls:</span>
                                    </div>

                                    <div class="scroll-y mh-500px">

                                        <Call
                                            v-for="call in calls"
                                            :key="call.id"
                                            :id="call.id"
                                            :name="call.name"
                                            :company="call.company"
                                            :contact="call.contact"
                                        />

                                    </div>

                                </div>

                                <div v-else class="d-flex flex-column flex-center">

                                    <Icon type="call" class=" fs-5x mb-8"/>

                                    <div class="fs-2 fw-semibold text-center mb-5">
                                        No results in calls for <span class="fw-bold">`{{ search }}`</span>
                                    </div>

                                    <a v-if="$hasPermission('calls_create')" href="#" class="d-flex align-items-center text-primary">
                                        <Icon type="create" class="me-1 fs-3 text-primary"/>
                                        <span
                                            class="fs-7 fw-bold"
                                            @click="openCreateModal('call')"
                                        >Add call <span class="fw-bolder">`{{ search }}`</span></span>
                                    </a>

                                </div>

                            </div>

                            <div v-if="activeTab === 'meetings'" class="h-100 pt-10">

                                <div v-if="meetings.length > 0">

                                    <div class="d-flex flex-stack fw-semibold mb-4">
                                        <span class="fs-5 me-2">Search results for meetings:</span>
                                    </div>

                                    <div class="scroll-y mh-500px">

                                        <Meeting
                                            v-for="meeting in meetings"
                                            :key="meeting.id"
                                            :id="meeting.id"
                                            :name="meeting.name"
                                            :company="meeting.company"
                                            :contact="meeting.contact"
                                        />

                                    </div>

                                </div>

                                <div v-else class="d-flex flex-column flex-center">

                                    <Icon type="meeting" class=" fs-5x mb-8"/>

                                    <div class="fs-2 fw-semibold text-center mb-5">
                                        No results in meetings for <span class="fw-bold">`{{ search }}`</span>
                                    </div>

                                    <a v-if="$hasPermission('meetings_create')" href="#" class="d-flex align-items-center text-primary">
                                        <Icon type="create" class="me-1 fs-3 text-primary"/>
                                        <span
                                            class="fs-7 fw-bold"
                                            @click="openCreateModal('meeting')"
                                        >Add meeting <span class="fw-bolder">`{{ search }}`</span></span>
                                    </a>

                                </div>

                            </div>

                            <div v-if="activeTab === 'tasks'" class="h-100 pt-10">

                                <div v-if="tasks.length > 0">

                                    <div class="d-flex flex-stack fw-semibold mb-4">
                                        <span class="fs-5 me-2">Search results for tasks:</span>
                                    </div>

                                    <div class="scroll-y mh-500px">

                                        <Task
                                            v-for="task in tasks"
                                            :key="task.id"
                                            :id="task.id"
                                            :name="task.name"
                                            :company="task.company"
                                            :contact="task.contact"
                                        />

                                    </div>

                                </div>

                                <div v-else class="d-flex flex-column flex-center">

                                    <Icon type="task" class=" fs-5x mb-8"/>

                                    <div class="fs-2 fw-semibold text-center mb-5">
                                        No results in tasks for <span class="fw-bold">`{{ search }}`</span>
                                    </div>

                                    <a v-if="$hasPermission('tasks_create')" href="#" class="d-flex align-items-center text-primary">
                                        <Icon type="create" class="me-1 fs-3 text-primary"/>
                                        <span
                                            class="fs-7 fw-bold"
                                            @click="openCreateModal('task')"
                                        >Add task <span class="fw-bolder">`{{ search }}`</span></span>
                                    </a>

                                </div>

                            </div>

                            <div v-if="activeTab === 'companies'" class="h-100 pt-10">

                                <div v-if="companies.length > 0">

                                    <div class="d-flex flex-stack fw-semibold mb-4">
                                        <span class="fs-5 me-2">Search results for companies:</span>
                                    </div>

                                    <div class="scroll-y mh-500px">

                                        <Company
                                            v-for="company in companies"
                                            :key="company.id"
                                            :id="company.id"
                                            :name="company.name"
                                            :assignedTo="company.assignedTo"
                                        />

                                    </div>

                                </div>

                                <div v-else class="d-flex flex-column flex-center">

                                    <Icon type="company" class=" fs-5x mb-8"/>

                                    <div class="fs-2 fw-semibold text-center mb-5">
                                        No results in companies for <span class="fw-bold">`{{ search }}`</span>
                                    </div>

                                    <a v-if="$hasPermission('companies_create')" href="#" class="d-flex align-items-center text-primary">
                                        <Icon type="create" class="me-1 fs-3 text-primary"/>
                                        <span
                                            class="fs-7 fw-bold"
                                            @click="openCreateModal('company')"
                                        >Add company <span class="fw-bolder">`{{ search }}`</span></span>
                                    </a>

                                </div>

                            </div>

                            <div v-if="activeTab === 'contacts'" class="h-100 pt-10">

                                <div v-if="contacts.length > 0">

                                    <div class="d-flex flex-stack fw-semibold mb-4">
                                        <span class="fs-5 me-2">Search results for contacts:</span>
                                    </div>

                                    <div class="scroll-y mh-500px">

                                        <Contact
                                            v-for="contact in contacts"
                                            :key="contact.id"
                                            :id="contact.id"
                                            :name="contact.name"
                                            :assignedTo="contact.assignedTo"
                                        />

                                    </div>

                                </div>

                                <div v-else class="d-flex flex-column flex-center">

                                    <Icon type="contact" class=" fs-5x mb-8"/>

                                    <div class="fs-2 fw-semibold text-center mb-5">
                                        No results in contacts for <span class="fw-bold">`{{ search }}`</span>
                                    </div>

                                    <a v-if="$hasPermission('contacts_create')" href="#" class="d-flex align-items-center text-primary">
                                        <Icon type="create" class="me-1 fs-3 text-primary"/>
                                        <span
                                            class="fs-7 fw-bold"
                                            @click="openCreateModal('contact')"
                                        >Add contact <span class="fw-bolder">`{{ search }}`</span></span>
                                    </a>

                                </div>

                            </div>

                            <div v-if="activeTab === 'deals'" class="h-100 pt-10">

                                <div v-if="deals.length > 0">

                                    <div class="d-flex flex-stack fw-semibold mb-4">
                                        <span class="fs-5 me-2">Search results for deals:</span>
                                    </div>

                                    <div class="scroll-y mh-500px">

                                        <Deal
                                            v-for="deal in deals"
                                            :key="deal.id"
                                            :id="deal.id"
                                            :name="deal.name"
                                            :status="deal.status"
                                            :amount="deal.amount"
                                            :type="deal.type"
                                            :company="deal.company"
                                            :contact="deal.contact"
                                        />

                                    </div>

                                </div>

                                <div v-else class="d-flex flex-column flex-center">

                                    <Icon type="deal" class=" fs-5x mb-8"/>

                                    <div class="fs-2 fw-semibold text-center mb-5">
                                        No results in deals for <span class="fw-bold">`{{ search }}`</span>
                                    </div>

                                    <a v-if="$hasPermission('deals_create')" href="#" class="d-flex align-items-center text-primary">
                                        <Icon type="create" class="me-1 fs-3 text-primary"/>
                                        <span
                                            class="fs-7 fw-bold"
                                            @click="openCreateModal('deal')"
                                        >Add deal <span class="fw-bolder">`{{ search }}`</span></span>
                                    </a>

                                </div>

                            </div>

                            <div v-if="activeTab === 'quotations'" class="h-100 pt-10">

                                <div v-if="quotations.length > 0">

                                    <div class="d-flex flex-stack fw-semibold mb-4">
                                        <span class="fs-5 me-2">Search results for quotations:</span>
                                    </div>

                                    <div class="scroll-y mh-500px">

                                        <Quotation
                                            v-for="quotation in quotations"
                                            :key="quotation.id"
                                            :id="quotation.id"
                                            :name="quotation.name"
                                            :status="quotation.status"
                                            :number="quotation.number"
                                            :type="quotation.type"
                                            :amount="quotation.amount"
                                            :company="quotation.company"
                                            :contact="quotation.contact"
                                        />

                                    </div>

                                </div>

                                <div v-else class="d-flex flex-column flex-center">

                                    <Icon type="quotation" class=" fs-5x mb-8"/>

                                    <div class="fs-2 fw-semibold text-center mb-5">
                                        No results in quotations for <span class="fw-bold">`{{ search }}`</span>
                                    </div>

                                    <a v-if="$hasPermission('quotations_create')" href="#" class="d-flex align-items-center text-primary">
                                        <Icon type="create" class="me-1 fs-3 text-primary"/>
                                        <span
                                            class="fs-7 fw-bold"
                                            @click="openCreateModal('quotation')"
                                        >Add quotation <span class="fw-bolder">`{{ search }}`</span></span>
                                    </a>

                                </div>

                            </div>

                            <div v-if="activeTab === 'invoices'" class="h-100 pt-10">

                                <div v-if="invoices.length > 0">

                                    <div class="d-flex flex-stack fw-semibold mb-4">
                                        <span class="fs-5 me-2">Search results for invoices:</span>
                                    </div>

                                    <div class="scroll-y mh-500px">

                                        <Invoice
                                            v-for="invoice in invoices"
                                            :key="invoice.id"
                                            :id="invoice.id"
                                            :name="invoice.name"
                                            :status="invoice.status"
                                            :number="invoice.number"
                                            :type="invoice.type"
                                            :amount="invoice.amount"
                                            :company="invoice.company"
                                            :contact="invoice.contact"
                                        />

                                    </div>

                                </div>

                                <div v-else class="d-flex flex-column flex-center">

                                    <Icon type="invoice" class=" fs-5x mb-8"/>

                                    <div class="fs-2 fw-semibold text-center mb-5">
                                        No results in invoices for <span class="fw-bold">`{{ search }}`</span>
                                    </div>

                                    <a v-if="$hasPermission('invoices_create')" href="#" class="d-flex align-items-center text-primary">
                                        <Icon type="create" class="me-1 fs-3 text-primary"/>
                                        <span
                                            class="fs-7 fw-bold"
                                            @click="openCreateModal('invoice')"
                                        >Add invoice <span class="fw-bolder">`{{ search }}`</span></span>
                                    </a>

                                </div>

                            </div>

                            <div v-if="activeTab === 'products'" class="h-100 pt-10">

                                <div v-if="products.length > 0">

                                    <div class="d-flex flex-stack fw-semibold mb-4">
                                        <span class="fs-5 me-2">Search results for products:</span>
                                    </div>

                                    <div class="scroll-y mh-500px">

                                        <Product
                                            v-for="product in products"
                                            :key="product.id"
                                            :id="product.id"
                                            :name="product.name"
                                            :sellingAmount="product.selling_amount"
                                            :stock="product.stock"
                                        />

                                    </div>

                                </div>

                                <div v-else class="d-flex flex-column flex-center">

                                    <Icon type="product" class=" fs-5x mb-8"/>

                                    <div class="fs-2 fw-semibold text-center mb-5">
                                        No results in products for <span class="fw-bold">`{{ search }}`</span>
                                    </div>

                                    <a v-if="$hasPermission('products_create')" href="#" class="d-flex align-items-center text-primary">
                                        <Icon type="create" class="me-1 fs-3 text-primary"/>
                                        <span
                                            class="fs-7 fw-bold"
                                            @click="openCreateModal('product')"
                                        >Add product <span class="fw-bolder">`{{ search }}`</span></span>
                                    </a>

                                </div>

                            </div>

                            <div v-if="activeTab === 'projects'" class="h-100 pt-10">

                                <div v-if="projects.length > 0">

                                    <div class="d-flex flex-stack fw-semibold mb-4">
                                        <span class="fs-5 me-2">Search results for projects:</span>
                                    </div>

                                    <div class="scroll-y mh-500px">

                                        <Project
                                            v-for="project in projects"
                                            :key="project.id"
                                            :id="project.id"
                                            :name="project.name"
                                            :type="project.type"
                                            :progress="project.progress"
                                        />

                                    </div>

                                </div>

                                <div v-else class="d-flex flex-column flex-center">

                                    <Icon type="project" class=" fs-5x mb-8"/>

                                    <div class="fs-2 fw-semibold text-center mb-5">
                                        No results in projects for <span class="fw-bold">`{{ search }}`</span>
                                    </div>

                                    <a v-if="$hasPermission('projects_create')" href="#" class="d-flex align-items-center text-primary">
                                        <Icon type="create" class="me-1 fs-3 text-primary"/>
                                        <span
                                            class="fs-7 fw-bold"
                                            @click="openCreateModal('project')"
                                        >Add project <span class="fw-bolder">`{{ search }}`</span></span>
                                    </a>

                                </div>

                            </div>

                        </div>

                    </div>

                </template>

            </el-dropdown>

        </div>

    </div>

</template>

<script setup>
import { ref, watch, computed } from 'vue'
import Icon from '@/Shares/General/Icon.vue'
import { useModalStore } from '@/Stores/modalStore.js'
import CallCreateModal from '@/Shares/App/Modules/Calls/Modals/Create.vue'
import TaskCreateModal from '@/Shares/App/Modules/Tasks/Modals/Create.vue'
import MeetingCreateModal from '@/Shares/App/Modules/Meetings/Modals/Create.vue'
import CompanyCreateModal from '@/Shares/App/Modules/Companies/Modals/Create.vue'
import ContactCreateModal from '@/Shares/App/Modules/Contacts/Modals/Create.vue'
import DealCreateModal from '@/Shares/App/Modules/Deals/Modals/Create.vue'
import QuotationCreateModal from '@/Shares/App/Modules/Quotations/Modals/Create.vue'
import InvoiceCreateModal from '@/Shares/App/Modules/Invoices/Modals/Create.vue'
import ProductCreateModal from '@/Shares/App/Modules/Products/Modals/Create.vue'
import ProjectCreateModal from '@/Shares/App/Modules/Projects/Modals/Create.vue'
import axios from 'axios'
import { debounce } from 'lodash'
import Call from '@/Shares/App/Layout/Header/Navbar/SearchBar/Call.vue'
import Project from '@/Shares/App/Layout/Header/Navbar/SearchBar/Project.vue'
import Product from '@/Shares/App/Layout/Header/Navbar/SearchBar/Product.vue'
import Invoice from '@/Shares/App/Layout/Header/Navbar/SearchBar/Invoice.vue'
import Quotation from '@/Shares/App/Layout/Header/Navbar/SearchBar/Quotation.vue'
import Deal from '@/Shares/App/Layout/Header/Navbar/SearchBar/Deal.vue'
import Contact from '@/Shares/App/Layout/Header/Navbar/SearchBar/Contact.vue'
import Company from '@/Shares/App/Layout/Header/Navbar/SearchBar/Company.vue'
import Task from '@/Shares/App/Layout/Header/Navbar/SearchBar/Task.vue'
import Meeting from '@/Shares/App/Layout/Header/Navbar/SearchBar/Meeting.vue'


// Define variables
const modalStore = useModalStore()


// Define refs
const searchInputRef = ref()
const dropdownRef = ref()

const search = ref('')
const loading = ref(false)

const recentlyViewedItems = ref([])
const activeTab = ref('all')

const calls = ref([])
const meetings = ref([])
const tasks = ref([])
const companies = ref([])
const contacts = ref([])
const deals = ref([])
const quotations = ref([])
const invoices = ref([])
const products = ref([])
const projects = ref([])


// Define debounce
const debouncedSearch = debounce(() => {
    if (!search.value.trim()) {
        dropdownRef.value.handleClose()
        return
    }
    loading.value = true
    dropdownRef.value.handleOpen()
    axios.get(`/search/get`, { params: { search: search.value.trim() } })
        .then(response => {
            const data = response.data
            calls.value = data.calls
            meetings.value = data.meetings
            tasks.value = data.tasks
            companies.value = data.companies
            contacts.value = data.contacts
            deals.value = data.deals
            quotations.value = data.quotations
            invoices.value = data.invoices
            products.value = data.products
            projects.value = data.projects
        })
        .catch(error => {
            console.error('Fout bij het ophalen van gegevens', error)
        })
        .finally(() => {
            loading.value = false
        })
}, 250)


// Define watcher
watch(() => search.value, debouncedSearch)


// Define computed
const hasSearchValue = computed(() => search.value.trim().length > 0)
const allRecords = computed(() => {
    return [
        ...calls.value.map(item => ({ ...item, record_type: 'call' })),
        ...meetings.value.map(item => ({ ...item, record_type: 'meeting' })),
        ...tasks.value.map(item => ({ ...item, record_type: 'task' })),
        ...companies.value.map(item => ({ ...item, record_type: 'company' })),
        ...contacts.value.map(item => ({ ...item, record_type: 'contact' })),
        ...deals.value.map(item => ({ ...item, record_type: 'deal' })),
        ...quotations.value.map(item => ({ ...item, record_type: 'quotation' })),
        ...invoices.value.map(item => ({ ...item, record_type: 'invoice' })),
        ...products.value.map(item => ({ ...item, record_type: 'product' })),
        ...projects.value.map(item => ({ ...item, record_type: 'project' })),
    ]
})


// Define methods
function clearSearchValue() {
    search.value = ''
}

function openTab(tab) {
    activeTab.value = tab
}

function openCreateModal(modal) {

    switch (modal) {
        case 'call':
            dropdownRef.value.handleClose()
            modalStore.setProps({
                storeUrl: `/calls/store`,
                name: search.value,
            })
            modalStore.openModal(CallCreateModal)
            break
        case 'meeting':
            dropdownRef.value.handleClose()
            modalStore.setProps({
                storeUrl: `/meetings/store`,
                name: search.value,
            })
            modalStore.openModal(MeetingCreateModal)
            break
        case 'task':
            dropdownRef.value.handleClose()
            modalStore.setProps({
                storeUrl: `/tasks/store`,
                name: search.value,
            })
            modalStore.openModal(TaskCreateModal)
            break
        case 'company':
            dropdownRef.value.handleClose()
            modalStore.setProps({
                storeUrl: `/companies/store`,
                name: search.value,
            })
            modalStore.openModal(CompanyCreateModal)
            break
        case 'contact':
            dropdownRef.value.handleClose()
            modalStore.setProps({
                storeUrl: `/contact/store`,
                name: search.value,
            })
            modalStore.openModal(ContactCreateModal)
            break
        case 'deal':
            dropdownRef.value.handleClose()
            modalStore.setProps({
                storeUrl: `/deals/store`,
                name: search.value,
            })
            modalStore.openModal(DealCreateModal)
            break
        case 'quotation':
            dropdownRef.value.handleClose()
            modalStore.setProps({
                storeUrl: `/quotations/store`,
                name: search.value,
            })
            modalStore.openModal(QuotationCreateModal)
            break
        case 'invoice':
            dropdownRef.value.handleClose()
            modalStore.setProps({
                storeUrl: `/invoices/store`,
                name: search.value,
            })
            modalStore.openModal(InvoiceCreateModal)
            break
        case 'product':
            dropdownRef.value.handleClose()
            modalStore.setProps({
                storeUrl: `/products/store`,
                name: search.value,
            })
            modalStore.openModal(ProductCreateModal)
            break
        case 'project':
            dropdownRef.value.handleClose()
            modalStore.setProps({
                storeUrl: `/projects/store`,
                name: search.value,
            })
            modalStore.openModal(ProjectCreateModal)
            break
    }

}

function handleKeydown(event) {
    if (event.key === ' ') {
        event.preventDefault();
        search.value += ' ';
        event.stopPropagation();
        return false;
    }
}

function handleVisibleChange(open){
    if(!open){
        clearSearchValue()
    }
}

</script>
