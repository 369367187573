<script setup>
import { onBeforeMount, onMounted, onUnmounted } from 'vue'
import { Crisp } from 'crisp-sdk-web'
import { usePage } from '@inertiajs/vue3'

const page = usePage()

onBeforeMount(() => {

    if (!page.props.auth){
        return;
    }

    Crisp.configure('f0c3ad6e-a38c-4fd2-93d0-a132adcbe909', {
        'locale': page.props.auth.user.language
    })

    Crisp.setTokenId(page.props.auth.user.support_token)
    Crisp.user.setEmail(page.props.auth.user.email)
    Crisp.user.setEmail(page.props.auth.user.email)
    Crisp.user.setNickname(page.props.auth.user.name)
    Crisp.user.setPhone(page.props.auth.user.telephone)
    Crisp.user.setCompany(page.props.auth.account.name)

    Crisp.setTokenId(page.props.auth.user.support_token)
    Crisp.session.setData({
        user_id: page.props.auth.user.id,
        account_id: page.props.auth.account.id,
        account_name: page.props.auth.account.name,
    })

    // Show chat
    Crisp.chat.show()

})

onUnmounted(() => {

    Crisp.setTokenId();
    Crisp.session.reset();

    // Hide chat
    Crisp.chat.hide()

})

</script>
