<template>

    <label class="form-label fs-7">Backorders</label>

    <el-checkbox
        v-model="backordersAllowed"
        label="Backorders allowed"
        class="w-100 form-control py-5"
    />

</template>

<script setup>
import { ref, watch } from 'vue'

// Define props
const props = defineProps({
    backordersAllowed: Boolean,
})


// Define emits
const emits = defineEmits(['update:backordersAllowed'])


// Define refs
let backordersAllowed = ref(props.backordersAllowed || '')


// Update the local backordersAllowed when the prop changes
watch(() => props.backordersAllowed, (newBackordersAllowed) => {
    backordersAllowed.value = newBackordersAllowed
})


// Watch for changes and emit them
watch(() => backordersAllowed.value, (newBackordersAllowed) => {
    emits('update:backordersAllowed', newBackordersAllowed)
})
</script>

