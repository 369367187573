<template>
    <div class="menu-item me-0 me-lg-2 d-flex align-items-center">

        <Link href="/subscription/choose" class="btn btn-sm btn-primary me-2">
            <span class="menu-title text-white">Upgrade now</span>
        </Link>

    </div>
</template>

<script setup>

</script>
