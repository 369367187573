<template>

    <Modal size="xl" title="Add Contact">

        <div class="modal-body py-0 px-0">

            <form
                @submit.prevent="submit"
                class="form"
            >

                <div class="row g-0">

                    <div class="col-6 border border-top-0 border-bottom-0 border-left-0 border-right-1 border-gray-300 py-4 px-0">

                        <div class="px-6">

                            <div v-if="!modalStore.props.hideName || !modalStore.props.hideAssignedTo" class="row" :class="{ 'mb-3' : form.assignedTo === currentUser.id }">

                                <div v-if="!modalStore.props.hideName" class="col-md-6">

                                    <Name :name="form.name" @update:name="form.name = $event"/>
                                    <div v-if="form.errors.name" v-text="form.errors.name" class="text-danger small mt-1"/>

                                </div>

                                <div v-if="!modalStore.props.hideAssignedTo" class="col-md-6">

                                    <AssignedTo
                                        fetch-url="/contacts/create/get/users"
                                        :assigned-to="form.assignedTo"
                                        @update:assignedTo="form.assignedTo = $event"
                                    />
                                    <div v-if="form.errors.assignedTo" v-text="form.errors.assignedTo" class="text-danger small mt-1"/>

                                </div>

                            </div>

                            <div v-if="!modalStore.props.hideGender || !modalStore.props.hideTags" class="row mb-3">

                                <div v-if="!modalStore.props.hideGender" class="col-md-6">

                                    <Gender
                                        fetch-url="/contacts/create/get/genders"
                                        :gender="form.gender"
                                        @update:gender="form.gender = $event"
                                    />
                                    <div v-if="form.errors.gender" v-text="form.errors.gender" class="text-danger small mt-1"/>

                                </div>

                                <div v-if="!modalStore.props.hideTags" class="col-md-6">

                                    <Tags
                                        fetch-url="/contacts/create/get/tags"
                                        :tags="form.tags"
                                        @update:tags="form.tags = $event"
                                    />
                                    <div v-if="form.errors.tags" v-text="form.errors.tags" class="text-danger small mt-1"/>

                                </div>

                            </div>

                            <div v-if="!modalStore.props.hideLanguage || !modalStore.props.hideLanguage" class="row mb-3">

                                <div v-if="!modalStore.props.hideSource" class="col-md-6">

                                    <Source
                                        fetch-url="/contacts/create/get/sources"
                                        :source="form.source"
                                        @update:source="form.source = $event"
                                    />
                                    <div v-if="form.errors.source" v-text="form.errors.source" class="text-danger small mt-1"/>

                                </div>

                                <div v-if="!modalStore.props.hideLanguage" class="col-md-6">

                                    <Language
                                        fetch-url="/contacts/create/get/languages"
                                        :language="form.language"
                                        @update:language="form.language = $event"
                                    />
                                    <div v-if="form.errors.language" v-text="form.errors.language" class="text-danger small mt-1"/>

                                </div>

                            </div>

                            <el-collapse-transition>
                                <div v-if="showMoreDetails">

                                    <div v-if="!modalStore.props.hideIban || !modalStore.props.hideBic || !modalStore.props.hideImage" class="row mb-3">

                                        <div v-if="!modalStore.props.hideIban || !modalStore.props.hideBic" class="col-md-6">

                                            <div class="row g-4">

                                                <div v-if="!modalStore.props.hideIban" class="col-md-12">

                                                    <Iban :iban="form.iban" @update:iban="form.iban = $event"/>
                                                    <div v-if="form.errors.iban" v-text="form.errors.iban" class="text-danger small mt-1"/>

                                                </div>

                                                <div v-if="!modalStore.props.hideBic" class="col-md-12">

                                                    <Bic :bic="form.bic" @update:bic="form.bic = $event"/>
                                                    <div v-if="form.errors.bic" v-text="form.errors.bic" class="text-danger small mt-1"/>

                                                </div>

                                            </div>

                                        </div>

                                        <div v-if="!modalStore.props.hideImage" class="col-md-6">

                                            <Image :image="form.image" @update:image="form.image = $event"/>
                                            <div v-if="form.errors.image" v-text="form.errors.image" class="text-danger small mt-1"/>

                                        </div>

                                    </div>

                                    <div v-if="!modalStore.props.hideBirthdate || !modalStore.props.hideWebsite" class="row mb-3">

                                        <div v-if="!modalStore.props.hideBirthdate" class="col-md-6">

                                            <Birthdate :birthdate="form.birthdate" @update:birthdate="form.birthdate = $event"/>
                                            <div v-if="form.errors.birthdate" v-text="form.errors.birthdate" class="text-danger small mt-1"/>

                                        </div>

                                        <div v-if="!modalStore.props.hideWebsite" class="col-md-6">

                                            <Website :website="form.website" @update:website="form.website = $event"/>
                                            <div v-if="form.errors.website" v-text="form.errors.website" class="text-danger small mt-1"/>

                                        </div>

                                    </div>

                                    <div v-if="!modalStore.props.hideDescription" class="row mb-3">

                                        <div class="col-md-12">

                                            <Description :description="form.description" @update:description="form.description = $event"/>
                                            <div v-if="form.errors.description" v-text="form.errors.description" class="text-danger small mt-1"/>

                                        </div>

                                    </div>

                                    <CustomFields
                                        :fetchUrl="`/contacts/create/get/custom-fields`"
                                        :customFields="form.customFields"
                                        :errors="form.errors"
                                        @update:customFields="form.customFields = $event"
                                    />

                                </div>

                            </el-collapse-transition>

                        </div>

                        <el-divider class="mb-4"></el-divider>

                        <div class="d-flex align-items-center px-8 py-0 justify-content-between">

                            <div
                                v-if="showMoreDetails === false"
                                class="text-primary cursor-pointer"
                                @click="showMoreDetails = true"
                            >
                                More details <i class="ki-duotone ki-down text-primary"></i>
                            </div>

                            <div
                                v-if="showMoreDetails === true"
                                class="text-primary cursor-pointer"
                                @click="showMoreDetails = false"
                            >
                                Less details <i class="ki-duotone ki-up text-primary"></i>
                            </div>

                            <button
                                type="submit"
                                class="btn btn-sm btn-primary"
                                :disabled="form.processing"
                                :data-kt-indicator="form.processing ? 'on' : 'off'"
                            >

                                <span class="indicator-label">
                                    <i class="ki-duotone ki-plus fs-6 text-white me-1"></i> Create contact
                                </span>

                                <span class="indicator-progress">
                                    Please wait... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>

                            </button>

                        </div>

                    </div>

                    <div class="col-6 py-0 px-6">

                        <el-divider v-if="!modalStore.props.hideEmails" border-style="dashed" content-position="left">Emails</el-divider>

                        <div v-if="!modalStore.props.hideEmails" class="row mb-3">

                            <div class="col-md-12">

                                <Emails
                                    :emails="form.emails"
                                    :errors="form.errors"
                                    @update:emails="form.emails = $event"
                                />

                            </div>

                        </div>

                        <el-divider v-if="!modalStore.props.hideTelephones" border-style="dashed" content-position="left">Telephones</el-divider>

                        <div v-if="!modalStore.props.hideTelephones" class="row mb-3">

                            <div class="col-md-12">

                                <Telephones
                                    :telephones="form.telephones"
                                    :errors="form.errors"
                                    @update:telephones="form.telephones = $event"
                                />

                            </div>

                        </div>

                        <el-divider v-if="!modalStore.props.hideAddresses" border-style="dashed" content-position="left">Addresses</el-divider>

                        <div v-if="!modalStore.props.hideAddresses" class="row mb-3">

                            <div class="col-md-12">

                                <Addresses
                                    :addresses="form.addresses"
                                    addressesFetchUrl="/contacts/create/get/addresses/countries"
                                    :errors="form.errors"
                                    @update:addresses="form.addresses = $event"
                                />

                            </div>

                        </div>

                        <el-divider v-if="!modalStore.props.hideCompanies" border-style="dashed" content-position="left">Companies</el-divider>

                        <div v-if="!modalStore.props.hideCompanies" class="row mb-3">

                            <div class="col-md-12">

                                <Companies
                                    :companies="form.companies"
                                    fetchUrl="/contacts/create/get/companies"
                                    :errors="form.errors"
                                    @update:companies="form.companies = $event"
                                />

                            </div>

                        </div>

                    </div>

                </div>

            </form>

        </div>

    </Modal>

</template>

<script setup>
import Modal from '@/Shares/General/Modal.vue'
import { useForm, usePage } from '@inertiajs/vue3'
import { onMounted, ref } from 'vue'
import Name from '@/Shares/App/Modules/Contacts/Modals/Partials/Form/Name.vue'
import Description from '@/Shares/App/Modules/Contacts/Modals/Partials/Form/Description.vue'
import AssignedTo from '@/Shares/General/Form/AssignedTo.vue'
import Tags from '@/Shares/General/Form/Tags.vue'
import Source from '@/Shares/App/Modules/Contacts/Modals/Partials/Form/Source.vue'
import Website from '@/Shares/App/Modules/Contacts/Modals/Partials/Form/Website.vue'
import Language from '@/Shares/App/Modules/Contacts/Modals/Partials/Form/Language.vue'
import Image from '@/Shares/App/Modules/Contacts/Modals/Partials/Form/Image.vue'
import Iban from '@/Shares/App/Modules/Contacts/Modals/Partials/Form/Iban.vue'
import Bic from '@/Shares/App/Modules/Contacts/Modals/Partials/Form/Bic.vue'
import Gender from '@/Shares/App/Modules/Contacts/Modals/Partials/Form/Gender.vue'
import Birthdate from '@/Shares/App/Modules/Contacts/Modals/Partials/Form/Birthdate.vue'
import { useModalStore } from '@/Stores/modalStore.js'
import Emails from '@/Shares/App/Modules/Contacts/Modals/Partials/Form/Emails.vue'
import Addresses from '@/Shares/App/Modules/Contacts/Modals/Partials/Form/Addresses.vue'
import Telephones from '@/Shares/App/Modules/Contacts/Modals/Partials/Form/Telephones.vue'
import Companies from '@/Shares/App/Modules/Contacts/Modals/Partials/Form/Companies.vue'
import axios from 'axios'
import CustomFields from '@/Shares/General/Form/CustomFields.vue'

// Define variable
const modalStore = useModalStore()
const page = usePage();
const currentUser = page.props.auth.user


// Define form
const form = useForm({
    name: '',
    gender: '',
    assignedTo: '',
    tags: '',
    source: '',
    language: '',
    image: '',
    birthdate: '',
    iban: '',
    bic: '',
    description: '',
    website: '',
    addresses: [],
    emails: [],
    telephones: [],
    companies: [],
    customFields: {},
})

let showMoreDetails = ref(false)

// Function to handle form submission
function submit() {

    // Clear empty emails
    form.emails = form.emails.filter(email => {
        if (email.email) return true;
    });

    // Clear empty telephones
    form.telephones = form.telephones.filter(telephone => {
        if (telephone.number) return true;
    });

    // Clear empty addresses
    form.addresses = form.addresses.filter(address => {
        if (address.addressLine1 || address.addressLine2 || address.postalCode || address.city || address.state || address.country) return true;
    });

    // Clear empty companies
    form.companies = form.companies.filter(company => {
        if (company.company) return true;
    });

    form.post(modalStore.props.storeUrl, {
        preserveState: (page) => Object.keys(page.props.errors).length > 0,
        onSuccess: () => modalStore.closeModal(),
    })
}

async function fetchDefaultValues() {

    // Determine if it is necessary to retrieve the default values
    const shouldFetchDefaultValues = !form.assignedTo || !form.tags || !form.source || !form.language || !form.gender || !form.description || !form.description;

    // Fetch default values only if necessary
    if (shouldFetchDefaultValues) {
        await axios.get('/contacts/create/get/default-values').then(response => {
            const data = response.data

            // Only fill in the values if they are not already filled in
            form.assignedTo = form.assignedTo || data.assigned_to
            form.tags = form.tags || data.tags
            form.source = form.source || data.source
            form.language = form.language || data.language
            form.gender = form.gender || data.gender
            form.description = form.description || data.description

        }).catch(error => {
            console.error('Fout bij het ophalen van gegevens', error)
        })
    }

}


// Define on mounted
onMounted(async () => {

    // Check if any form value is predefined
    Object.keys(modalStore.props).forEach(key => {
        if (form.hasOwnProperty(key)) {
            form[key] = modalStore.props[key]
        }
    })

    // Fetch default values
    await fetchDefaultValues()

})


</script>
