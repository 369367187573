<template>
    <Telephone
        v-for="(telephone, index) in telephones"
        :key="index"
        :number="telephone.number"
        :types="telephone.types"
        :errors="getErrors(index)"
        @update:telephone="updateTelephone(index, $event)"
        @delete:telephone="deleteTelephone(index)"
    />

    <AddButton @addTelephone="addTelephone"/>
</template>


<script setup>
import { ref, watch } from 'vue'
import Telephone from '@/Shares/App/Modules/Companies/Modals/Partials/Form/Telephones/Telephone.vue'
import AddButton from '@/Shares/App/Modules/Companies/Modals/Partials/Form/Telephones/AddButton.vue'
import Email from '@/Shares/App/Modules/Companies/Modals/Partials/Form/Emails/Email.vue'


// Define props
const props = defineProps({
    telephones: Array,
    errors: Array,
})


// Define refs
let telephones = ref(props.telephones ?? [])


// Define emits
const emits = defineEmits(['update:telephones'])


// Define watchers
watch(() => props.telephones, (newValue) => {
    telephones.value = newValue
}, { deep: true })

watch(() => telephones.value, (newValue) => {
    emits('update:telephones', newValue)
}, { deep: true })


// Define methods
function addTelephone() {
    telephones.value.push({ 'number': null, 'types': [] })
}

function updateTelephone(index, updatedTelephone) {
    telephones.value[index] = updatedTelephone
}

function deleteTelephone(index) {
    telephones.value.splice(index, 1)
}

function getErrors(index) {
    const errors = {};
    for (const [key, value] of Object.entries(props.errors)) {
        if (key.startsWith(`telephones.${index}`)) {
            const newKey = key.replace(`telephones.${index}.`, '');
            errors[newKey] = value;
        }
    }
    return errors;
}


</script>
