<template>

    <div class="d-flex align-items-center d-lg-none ms-n3 me-1 me-md-2">

        <div class="btn btn-icon btn-active-color-primary w-35px h-35px" id="kt_app_sidebar_mobile_toggle">

            <i class="ki-duotone ki-abstract-14 fs-2 fs-md-1">
                <span class="path1"></span>
                <span class="path2"></span>
            </i>

        </div>

    </div>

    <div class="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
        <Link href="/" class="d-lg-none">
            <img src="/assets/media/logos/favicon.svg" class="h-30px" />
        </Link>
    </div>

</template>
