<template>

    <Modal size="xl" title="Add Quotation">

        <div class="modal-body py-0 px-0">

            <form @submit.prevent="submit" class="form">

                <div class="row g-0">

                    <div class="col-5 border border-top-0 border-bottom-0 border-left-0 border-right-1 border-gray-300 py-4 px-0">

                        <div class="px-6">

                            <div v-if="!modalStore.props.hideCompany || !modalStore.props.hideContact" class="row mb-3">

                                <div class="col-md-12">

                                    <Customer

                                        v-if="
                                            $hasAccessToSubscriptionFeatures(['companies', 'contacts']) &&
                                            $hasPermissions(['companies_index', 'contacts_index'])
                                        "

                                        fetch-url-companies="/quotations/create/get/companies"
                                        fetch-url-contacts="/quotations/create/get/contacts"

                                        :company="form.company"
                                        :contact="form.contact"

                                        @update:customerType="customerType = $event"
                                        @update:customerName="customerName = $event"

                                        @update:company="form.company = $event"
                                        @update:companyIsNew="companyIsNew = $event"

                                        @update:contact="form.contact = $event"
                                        @update:contactIsNew="contactIsNew = $event"

                                    />
                                    <div v-if="form.errors.company" v-text="form.errors.company" class="text-danger small mt-1"/>
                                    <div v-if="form.errors.contact" v-text="form.errors.contact" class="text-danger small mt-1"/>

                                </div>

                            </div>

                            <el-divider v-if="!modalStore.props.hideCompany || !modalStore.props.hideContact" class="my-3"/>

                            <div v-if="!modalStore.props.hideNumber || !modalStore.props.hideName" class="row mb-3">

                                <div v-if="!modalStore.props.hideNumber" class="col-md-6">

                                    <Number :number="form.number" @update:number="form.number = $event"/>
                                    <div v-if="form.errors.number" v-text="form.errors.number" class="text-danger small mt-1"/>

                                </div>

                                <div v-if="!modalStore.props.hideName" class="col-md-6">

                                    <Name :name="form.name" @update:name="form.name = $event"/>
                                    <div v-if="form.errors.name" v-text="form.errors.name" class="text-danger small mt-1"/>

                                </div>

                            </div>

                            <div v-if="!modalStore.props.hideDate || !modalStore.props.hideDueDate" class="row mb-3">

                                <div v-if="!modalStore.props.hideDate" class="col-md-6">

                                    <Date
                                        :date="form.date"
                                        conditionsFetchUrl="/quotations/create/get/conditions"
                                        @update:date="form.date = $event"
                                    />
                                    <div v-if="form.errors.date" v-text="form.errors.date" class="text-danger small mt-1"/>

                                </div>

                                <div v-if="!modalStore.props.hideDueDate" class="col-md-6">

                                    <DueDate
                                        :dueDate="form.dueDate"
                                        :date="form.date"
                                        conditionsFetchUrl="/quotations/create/get/conditions"
                                        :type="form.type"
                                        @update:dueDate="form.dueDate = $event"
                                    />
                                    <div v-if="form.errors.dueDate" v-text="form.errors.dueDate" class="text-danger small mt-1"/>

                                </div>

                            </div>

                            <el-divider class="my-3"/>

                            <div v-if="multipleTypes && (!modalStore.props.hideType || !modalStore.props.hideAssignedTo)" class="row" :class="{ 'mb-3' : form.assignedTo === currentUser.id }">

                                <div v-if="multipleTypes && !modalStore.props.hideType" class="col-md-6">

                                    <Type
                                        fetch-url="/quotations/create/get/types"
                                        :type="form.type"
                                        @update:type="form.type = $event"
                                        @update:multipleTypes="multipleTypes = $event"
                                    />
                                    <div v-if="form.errors.type" v-text="form.errors.type" class="text-danger small mt-1"/>

                                </div>

                                <div v-if="!modalStore.props.hideAssignedTo" class="col-md-6">

                                    <AssignedTo
                                        fetch-url="/quotations/create/get/users"
                                        :assigned-to="form.assignedTo"
                                        @update:assignedTo="form.assignedTo = $event"
                                    />
                                    <div v-if="form.errors.assignedTo" v-text="form.errors.assignedTo" class="text-danger small mt-1"/>

                                </div>

                            </div>

                            <div v-if="form.type && (!modalStore.props.hidePhase || !modalStore.props.hideStatus)" class="row mb-3">

                                <div v-if="!modalStore.props.hidePhase" class="col-md-6">

                                    <Phase
                                        fetch-url="/quotations/create/get/phases"
                                        :phase="form.phase"
                                        :type="form.type"
                                        @update:phase="form.phase = $event"
                                        @update:status="form.status = $event"
                                    />
                                    <div v-if="form.errors.phase" v-text="form.errors.phase" class="text-danger small mt-1"/>

                                </div>

                                <div v-if="!modalStore.props.hideStatus" class="col-md-6">

                                    <Status
                                        fetch-url="/quotations/create/get/statuses"
                                        :status="form.status"
                                        @update:status="form.status = $event"
                                    />
                                    <div v-if="form.errors.status" v-text="form.errors.status" class="text-danger small mt-1"/>

                                </div>

                            </div>

                            <div v-if="form.type && (!modalStore.props.hideDocumentTemplate || !modalStore.props.hideEmailTemplate)" class="row mb-3">

                                <div v-if="!modalStore.props.hideDocumentTemplate" class="col-md-6">

                                    <DocumentTemplate
                                        fetch-url="/quotations/create/get/document-templates"
                                        :type="form.type"
                                        :documentTemplate="form.documentTemplate"
                                        @update:documentTemplate="form.documentTemplate = $event"
                                    />
                                    <div v-if="form.errors.documentTemplate" v-text="form.errors.documentTemplate" class="text-danger small mt-1"/>

                                </div>

                                <div v-if="!modalStore.props.hideEmailTemplate" class="col-md-6">

                                    <EmailTemplate
                                        fetch-url="/quotations/create/get/email-templates"
                                        :type="form.type"
                                        :emailTemplate="form.emailTemplate"
                                        @update:emailTemplate="form.emailTemplate = $event"
                                    />
                                    <div v-if="form.errors.emailTemplate" v-text="form.errors.emailTemplate" class="text-danger small mt-1"/>

                                </div>

                            </div>

                            <div v-if="!modalStore.props.hideDeal && !modalStore.props.hideProject" class="row mb-3">

                                <div v-if="$hasAccessToSubscriptionFeature('deals') && $hasPermission('deals_index') && !modalStore.props.hideDeal" class="col-md-6">

                                    <Deal

                                        fetch-url="/quotations/create/get/deals"

                                        :company="form.company"
                                        :companyIsNew="companyIsNew"

                                        :contact="form.contact"
                                        :contactIsNew="contactIsNew"

                                        :project="form.project"
                                        :projectIsNew="projectIsNew"

                                        :deal="form.deal"

                                        @update:deal="form.deal = $event"
                                        @update:dealIsNew="dealIsNew = $event"

                                        @update:company="form.company = $event"
                                        @update:contact="form.contact = $event"

                                    />
                                    <div v-if="form.errors.deal" v-text="form.errors.deal" class="text-danger small mt-1"/>

                                </div>

                                <div v-if="$hasAccessToSubscriptionFeature('projects') && $hasPermission('projects_index') && !modalStore.props.hideProject" class="col-md-6">

                                    <Project

                                        v-if="$hasAccessToSubscriptionFeature('projects') && $hasPermission('projects_index')"

                                        fetch-url="/quotations/create/get/projects"

                                        :company="form.company"
                                        :companyIsNew="companyIsNew"

                                        :contact="form.contact"
                                        :contactIsNew="contactIsNew"

                                        :deal="form.deal"
                                        :dealIsNew="dealIsNew"

                                        :project="form.project"

                                        @update:project="form.project = $event"
                                        @update:projectIsNew="projectIsNew = $event"

                                        @update:company="form.company = $event"
                                        @update:contact="form.contact = $event"
                                        @update:deal="form.deal = $event"
                                    />
                                    <div v-if="form.errors.project" v-text="form.errors.project" class="text-danger small mt-1"/>

                                </div>

                            </div>

                            <div v-if="form.type && !modalStore.props.hideTags" class="row mb-3" >

                                <div class="col-md-6">

                                    <Tags
                                        fetch-url="/quotations/create/get/tags"
                                        :type="form.type"
                                        :tags="form.tags"
                                        @update:tags="form.tags = $event"
                                    />
                                    <div v-if="form.errors.tags" v-text="form.errors.tags" class="text-danger small mt-1"/>

                                </div>

                            </div>

                            <div v-if="!modalStore.props.hideDescription" class="row mb-3">

                                <div class="col-md-12">

                                    <Description :description="form.description" @update:description="form.description = $event"/>
                                    <div v-if="form.errors.description" v-text="form.errors.description" class="text-danger small mt-1"/>

                                </div>

                            </div>

                            <CustomFields
                                v-if="form.type"
                                :fetchUrl="`/quotations/create/get/${form.type}/custom-fields`"
                                :customFields="form.customFields"
                                :errors="form.errors"
                                @update:customFields="form.customFields = $event"
                            />

                        </div>

                        <el-divider class="mb-4"></el-divider>

                        <div class="d-flex justify-content-end px-8 py-0">

                            <button
                                type="submit"
                                class="btn btn-sm btn-primary"
                                :disabled="form.processing"
                                :data-kt-indicator="form.processing ? 'on' : 'off'"
                            >

                                <span class="indicator-label">
                                    <i class="ki-duotone ki-plus fs-6 text-white me-1"></i> Create quotation
                                </span>

                                <span class="indicator-progress">
                                    Please wait... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>

                            </button>

                        </div>

                    </div>

                    <div class="col-7 pt-2 pb-0 px-6">

                        <div class="d-flex flex-column h-100">

                            <LinesTable
                                v-if="!modalStore.props.hideLines"
                                :lines="form.lines"
                                :discounts="form.discounts"
                                fetchLineProductsUrl="/quotations/create/get/products"
                                @update:lines="form.lines = $event"
                                @update:discounts="form.discounts = $event"
                            />

                        </div>

                    </div>

                </div>

            </form>

        </div>

    </Modal>

</template>

<script setup>
import Modal from '@/Shares/General/Modal.vue'
import { useForm, usePage } from '@inertiajs/vue3'
import { onMounted, ref, watch } from 'vue'
import Name from '@/Shares/App/Modules/Quotations/Modals/Partials/Form/Name.vue'
import Description from '@/Shares/App/Modules/Quotations/Modals/Partials/Form/Description.vue'
import Customer from '@/Shares/General/Form/Customer.vue'
import AssignedTo from '@/Shares/General/Form/AssignedTo.vue'
import Deal from '@/Shares/General/Form/Deal.vue'
import Project from '@/Shares/General/Form/Project.vue'
import Date from '@/Shares/App/Modules/Quotations/Modals/Partials/Form/Date.vue'
import DueDate from '@/Shares/App/Modules/Quotations/Modals/Partials/Form/DueDate.vue'
import Number from '@/Shares/App/Modules/Quotations/Modals/Partials/Form/Number.vue'
import Type from '@/Shares/App/Modules/Quotations/Modals/Partials/Form/Type.vue'
import DocumentTemplate from '@/Shares/App/Modules/Quotations/Modals/Partials/Form/DocumentTemplate.vue'
import Tags from '@/Shares/General/Form/Tags.vue'
import Phase from '@/Shares/App/Modules/Quotations/Modals/Partials/Form/Phase.vue'
import Status from '@/Shares/App/Modules/Quotations/Modals/Partials/Form/Status.vue'
import { useModalStore } from '@/Stores/modalStore.js'
import LinesTable from '@/Shares/General/Form/LinesTable.vue'
import EmailTemplate from '@/Shares/App/Modules/Quotations/Modals/Partials/Form/EmailTemplate.vue'
import axios from 'axios'
import CustomFields from '@/Shares/General/Form/CustomFields.vue'
import moment from 'moment/moment.js'


// Define variables
const modalStore = useModalStore()
const page = usePage();
const currentUser = page.props.auth.user


// Define form
const form = useForm({
    type: '',
    number: '',
    date: moment().format('YYYY-MM-DD'),
    dueDate: '',
    name: '',
    company: '',
    contact: '',
    deal: '',
    project: '',
    tags: '',
    documentTemplate: '',
    emailTemplate: '',
    description: '',
    assignedTo: '',
    status: '',
    phase: '',
    lines: [],
    discounts: [],
    customFields: {},
})


// Define refs
let multipleTypes = ref(true)

let customerType = ref('')
let customerName = ref('')

let companyIsNew = ref(false);
let contactIsNew = ref(false);
let dealIsNew = ref(false);
let projectIsNew = ref(false);


// Define watchers
watch(() => customerName.value, () => {
    if (!form.name && customerName.value) {
        form.name = 'Quotation ' + customerName.value
    }
})


watch(() => form.type, () => {
    fetchDefaultValues()
})


// Define methods
function submit() {
    form.post(modalStore.props.storeUrl, {
        preserveState: (page) => Object.keys(page.props.errors).length > 0,
        onSuccess: () => modalStore.closeModal(),
    })
}

async function fetchDefaultValues() {

    // Check if type is present
    if (!form.type){
        return;
    }

    // Determine if it is necessary to retrieve the default values
    const shouldFetchDefaultValues = !form.name || !form.phase || !form.status || !form.documentTemplate || !form.emailTemplate || !form.assignedTo || !form.tags || !form.description;

    // Fetch default values only if necessary
    if (shouldFetchDefaultValues) {
        await axios.get(`/quotations/create/get/${form.type}/default-values`).then(response => {
            const data = response.data

            // Only fill in the values if they are not already filled in
            form.name = form.name || data.name;
            form.phase = form.phase || data.phase;
            form.status = form.status || data.status;
            form.documentTemplate = form.documentTemplate || data.document_template;
            form.emailTemplate = form.emailTemplate || data.email_template;
            form.assignedTo = form.assignedTo || data.assigned_to;
            form.tags = form.tags || data.tags;
            form.description = form.description || data.description;

        }).catch(error => {
            console.error('Fout bij het ophalen van gegevens', error)
        })
    }

}


// Define on mounted
onMounted(async () => {

    // Check if any form value is predefined
    Object.keys(modalStore.props).forEach(key => {
        if (form.hasOwnProperty(key)) {
            form[key] = modalStore.props[key]
        }
    })

    if (modalStore.props.customerName) {
        customerName.value = modalStore.props.customerName
    }

    if (modalStore.props.customerType) {
        customerType.value = modalStore.props.customerType
    }

    // Add line
    if(form.lines.length === 0) {
        form.lines = [{ 'product': null }]
    }

    // Fetch default values
    await fetchDefaultValues()

})

</script>
