<template>

    <label class="form-label fs-7">Length</label>

    <input
        v-model="length"
        type="text"
        class="form-control form-control-sm"
        placeholder="Enter length..."
    />

</template>
<script setup>
import { ref, watch } from 'vue'

// Define props
const props = defineProps({
    length: String,
})


// Define emits
const emits = defineEmits(['update:length'])


// Define refs
let length = ref(props.length || '')


// Update the local length when the prop changes
watch(() => props.length, (newLength) => {
    length.value = newLength
})


// Watch for changes and emit them
watch(() => length.value, (newLength) => {
    emits('update:length', newLength)
})
</script>
