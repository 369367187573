<template>

    <label class="form-label fs-7 required">Date</label>

    <el-date-picker
        v-model="date"
        type="date"
        placeholder="Select a date"
        class="me-2 w-100 hide-icon"
        format="DD MMM. YYYY"
        value-format="YYYY-MM-DD"
        :clearable="false"
    />

</template>

<script setup>
import {ref, watch} from 'vue'

// Define props and emits
const props = defineProps({
    date: String,
})

const emits = defineEmits(['update:date'])

let date = ref(props.date);

// Update the local date when the prop changes
watch(() => props.date, (newDate) => {
    date.value = newDate;
});

// Watch for changes in the local date and emit an event
watch(date, (newDate) => {
    if (newDate !== props.date) {
        emits('update:date', newDate);
    }
});
</script>
