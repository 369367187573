<template>

    <label class="form-label fs-7">Priority</label>

    <el-select
        v-model="priority"
        filterable
        placeholder="Select priority..."
    >
        <el-option
            v-for="option in options"
            :key="option.value"
            :label="option.label"
            :value="option.value"
        >{{ option.label }}</el-option>
    </el-select>

</template>
<script setup>
import {ref, watch, onMounted} from 'vue'

// Define props
const props = defineProps({
    fetchUrl: String,
    priority: String,
})


// Define emits
const emits = defineEmits(['update:priority'])


// Define refs
const priority = ref(props.priority || '')
const options = ref([])


// Define watchers
watch(() => props.priority, (newPriority) => {
    priority.value = newPriority
})
watch(() => priority.value, (newPriority) => {
    emits('update:priority', newPriority)
})


// Define on mounts
onMounted(async () => {
    if (props.fetchUrl) {
        try {
            const response = await fetch(props.fetchUrl)
            if (response.ok) {
                const data = await response.json()
                options.value = data.map(item => ({
                    value: item.id,
                    label: item.name,
                }))
            } else {
                console.error('Fetch failed:', response.statusText)
            }
        } catch (error) {
            console.error('Fetch error:', error)
        }
    }
})

</script>
