<template>

    <label class="form-label fs-7">Location</label>

    <el-select
        v-model="location"
        filterable
        remote
        reserve-keyword
        clearable
        placeholder="Search a for a location..."
        :remote-method="remoteMethod"
        :loading="loading"
        @change="updateLocation"
    >
        <el-option-group
            v-for="(groupOptions, group) in options"
            :key="group"
            :label="group"
        >
            <el-option
                v-for="item in groupOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                class="d-flex align-items-center"
            >
                <div class="me-2 d-flex align-items-center">
                    <i :class="item.icon">
                        <span v-for="n in item.paths" :key="n" :class="'path' + n"></span>
                    </i>
                </div>
                {{ item.label }}
            </el-option>
        </el-option-group>
    </el-select>

</template>

<script setup>
import { onMounted, ref, watch } from 'vue'
import axios from 'axios'

// Define props
const props = defineProps({
    location: String,
    locationType: String,
    company: String,
    contact: String,
    fetchUrl: String
})

// Define refs
const location = ref(props.location || '')
const locationType = ref(props.locationType || '')
const options = ref([])
const loading = ref(false)

// Define emits
const emits = defineEmits(['update:location', 'update:locationType'])

// Define watch
watch(() => props.location, async (value) => {
    if (value) {
        loading.value = true
        options.value = await fetchData(`location=${value}`)
        location.value = props.location
        loading.value = false
    } else {
        location.value = value
    }
})

// Define methods
const remoteMethod = async (search) => {
    if (search) {
        loading.value = true
        const query = 'search=' + search
        options.value = await fetchData(query)
        loading.value = false
    } else {
        options.value = []
    }
}

const fetchData = async (query) => {
    try {
        if (props.company) {
            query += '&company=' + props.company
        }
        if (props.contact) {
            query += '&contact=' + props.contact
        }

        const response = await axios.get(`${props.fetchUrl}?${query}`)
        const data = response.data

        let locations = {}
        Object.keys(data).forEach((group) => {
            locations[group] = data[group].map(item => ({
                type: item.type,
                value: item.id ?? item.value,
                label: item.name ?? item.value,
                icon: getIconClass(item.type),
                paths: getIconPaths(item.type),
            }))
        })

        return locations
    } catch (error) {
        console.error('An error occurred:', error)
        throw new Error('Failed to fetch data')
    }
}

function updateLocation(value) {
    emits('update:location', value)

    let foundSelectedOption = false
    for (const groupOptions of Object.values(options.value)) {
        const selectedOption = groupOptions.find(option => option.value === value)
        if (selectedOption) {
            locationType.value = selectedOption.type
            emits('update:locationType', selectedOption.type)
            foundSelectedOption = true
            break
        }
    }
}

const getIconClass = (type) => {

    // Company
    if (type === 'company') return 'ki-duotone ki-bank fs-2';

    // Contact
    if (type === 'contact') return 'ki-duotone ki-address-book fs-2';

    // Custom address
    if (type === 'custom_address') return 'ki-duotone ki-map fs-2';

    // Meeting locations
    if (type === 'meeting_locations') return 'ki-duotone ki-geolocation fs-2';

    return '';

}

const getIconPaths = (type) => {

    // Company
    if (type === 'company') return 2;

    // Contact
    if (type === 'contact') return 3;

    // Custom address
    if (type === 'custom_address') return 3;

    // Meeting locations
    if (type === 'meeting_locations') return 2;

    return 0;

}

// Define on mounted
onMounted(async () => {
    if (props.location) {
        loading.value = true
        options.value = await fetchData(`location=${props.location}`)
        location.value = props.location
        loading.value = false
    }
})
</script>
