<template>

    <label class="form-label fs-7">Iban</label>

    <input
        v-model="iban"
        type="text"
        class="form-control form-control-sm"
        placeholder="Enter iban..."
    />

</template>

<script setup>
import {ref, watch} from 'vue'

// Define props and emits
const props = defineProps({
    iban: String,
})

const emits = defineEmits(['update:iban'])

let iban = ref(props.iban);

// Update the local iban when the prop changes
watch(() => props.iban, (newIban) => {
    iban.value = newIban;
});

// Watch for changes in the local iban and emit an event
watch(iban, (newIban) => {
    if (newIban !== props.iban) {
        emits('update:iban', newIban);
    }
});
</script>
