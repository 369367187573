<template>

    <div id="kt_app_header" class="app-header" data-kt-sticky="true" data-kt-sticky-activate="{default: true, lg: true}" data-kt-sticky-name="app-header-minimize" data-kt-sticky-offset="{default: '200px', lg: '0'}" data-kt-sticky-animation="false" style="background-color: var(--bs-app-header-minimize-bg-color);box-shadow: var(--bs-app-header-minimize-box-shadow);border-bottom: var(--bs-app-header-minimize-border-bottom);">

        <div class="app-container container-fluid d-flex align-items-stretch justify-content-between" id="kt_app_header_container">

            <SidebarMobileToggle />

            <div class="d-flex align-items-stretch justify-content-between flex-lg-grow-1" id="kt_app_header_wrapper">

                <div class="app-header-menu app-header-mobile-drawer align-items-stretch" data-kt-drawer="true" data-kt-drawer-name="app-header-menu" data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-overlay="true" data-kt-drawer-width="250px" data-kt-drawer-direction="end" data-kt-drawer-toggle="#kt_app_header_menu_toggle" data-kt-swapper="true" data-kt-swapper-mode="{default: 'append', lg: 'prepend'}" data-kt-swapper-parent="{default: '#kt_app_body', lg: '#kt_app_header_wrapper'}">

                    <Menu @menuItemsAreVisible="menuItemsAreVisible = $event" />

                </div>

                <Navbar :show-mobile-menu-toggle="menuItemsAreVisible" />

            </div>

        </div>

    </div>

</template>

<script setup>
import SidebarMobileToggle from "@/Layouts/App/Layout/Header/SidebarMobileToggle.vue";
import Navbar from "@/Layouts/App/Layout/Header/Navbar.vue";
import Menu from "@/Layouts/App/Layout/Header/Menu.vue";
import { ref } from 'vue'

// Define refs
const menuItemsAreVisible = ref(false)

</script>

