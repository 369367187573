<template>

    <Link
        :href="`/invoices/${props.id}`"
        class="d-flex align-items-center justify-content-between bg-hover-gray-100 py-2 text-hover-primary text-gray-800"
    >

        <div class="d-flex align-items-center">

            <div class="symbol symbol-40px me-4">
                <span class="symbol-label bg-light">
                    <Icon type="invoice" class="fs-2x ms-3 me-2"/>
                </span>
            </div>


            <div class="d-flex flex-column">

                <span class="fs-6 fw-semibold">{{ props.name }} ({{ props.number }}) </span>

                <div class="d-flex" v-if="subText">
                    <span class="fs-7 text-muted fw-semibold" v-text="subText"/>
                </div>

            </div>

        </div>

        <span class="badge me-2" :class="statusClass" v-text="props.status" />

    </Link>

    <el-divider border-style="dashed" class="my-2"/>

</template>

<script setup>
import Icon from '@/Shares/General/Icon.vue'
import { computed } from 'vue'

// Define props
const props = defineProps({
    id: String,
    name: String,
    number: String,
    type: String,
    status: String,
    amount: String,
    company: Object,
    contact: Object,
})

// Define computed
const subText = computed(() => {
    let parts = []

    if (props.type) {
        parts.push(`${props.type} -`)
    }

    if (props.amount) {
        parts.push(`${proxy.$filters.formatCurrency(props.amount, 'EUR')} -`)
    }

    if (props.company && props.contact) {
        parts.push(`${props.company.name}, ${props.contact.name}`)
    } else if (props.company) {
        parts.push(props.company.name)
    } else if (props.contact) {
        parts.push(props.contact.name)
    }

    return parts.join(' ')
})

const statusClass = computed(() => {
    switch (props.status) {
        case 'draft':
            return 'badge-light'
        case 'outstanding':
            return 'badge-warning'
        case 'paid':
            return 'badge-success'
        case 'expired':
            return 'badge-danger'
    }
})

</script>
