<template>
    <div class="mb-6">

        <div class="row g-1 mb-1">

            <div class="col-6 d-flex align-items-center">

                <el-select
                    v-model="contact"
                    ref="contactSelectRef"
                    filterable
                    clearable
                    remote
                    :reserve-keyword="false"
                    default-first-option
                    placeholder="Search for contact..."
                    :remote-method="remoteMethod"
                    :loading="loading"
                    loading-text="Loading contacts..."
                    no-data-text="No contacts found."
                    @focus="updateBadge"
                    @blur="updateBadge"
                    @visible-change="updateBadge"
                    @clear="updateBadge"
                >

                    <el-option
                        v-for="item in contactOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    >
                        {{ item.label }} <span v-if="item.isNew" class="new-badge ms-2 badge badge-sm badge-primary">New</span>
                    </el-option>

                    <template #footer v-if="searchText" class="p-0">

                        <a class="btn btn-sm text-dark text-hover-primary m-0 p-0 d-flex align-items-center" @click="addAndSelectSearchText">
                            <i class="ki-solid ki-plus me-0 text-dark"/>
                            Add <span class="fw-bolder mx-1">'{{ searchText }}'</span> as new contact
                        </a>

                    </template>

                </el-select>

            </div>

            <div class="col-5 d-flex align-items-center">
                <input v-model="position" type="text" class="form-control form-control-sm w-100" placeholder="Position..."/>
            </div>

            <div class="col-1 d-flex align-items-center justify-content-center">
                <button type="button" class="btn btn-icon btn-sm btn-light-danger" @click.prevent="deleteContact">
                    <Icon type="delete" class="fs-3"/>
                </button>
            </div>

        </div>

        <div class="row g-1 justify-content-end">

            <div class="col-5 d-flex align-items-center">
                <el-checkbox v-model="primary" label="Primary" border class="w-100"/>

            </div>

            <div class="col-5 d-flex align-items-center">
                <el-checkbox v-model="decisionMaker" label="Decision maker" border class="w-100"/>
            </div>

        </div>

        <div v-if="props.errors" v-for="error in props.errors" v-text="error" class="text-danger small mt-1"/>

    </div>
</template>

<script setup>

// Define props
import { computed, nextTick, onMounted, ref, watch } from 'vue'
import Icon from '@/Shares/General/Icon.vue'

const props = defineProps({
    contact: String,
    position: String,
    primary: Boolean,
    decisionMaker: Boolean,
    fetchUrl: String,
    errors: Array
})


// Define refs
const contact = ref(props.contact ?? '')
const position = ref(props.position ?? '')
const primary = ref(props.primary ?? false)
const decisionMaker = ref(props.decisionMaker ?? false)

const searchText = ref('')
const dataFetched = ref(false)
const loading = ref(false)
const contactSelectRef = ref()
const contactOptions = ref([])


// Define emits
const emits = defineEmits(['update:contact', 'delete:contact'])


// Define watchers
watch(() => props.contact, (value) => {
    contact.value = value
})
watch(() => props.position, (value) => {
    position.value = value
})
watch(() => props.primary, (value) => {
    primary.value = value
})
watch(() => props.decisionMaker, (value) => {
    decisionMaker.value = value
})
watch(() => [contact.value, position.value, primary.value, decisionMaker.value], () => {
    emits('update:contact', {
        'contact': contact.value,
        'position': position.value,
        'primary': primary.value,
        'decisionMaker': decisionMaker.value
    })
})


// Define methods
function deleteContact() {
    emits('delete:contact')
}

const fetchContacts = async (query) => {
    try {
        const response = await fetch(`${props.fetchUrl}?${query}`)
        if (!response.ok) {
            throw new Error('Network response was not ok')
        }
        const data = await response.json()
        dataFetched.value = true

        // Map contacts
        let contacts = [];
        if (Array.isArray(data)){
            contacts = data.map(item => ({
                value: item.id,
                label: item.name,
                isNew: false,
            }))
        } else {
            contacts = [{
                value: data.id,
                label: data.name,
                isNew: false,
            }]
        }

        return contacts
    } catch (error) {
        console.error('Error fetching contacts:', error)
        return []
    }
}

const remoteMethod = async (search) => {
    searchText.value = search
    if (search) {
        loading.value = true
        const query = 'search=' + search
        contactOptions.value = await fetchContacts(query)
        loading.value = false
    }
}

const addAndSelectSearchText = () => {
    const newContact = searchText.value
    contact.value = newContact
    nextTick(() => {
        contactSelectRef.value.blur()
    })

    // Set isNew property to true for the new contact
    contactOptions.value.push({
        value: newContact,
        label: newContact,
        isNew: true,
    })

    updateBadge()
}

const updateBadge = () => {
    nextTick(() => {

        // Get placeholder
        const placeholder = contactSelectRef.value.$el.querySelector('.el-select__placeholder')
        if (!placeholder) return

        // Check if current contact is new
        const selectedOption = contactOptions.value.find(opt => opt.value === contact.value)
        if (contact.value && selectedOption && selectedOption.isNew) {

            // Add classes
            placeholder.classList.add('d-flex', 'align-items-center', 'justify-content-between')

            // Check if badge exist
            const existingBadge = placeholder.querySelector('.new-badge')
            if (existingBadge) {
                return
            }

            // Add new badge
            const newBadge = document.createElement('span')
            newBadge.className = 'new-badge ms-2 badge badge-sm badge-primary'
            newBadge.textContent = 'New'
            placeholder.appendChild(newBadge)

            return
        }

        // Remove existing badge
        const existingBadge = placeholder.querySelector('.new-badge')
        if (existingBadge) {
            placeholder.classList.remove('d-flex', 'align-items-center', 'justify-content-between')
            existingBadge.remove()
        }

    })
}


// Define on mounted
onMounted(async () => {
    if (props.contact) {
        loading.value = true
        const query = `contact=${props.contact}`
        contactOptions.value = await fetchContacts(query)
        contact.value = props.contact
        loading.value = false
    }
})

</script>
