<template>

    <label class="form-label fs-7">Image</label>

    <el-upload
        v-model:file-list="fileList"
        ref="upload"
        :auto-upload="false"
        :limit="1"
        :on-exceed="handleExceed"
        @change="onChange"
        @remove="onChange"
        :show-file-list="false"
        class="avatar-uploader"
    >
        <img v-if="imageUrl" :src="imageUrl" class="avatar"/>
        <el-icon v-else class="avatar-uploader-icon">
            <Plus/>
        </el-icon>
    </el-upload>

</template>

<script setup>
import { isProxy, ref, toRaw, watch, onMounted } from 'vue'
import { genFileId } from 'element-plus'
import { Plus } from '@element-plus/icons-vue'

// Define props
const props = defineProps({
    image: Object,
})

// Define refs
let fileList = ref([])
let upload = ref()
const imageUrl = ref('')


// Define emits
const emit = defineEmits(['update:image'])


// Define watchers
watch(() => props.image, (value) => {
    if (!value){
        return;
    }
    fileList.value = [value]
    if (props.image.url) {
        imageUrl.value = props.image.url
    }
})


// Define methods
function onChange(event, files) {

    if (files.length > 0) {
        let firstObject = files[0]
        if (isProxy(firstObject)) {
            firstObject = toRaw(firstObject)
        }
        imageUrl.value = URL.createObjectURL(firstObject.raw)
        emit('update:image', firstObject)
    } else {
        imageUrl.value = ''
        emit('update:image', '')
    }

}

const handleExceed = (files) => {
    upload.value.clearFiles()
    const file = files[0]
    file.uid = genFileId()
    upload.value.handleStart(file)
}

// Define on mounted
onMounted(() => {
    if(props.image){
        fileList.value = [props.image]
        if (props.image.url) {
            imageUrl.value = props.image.url
        }
    }
})
</script>

<style scoped>
.avatar-uploader .avatar {
    width: 125px;
    height: 125px;
    display: block;
}
</style>

<style>
.avatar-uploader .el-upload {
    border: 1px dashed var(--el-border-color);
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
    border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    text-align: center;
}
</style>
