<template>

    <label class="form-label fs-7 required">Status</label>

    <el-select
        v-model="status"
        filterable
        reserve-keyword
        placeholder="Select status..."
        @change="updateStatus"
    >
        <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
        >{{ item.label }}
        </el-option>
    </el-select>

</template>

<script setup>
import { ref, watch, onMounted } from 'vue'
import axios from 'axios'

// Define props and emits
const props = defineProps({
    fetchUrl: String,
    status: String,
})


// Define refs
const options = ref([])
const status = ref(props.status || '')


// Define emits
const emits = defineEmits(['update:status'])


// Define watchers
watch(() => props.status, (newStatus) => {
    status.value = newStatus
})
watch(() => props.fetchUrl, () => {
    fetchData()
})


// Define methods
async function fetchData() {

    if (props.fetchUrl) {

        axios.get(props.fetchUrl).then(response => {
            const data = response.data

            options.value = data.map(item => ({
                value: item.value,
                label: item.label,
            }))

        }).catch(error => {
            console.error('Fout bij het ophalen van gegevens', error)
        })

    }
}

const updateStatus = () => {
    emits('update:status', status.value)
}


// Define on mounted
onMounted(async () => {
    await fetchData()
})
</script>
