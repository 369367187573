<template>

    <div class="mb-2">

        <div class="row g-1">

            <div class="col-7 d-flex align-items-center">
                <input v-model="email" type="email" class="form-control form-control-sm w-100" placeholder="Email..."/>
            </div>

            <div class="col-4 d-flex align-items-center">
                <el-select
                    v-model="types"
                    multiple
                    reserve-keyword
                    collapse-tags
                    collapse-tags-tooltip
                    clearable
                    placeholder="Select types..."
                >
                    <el-option label="primary" value="primary"/>
                    <el-option label="invoicing" value="invoicing"/>
                    <el-option label="marketing" value="marketing"/>
                </el-select>
            </div>

            <div class="col-1 d-flex align-items-center justify-content-center">
                <button type="button" class="btn btn-icon btn-sm btn-light-danger" @click.prevent="deleteEmail">
                    <Icon type="delete" class="fs-3"/>
                </button>
            </div>

        </div>

        <div v-if="props.errors" v-for="error in props.errors" v-text="error" class="text-danger small mt-1"/>

    </div>

</template>

<script setup>

// Define props
import { ref, watch } from 'vue'
import Icon from '@/Shares/General/Icon.vue'

const props = defineProps({
    email: String,
    types: Array,
    errors: Array
})


// Define refs
const email = ref(props.email ?? '')
const types = ref(props.types ?? [])


// Define emits
const emits = defineEmits(['update:email', 'delete:email'])


// Define watchers
watch(() => props.email, (value) => {
    email.value = value
})
watch(() => props.types, (value) => {
    types.value = value
})
watch(() => [email.value, types.value], () => {
    emits('update:email', { 'email': email.value, 'types': types.value })
})


// Define methods
function deleteEmail() {
    emits('delete:email')
}

</script>
