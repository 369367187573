<template>

    <div class="row">

        <div v-if="!customerType" class="col-md-12">

            <label class="form-label fs-7" :class="{ 'required': props.required }">Customer</label>

            <CustomerSelect
                :fetchUrlCompanies="props.fetchUrlCompanies"
                :fetchUrlContacts="props.fetchUrlContacts"
                @update:customer="updateCustomerSelect"
            />

        </div>

        <div v-if="!modalStore.props.hideCompany && customerType === 'company'" class="col-md-6">

            <label class="form-label fs-7" :class="{ 'required': props.required }">Company</label>

            <CompanySelect
                :companyIsNew="companyIsNew"
                :company="company"
                :contactIsNew="contactIsNew"
                :contact="contact"
                :fetchUrl="props.fetchUrlCompanies"
                @update:company="company = $event"
                @update:companyName="companyName = $event"
                @update:companyIsNew="companyIsNew = $event"
            />

        </div>

        <div v-if="!modalStore.props.hideContact && customerType === 'contact' || customerType === 'company'"  class="col-md-6">

            <label class="form-label fs-7" :class="{ 'required': props.required && customerType === 'contact' }">Contact</label>

            <ContactSelect
                :contactIsNew="contactIsNew"
                :contact="contact"
                :companyIsNew="companyIsNew"
                :company="company"
                :fetchUrl="props.fetchUrlContacts"
                @update:contact="contact = $event"
                @update:contactName="contactName = $event"
                @update:contactIsNew="contactIsNew = $event"
            />

        </div>

        <div v-if="!modalStore.props.hideCompany && customerType === 'contact'" class="col-md-6">

            <label class="form-label fs-7">Company</label>

            <CompanySelect
                :companyIsNew="companyIsNew"
                :company="company"
                :contactIsNew="contactIsNew"
                :contact="contact"
                :fetchUrl="props.fetchUrlCompanies"
                @update:company="company = $event"
                @update:companyName="companyName = $event"
                @update:companyIsNew="companyIsNew = $event"
            />

        </div>

    </div>

</template>

<script setup>
import { ref, watch, onMounted } from 'vue'
import { useModalStore } from '@/Stores/modalStore.js'
import CustomerSelect from '@/Shares/General/Form/Customer/CustomerSelect.vue'
import CompanySelect from '@/Shares/General/Form/Customer/CompanySelect.vue'
import ContactSelect from '@/Shares/General/Form/Customer/ContactSelect.vue'

// Define variables
const modalStore = useModalStore()

// Define props
const props = defineProps({
    company: String,
    contact: String,
    fetchUrlCompanies: String,
    fetchUrlContacts: String,
    required: { type: Boolean, default: false },
})

// Define refs
const customer = ref('')
const customerType = ref('')
const customerName = ref('')
const customerIsNew = ref(false)

const company = ref('')
const companyName = ref('')
const companyIsNew = ref(false)

const contact = ref('')
const contactName = ref('')
const contactIsNew = ref(false)


// Define emits
const emit = defineEmits([

    'update:customerType',
    'update:customerName',

    'update:company',
    'update:companyIsNew',

    'update:contact',
    'update:contactIsNew',

])


// Define watchers
watch([company, companyName, companyIsNew], (value) => {

    // Check if company empty
    if (!company.value && customerType.value === 'company'){

        // Check if contact is not empty
        if (contact.value){

            // Update customer type
            customerType.value = 'contact'

            // Update customer name
            customerName.value = contactName.value

        } else {

            // Update customer type
            customerType.value = null

            // Update customer name
            customerName.value = null

        }

        return;
    }

    // Check if customer type is company
    if (company.value && customerType.value === 'company'){

        // Update customer name
        customerName.value = companyName.value

        return;

    }

    // Check if customer type is contact
    if (customerType.value === 'contact' && company.value){

        // Update customer type
        customerType.value = 'company'

        // Update customer name
        customerName.value = companyName.value

    }

})

watch([contact, contactName, contactIsNew], (value) => {

    // Check if contact empty
    if (!contact.value && customerType.value === 'contact'){

        // Update customer name
        customerName.value = contactName.value;

        // Check if company is not empty
        if (company.value){

            // Update customer type
            customerType.value = 'company'

        } else {

            // Update customer type
            customerType.value = null

        }

    }

})

watch([customerType, customerName, company, companyIsNew, contact, contactIsNew], (value) => {

    // Emit changes
    emit('update:customerType', customerType.value)
    emit('update:customerName', customerName.value)

    emit('update:company', company.value)
    emit('update:companyIsNew', companyIsNew.value)

    emit('update:contact', contact.value)
    emit('update:contactIsNew', contactIsNew.value)

})


// Define methods
const updateCustomerSelect = (value) => {

    // Update customer
    customer.value = value.customer

    // Update customer type
    customerType.value = value.customerType

    // Update customer name
    customerName.value = value.customerName

    // Update customer is new
    customerIsNew.value = value.customerIsNew

    // Check customer type is company
    if (customerType.value === 'company'){

        // Update company refs
        company.value = customer.value
        companyIsNew.value = customerIsNew.value

        return;
    }


    // Check customer type is contact
    if (customerType.value === 'contact'){

        // Update contact refs
        contact.value = customer.value
        contactIsNew.value = customerIsNew.value

    }

}


// Define watchers
watch(() => [props.company, props.contact], (value) => {

    // Update refs
    company.value = props.company
    contact.value = props.contact

    // Check if value
    if (props.company){

        // Update customer type refs
        customerType.value = 'company'

        return;
    }

    // Check if contact still has value
    if (props.contact){

        // Update customer type refs
        customerType.value = 'contact'

        return;
    }

    // Update customer type refs
    customerType.value = null

})


// Define on mounted
onMounted(() => {

    // Check if props are present
    if (props.company || props.contact){

        // Update customer type
        customerType.value = props.company? 'company' : 'contact'

        // Update customer name
        customerName.value = props.company? props.company : props.contact

        // Update company refs
        company.value = props.company

        // Update contact refs
        contact.value = props.contact

    }

})

</script>
