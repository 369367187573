<template>

    <label class="form-label fs-7">Bic</label>

    <input
        v-model="bic"
        type="text"
        class="form-control form-control-sm"
        placeholder="Enter bic..."
    />

</template>

<script setup>
import {ref, watch} from 'vue'

// Define props and emits
const props = defineProps({
    bic: String,
})

const emits = defineEmits(['update:bic'])

let bic = ref(props.bic);

// Update the local bic when the prop changes
watch(() => props.bic, (newBic) => {
    bic.value = newBic;
});

// Watch for changes in the local bic and emit an event
watch(bic, (newBic) => {
    if (newBic !== props.bic) {
        emits('update:bic', newBic);
    }
});
</script>
