<template>

    <label class="form-label fs-7">Website</label>

    <input
        v-model="website"
        type="text"
        class="form-control form-control-sm"
        placeholder="Enter website..."
    />

</template>

<script setup>
import {ref, watch} from 'vue'

// Define props and emits
const props = defineProps({
    website: String,
})

const emits = defineEmits(['update:website'])

let website = ref(props.website ?? '');

// Update the local website when the prop changes
watch(() => props.website, (newWebsite) => {
    website.value = newWebsite;
});

// Watch for changes in the local website and emit an event
watch(website, (newWebsite) => {
    if (newWebsite !== props.website) {
        emits('update:website', newWebsite);
    }
});
</script>
