<template>

    <label class="form-label fs-7">Width</label>

    <input
        v-model="width"
        type="text"
        class="form-control form-control-sm"
        placeholder="Enter width..."
    />
</template>

<script setup>
import { ref, watch } from 'vue'

// Define props
const props = defineProps({
    width: String,
})


// Define emits
const emits = defineEmits(['update:width'])


// Define refs
let width = ref(props.width || '')


// Update the local width when the prop changes
watch(() => props.width, (newWidth) => {
    width.value = newWidth
})


// Watch for changes and emit them
watch(() => width.value, (newWidth) => {
    emits('update:width', newWidth)
})
</script>
