<template>

    <div v-if="conditions.length > 0" class="border-top">

        <ul class="list-group fw-semibold">

            <li
                v-for="(condition, index) in conditions"
                class="list-group-item condition-item cursor-pointer text-hover-primary border-top-0 border-start-0 border-end-0 border-dashed py-1 rounded-0 text-center"
                :class="{ 'border-bottom-0': index === conditions.length - 1 }"
                @click="onItemClick(condition.days_valid)"
            >
                {{ condition.name }} - {{ condition.days_valid }} days
            </li>

        </ul>

    </div>

</template>

<script setup>
import { ref, onMounted } from 'vue'
import axios from 'axios'
import { useModalStore } from '@/Stores/modalStore.js'


// Define variables
const modalStore = useModalStore()


// Define props
const props = defineProps({
    fetchUrl: String,
    type: String,
    onConditionSelected: Function
})


// Define refs
const conditions = ref([])


// Define emits
const emits = defineEmits(['conditionSelected']);


// Define methods
const onItemClick = (daysValid) => {
    if (props.onConditionSelected) {
        props.onConditionSelected(daysValid)
    }
}

// Define on mounted
onMounted(() => {
    if (props.type) {
        axios.get(props.fetchUrl, { params: { type: props.type } }).then(response => {
            conditions.value = response.data
        }).catch(error => {
            console.error('Fout bij het ophalen van gegevens', error)
        })
    }
})

</script>
