<template>

    <label class="form-label fs-7">Gender</label>

    <el-select
        v-model="gender"
        filterable
        reserve-keyword
        clearable
        placeholder="Select gender..."
    >
        <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
        >{{ item.label }}
        </el-option>
    </el-select>

</template>

<script setup>
import { ref, watch, onMounted } from 'vue'

// Define props and emits
const props = defineProps({
    fetchUrl: String,
    gender: String,
})

const emits = defineEmits(['update:gender'])

// Define reactive properties using ref
const gender = ref('')

// Watch for changes in formData and emit them
watch(() => gender.value, (newGender) => {
    emits('update:gender', newGender)
})

watch(() => props.gender, (newValue) => {
    gender.value = newValue;
}, { immediate: true });

/**
 * Remote fetch
 * */
const options = ref([])

// Fetch users data
onMounted(async () => {
    if (props.fetchUrl) {
        try {
            const response = await fetch(props.fetchUrl)
            if (response.ok) {
                const data = await response.json()
                options.value = data.map(item => ({
                    value: item.value,
                    label: item.label,
                }))
            } else {
                console.error('Fetch failed:', response.statusText)
            }
        } catch (error) {
            console.error('Fetch error:', error)
        }
    }
})
</script>
