<template>

    <label class="form-label fs-7">Tags</label>

    <el-select
        v-model="tags"
        filterable
        multiple
        reserve-keyword
        collapse-tags
        collapse-tags-tooltip
        clearable
        placeholder="Select tags..."
    >
        <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
        >{{ item.label }}
        </el-option>
    </el-select>

</template>

<script setup>
import { ref, watch, onMounted } from 'vue'
import axios from 'axios'

// Define props
const props = defineProps({
    fetchUrl: String,
    tags: Array,
    type: String,
})


// Define emits
const emits = defineEmits(['update:tags'])


// Define refs
const tags = ref(props.tags || [])
const options = ref([])


// Define watch
watch(() => props.tags, (value) => {
    tags.value = value
})
watch(() => tags.value, (newTags) => {
    emits('update:tags', newTags)
})

watch(() => [props.type, props.fetchUrl], (value) => {
    fetchData()
})


// Define methods
async function fetchData() {
    if (props.fetchUrl) {
        axios.get(props.fetchUrl, { params: { type: props.type } }).then(response => {
            const data = response.data

            options.value = data.map(item => ({
                value: item.id,
                label: item.name,
            }))

            tags.value = tags.value?.filter(tag =>
                data.some(item => item.id === tag)
            );

        }).catch(error => {
            console.error('Fout bij het ophalen van gegevens', error)
        })
    }
}


// Define on mounted
onMounted(async () => {
    await fetchData()
})
</script>
