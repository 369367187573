<template>

    <el-divider v-if="customFields.length > 0" class="my-3"/>

    <div v-if="customFields.length > 0" class="row g-5 mb-3">

        <div v-for="(field, index) in customFields" :key="field.id" :class="{ 'col-6': field.field_type !== 'textarea', 'col-12':  field.field_type === 'textarea'}">

            <label :for="`field-${index}`" class="form-label fs-7" :class="{ 'required': field.required }">{{ field.name }}</label>

            <div>
                <String
                    v-if="field.field_type === 'string'"
                    :value="values[field.id]"
                    :placeholder="field.placeholder"
                    :required="field.required"
                    @update:value="updateValue(field.id, $event)"
                />

                <Textarea
                    v-if="field.field_type === 'textarea'"
                    :value="values[field.id]"
                    :placeholder="field.placeholder"
                    :required="field.required"
                    @update:value="updateValue(field.id, $event)"
                />

                <Enum
                    v-if="field.field_type === 'enum'"
                    :value="values[field.id]"
                    :placeholder="field.placeholder"
                    :required="field.required"
                    :options="field.options"
                    @update:value="updateValue(field.id, $event)"
                />

                <Set
                    v-if="field.field_type === 'set'"
                    :value="values[field.id]"
                    :placeholder="field.placeholder"
                    :required="field.required"
                    :options="field.options"
                    @update:value="updateValue(field.id, $event)"
                />

                <Date
                    v-if="field.field_type === 'date'"
                    :value="values[field.id]"
                    :placeholder="field.placeholder"
                    :required="field.required"
                    @update:value="updateValue(field.id, $event)"
                />

                <Money
                    v-if="field.field_type === 'money'"
                    :value="values[field.id]"
                    :placeholder="field.placeholder"
                    :required="field.required"
                    @update:value="updateValue(field.id, $event)"
                />

                <Int
                    v-if="field.field_type === 'int'"
                    :value="values[field.id]"
                    :placeholder="field.placeholder"
                    :required="field.required"
                    @update:value="updateValue(field.id, $event)"
                />

                <Number
                    v-if="field.field_type === 'number'"
                    :value="values[field.id]"
                    :placeholder="field.placeholder"
                    :required="field.required"
                    @update:value="updateValue(field.id, $event)"
                />

                <AutoIncrement
                    v-if="field.field_type === 'auto_increment'"
                    :value="values[field.id]"
                    :placeholder="field.placeholder"
                    :required="field.required"
                    @update:value="updateValue(field.id, $event)"
                />

                <Boolean
                    v-if="field.field_type === 'boolean'"
                    :name="field.name"
                    :value="values[field.id]"
                    :placeholder="field.placeholder"
                    :required="field.required"
                    @update:value="updateValue(field.id, $event)"
                />

                <Email
                    v-if="field.field_type === 'email'"
                    :value="values[field.id]"
                    :placeholder="field.placeholder"
                    :required="field.required"
                    @update:value="updateValue(field.id, $event)"
                />

                <Telephone
                    v-if="field.field_type === 'telephone'"
                    :value="values[field.id]"
                    :placeholder="field.placeholder"
                    :required="field.required"
                    @update:value="updateValue(field.id, $event)"
                />

                <Url
                    v-if="field.field_type === 'url'"
                    :value="values[field.id]"
                    :placeholder="field.placeholder"
                    :required="field.required"
                    @update:value="updateValue(field.id, $event)"
                />

            </div>

            <div v-if="props.errors['customFields.' + field.id]" v-text="props.errors['customFields.' + field.id]" class="text-danger small mt-1" />

        </div>

    </div>

</template>

<script setup>
import { onMounted, ref, watch } from 'vue'
import axios from 'axios'
import String from '@/Shares/General/Form/CustomFields/String.vue'
import Textarea from '@/Shares/General/Form/CustomFields/Textarea.vue'
import Enum from '@/Shares/General/Form/CustomFields/Enum.vue'
import Set from '@/Shares/General/Form/CustomFields/Set.vue'
import Date from '@/Shares/General/Form/CustomFields/Date.vue'
import Money from '@/Shares/General/Form/CustomFields/Money.vue'
import Int from '@/Shares/General/Form/CustomFields/Int.vue'
import Number from '@/Shares/General/Form/CustomFields/Number.vue'
import Boolean from '@/Shares/General/Form/CustomFields/Boolean.vue'
import Email from '@/Shares/General/Form/CustomFields/Email.vue'
import Telephone from '@/Shares/General/Form/CustomFields/Telephone.vue'
import Url from '@/Shares/General/Form/CustomFields/Url.vue'
import AutoIncrement from '@/Shares/General/Form/CustomFields/AutoIncrement.vue'
import Project from '@/Shares/General/Form/Project.vue'

// Define props
const props = defineProps({
    fetchUrl: String,
    customFields: Object,
    errors: Object,
})


// Define refs
const customFields = ref([])
const values = ref(props.customFields || {})


// Define emits
const emits = defineEmits(['update:customFields'])


// Define methods
async function fetchCustomFields() {
    if (props.fetchUrl) {
        axios.get(props.fetchUrl).then(response => {
            customFields.value = response.data;
        }).catch(error => {
            console.error('Fout bij het ophalen van gegevens', error)
        })
    }
}

function updateValue(fieldId, newValue) {
    values.value[fieldId] = newValue
    emits('update:customFields', values.value)
}

// Define on mounted
onMounted(async () => {
    await fetchCustomFields()
})
</script>
