<template>

    <label class="form-label fs-7">Stock</label>

    <el-checkbox v-model="manageStock" label="Manage stock" class="w-100 form-control py-5" />

</template>

<script setup>
import { ref, watch } from 'vue'

// Define props
const props = defineProps({
    manageStock: Boolean,
})


// Define emits
const emits = defineEmits(['update:manageStock'])


// Define refs
let manageStock = ref(props.manageStock || '')


// Update the local manageStock when the prop changes
watch(() => props.manageStock, (newBackordersAllowed) => {
    manageStock.value = newBackordersAllowed
})


// Watch for changes and emit them
watch(() => manageStock.value, (newBackordersAllowed) => {
    emits('update:manageStock', newBackordersAllowed)
})
</script>
