<template>

    <div class="card p-0 mb-1 rounded-1 border-gray-300">

        <div class="card-body px-4 py-2 d-flex d-flex">

            <div v-if="!product" class="w-35px"></div>

            <div
                v-else-if="product"
                class="w-35px d-flex text-hover-primary text-active-primary"
                :class="{ 'active': dropdownActive, 'align-items-center': !alignAllToTop, 'align-items-start mt-2': alignAllToTop }"
                @click="dropdownActive = !dropdownActive"
            >
                <i class="ki-duotone fs-2" :class="{ 'ki-right': !dropdownActive, 'ki-down': dropdownActive }"/>
            </div>

            <div class="w-250px d-flex pe-2"
                 :class="{ 'align-items-center': !alignAllToTop, 'align-items-start flex-column': alignAllToTop }">

                <Product
                    :product="product"
                    :fetchUrl="props.fetchLineProductsUrl"
                    @update:product="updateProduct"
                />

                <div
                    v-if="description && !dropdownActive"
                    v-text="description"
                    class="description-text fs-7 mt-1"
                />

            </div>

            <div
                class="w-150px d-flex pe-2"
                :class="{ 'align-items-center': !alignAllToTop, 'align-items-start flex-column': alignAllToTop }"
            >

                <el-input-number
                    v-model="quantity"
                    :min="minQuantity"
                    :max="maxQuantity"
                    :readonly="!product"
                    controls-position="right"
                    class="w-100"
                />

                <div v-if="quantityMessage" v-text="quantityMessage" class="text-danger small mt-1" />

            </div>

            <div
                class="w-100px d-flex justify-content-end"
                :class="{ 'align-items-center': !alignAllToTop, 'align-items-start pt-2': alignAllToTop }"
            >

                <div v-if="product">
                    €<span>{{ parseFloat(sellingPriceAmount).toFixed(2) }}</span>
                </div>

                <div v-else>
                    €0.00
                </div>

            </div>

            <div
                class="w-100px d-flex justify-content-end"
                :class="{ 'align-items-center': !alignAllToTop, 'align-items-start': alignAllToTop, 'pt-2' : alignAllToTop && !discountActive }"
            >
                <div class="d-flex align-items-center" :class="{ 'flex-column': discountActive}">
                    <del v-if="discountActive" class="text-danger">€<span>{{totalPriceWithoutDiscount.toFixed(2) }}</span></del>
                    <span v-if="discountActive">€<span>{{ totalPriceWithDiscount.toFixed(2) }}</span></span>
                    <span v-if="!discountActive">€<span>{{ totalPriceWithoutDiscount.toFixed(2) }}</span></span>
                </div>
            </div>

            <div
                class="w-75px d-flex justify-content-end"
                :class="{ 'align-items-center': !alignAllToTop, 'align-items-start': alignAllToTop }"
            >

                <button type="button" class="btn btn-icon btn-sm btn-light-danger" @click="deleteLine">
                    <Icon type="delete"/>
                </button>

            </div>

        </div>

        <el-divider v-if="dropdownActive" class="mt-0 mb-1"></el-divider>

        <div class="collapse fs-6 pt-2 ps-4 pb-2" :class="{ 'show' : dropdownActive }">

            <div v-if="customSellingPrice || customVat" class="row me-0 pe-0 mb-3">

                <div v-if="customSellingPrice" class="col-8">

                    <label class="form-label fs-7">Selling price</label>

                    <el-input
                        v-model="sellingPriceAmount"
                        placeholder="Enter amount..."
                        class="input-with-select"
                    >
                        <template #prepend>
                            <SellingPriceCurrency
                                fetchUrl="/products/create/get/currencies"
                                :required="true"
                                :sellingPriceCurrency="sellingPriceCurrency"
                                @update:sellingPriceCurrency="sellingPriceCurrency = $event"
                            />
                        </template>
                        <template #append>
                            <SellingPriceUnit
                                fetchUrl="/products/create/get/units"
                                :required="true"
                                :sellingPriceUnit="sellingPriceUnit"
                                @update:sellingPriceUnit="sellingPriceUnit = $event"
                            />
                        </template>
                    </el-input>

                </div>

                <div v-if="customVat" class="col-4">

                    <label class="form-label fs-7">Vat</label>

                    <Vat
                        fetchUrl="/products/create/get/vats"
                        :vat="vat?.id"
                        @update:vat="vat = $event"
                    />

                </div>

            </div>

            <div class="row me-0 pe-0">

                <div class="col-6">

                    <div class="row mb-3">

                        <div class="col-md-12">

                            <label class="form-label fs-7">Description</label>

                            <textarea
                                v-model="description"
                                class="form-control form-control-sm"
                                placeholder="Enter description..."
                            />

                        </div>

                    </div>

                </div>

                <div class="col-6">

                    <div class="row mb-3">

                        <div class="col-md-12">

                            <div class="d-flex align-items-center "
                                 :class="{ 'mb-2': discountActive, 'mb-1': !discountActive}">
                                <label class="form-label fs-7 mb-0">Discount</label>
                                <a v-if="discountActive" href="#"
                                   class="d-flex align-items-center ms-0 text-hover-danger fs-8"
                                   @click="discountActive = !discountActive">
                                    <i class="ki-solid ki-cross fs-3 text-primary"></i>
                                </a>
                            </div>

                            <a v-if="!discountActive" href="#" @click="discountActive = !discountActive"
                               class="fs-7 text-primary fw-semibold">Add discount</a>

                            <div v-if="discountActive" class="border rounded-0 border-gray-300 px-2 py-1">

                                <div class="mb-3">

                                    <label class="form-label fs-7">Description</label>

                                    <input
                                        v-model="discount.description"
                                        type="text"
                                        class="form-control form-control-sm"
                                        placeholder="Enter description..."
                                    >

                                </div>
                                <div class="mb-3">

                                    <label class="form-label fs-7">Amount</label>

                                    <div>
                                        <el-input-number
                                            v-model="discount.value"
                                            min="0"
                                            :precision="2"
                                        />
                                    </div>

                                </div>

                                <div class="mb-3">

                                    <label class="form-label fs-7">Type</label>

                                    <div>
                                        <el-radio-group v-model="discount.type">
                                            <el-radio-button label="Price (€)" value="price"/>
                                            <el-radio-button label="Percentage (%)" value="percentage"/>
                                        </el-radio-group>
                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>

                </div>

            </div>

        </div>

    </div>

</template>

<script setup>
import Icon from '@/Shares/General/Icon.vue'
import { ref, computed, watch, onBeforeMount } from 'vue'
import Product from '@/Shares/General/Form/LinesTable/Lines/Line/Product.vue'
import Vat from '@/Shares/General/Form/LinesTable/Lines/Line/Vat.vue'
import Textarea from '@/Shares/General/Form/CustomFields/Textarea.vue'
import SellingPriceCurrency from '@/Shares/App/Modules/Products/Modals/Partials/Form/SellingPriceCurrency.vue'
import SellingPriceUnit from '@/Shares/App/Modules/Products/Modals/Partials/Form/SellingPriceUnit.vue'

// Define props
const props = defineProps({
    line: Object,
    fetchLineProductsUrl: String
})


// Define refs
const initialLoaded = ref(false);
const dropdownActive = ref(false)
const discountActive = ref(false)

const product = ref()
const productIsNew = ref(false)
const productDetails = ref({})

const description = ref()
const quantity = ref(1)
const customSellingPrice = ref(false)
const sellingPriceAmount = ref()
const sellingPriceCurrency = ref()
const sellingPriceUnit = ref()
const customVat = ref(false)
const vat = ref()
const discount = ref({
    'description': null,
    'value': 0,
    'type': 'price',
})

// Define emits
const emits = defineEmits(['update:line', 'delete:line'])


// Define computed
const subTotalProductPrice = computed(() => {

    // Check if product is selected
    if (!product.value) {
        return 0
    }

    // Check if product has price
    if (sellingPriceAmount.value) {
        return parseFloat(sellingPriceAmount.value)
    }

    return 0
})
const totalPriceWithoutDiscount = computed(() => {
    return subTotalProductPrice.value * quantity.value
})
const totalPriceWithDiscount = computed(() => {

    // Calculate discount
    let discountAmount = 0
    if (discount.value.type === 'price') {
        discountAmount = discount.value.value
    } else {
        discountAmount = totalPriceWithoutDiscount.value * (discount.value.value / 100)
    }

    return totalPriceWithoutDiscount.value - discountAmount
})

const minQuantity = computed(() => {

    // Check if product is selected
    if (!product.value) {
        return 0
    }

    // Check if product is new
    if (productIsNew.value) {
        return 1
    }

    // Check if product manages stock
    if (productDetails.value.manageStock) {

        // Check if product has no stock
        if (!productDetails.value.stock) {

            // Check if product backorders allowed
            if (productDetails.value.backordersAllowed) {

                return 1

            }

            return 0

        }

        return 1

    }

    return 0

})
const maxQuantity = computed(() => {

    // Check if product is selected
    if (!product.value) {
        return 0
    }

    // Check if product is new
    if (productIsNew.value) {
        return Infinity
    }

    // Check if product manages stock
    if (productDetails.value.manageStock) {

        // Check if product backorders allowed
        if (productDetails.value.backordersAllowed) {
            return Infinity
        }

        // Check if product has stock
        if (productDetails.value.stock) {

            return productDetails.value.stock

        }

        return 0

    }

    return Infinity

})
const quantityMessage = computed(() => {

    // Check if product is new
    if (!product.value || productIsNew.value){
        return;
    }

    // In backorder message
    if(productDetails.value.manageStock && productDetails.value.backordersAllowed && quantity.value > productDetails.value.stock){
        return 'In backorder!'
    }

    // Out of stock message
    if(productDetails.value.manageStock && !productDetails.value.backordersAllowed && quantity.value === productDetails.value.stock){
        return 'Out of stock!'
    }

})

const alignAllToTop = computed(() => {

    // Check if description is ser
    if (description.value && !dropdownActive.value) {
        return true
    }

    // Check stock messages
    if (productDetails.value.manageStock && productDetails.value.backordersAllowed && quantity.value > productDetails.value.stock) return true
    if (productDetails.value.manageStock && !productDetails.value.backordersAllowed && quantity.value === productDetails.value.stock) return true

    return false
})


// Define watchers
watch([product, description, quantity, customSellingPrice, sellingPriceAmount, sellingPriceCurrency, sellingPriceUnit, customVat, vat, discount], () => {

    if (!initialLoaded.value){
        return;
    }

    if (!product.value) {
        emits('update:line', {
            'id': props.id,
            'product': null,
        })
        return
    }

    emits('update:line', {
        'id': props.id,
        'product': product.value,
        'description': description.value,
        'quantity': quantity.value,
        'customSellingPrice': customSellingPrice.value,
        'sellingPriceAmount': sellingPriceAmount.value,
        'sellingPriceCurrency': sellingPriceCurrency.value,
        'sellingPriceUnit': sellingPriceUnit.value,
        'customVat': customVat.value,
        'vat': vat.value,
        'discount': discount.value,
    })

}, { deep: true })


// Define methods
function deleteLine() {
    emits('delete:line')
}

const updateProduct = (value) => {

    // Update product details
    productDetails.value = {
        'id': value.id,
        'isNew': value.isNew,
        'name': value.name,
        'description': value.description,
        'sellingPriceAmount': value.sellingPriceAmount,
        'sellingPriceCurrency': value.sellingPriceCurrency,
        'sellingPriceUnit': value.sellingPriceUnit,
        'manageStock': value.manageStock,
        'stock': value.stock,
        'backordersAllowed': value.backordersAllowed,
        'vat': value.vat,
    }

    // Check if initial loaded
    if (initialLoaded.value){

        // Update product refs
        product.value = productDetails.value.id
        productIsNew.value = productDetails.value.isNew

        // Update description
        description.value = productDetails.value.description

        // Update quantity
        if (productDetails.value.manageStock && productDetails.value.stock <= 0 && !productDetails.value.backordersAllowed){
            quantity.value = 0;
        } else {
            quantity.value = 1
        }

        // Update selling price
        if (productIsNew.value){
            sellingPriceAmount.value = 0
            sellingPriceCurrency.value = 'euro'
            sellingPriceUnit.value = 'pieces'
            customSellingPrice.value = true
        } else if(customSellingPrice.value) {
            sellingPriceAmount.value = productDetails.value.sellingPriceAmount
            sellingPriceCurrency.value = productDetails.value.sellingPriceCurrency
            sellingPriceUnit.value = productDetails.value.sellingPriceUnit
        } else {
            customSellingPrice.value = false
            sellingPriceAmount.value = productDetails.value.sellingPriceAmount
            sellingPriceCurrency.value = productDetails.value.sellingPriceCurrency
            sellingPriceUnit.value = productDetails.value.sellingPriceUnit
        }

        // Update vat
        if(productIsNew.value){
            vat.value = null
            customVat.value = true
        } else if (customVat.value) {
            vat.value = productDetails.value.vat
        } else {
            customVat.value = false
            vat.value = productDetails.value.vat
        }

        // Update discount
        discount.value = {
            'description': null,
            'value': 0,
            'type': 'price',
        }

    } else {

        // Update selling price
        if (!customSellingPrice.value){
            sellingPriceAmount.value = productDetails.value.sellingPriceAmount
            sellingPriceCurrency.value = productDetails.value.sellingPriceCurrency
            sellingPriceUnit.value = productDetails.value.sellingPriceUnit
        }

        // Update vat
        if(!customVat.value){
            customVat.value = false
            vat.value = productDetails.value.vat
        }

    }

    initialLoaded.value = true;

}


// Define on mounted
onBeforeMount(() => {

    if (props.line && props.line.product){

        product.value = props.line.product;

        customSellingPrice.value = props.line.customSellingPrice;
        sellingPriceAmount.value = props.line.sellingPriceAmount;
        sellingPriceCurrency.value = props.line.sellingPriceCurrency;
        sellingPriceUnit.value = props.line.sellingPriceUnit;

        customVat.value = props.line.customVat;
        vat.value = props.line.vat;

        // Update quantity
        quantity.value = props.line.quantity;

        description.value = props.line.description;

        if (props.line.discount) {
            discount.value = {
                description: props.line.discount.description,
                value: props.line.discount.value,
                type: props.line.discount.type,
            };
            discountActive.value = !!props.line.discount.value;
        } else {
            discount.value = {
                description: null,
                value: 0,
                type: 'price',
            };
            discountActive.value = false;
        }

        return;

    }

    initialLoaded.value = true;

})

</script>

<style scoped>
.description-text {
    max-width: 175px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
</style>
