<script setup>
import { computed } from 'vue'

// Props
const props = defineProps({
    style: { type: String, default: 'duotone' },
    type: String,
})

// Icon configuration based on type
const iconConfig = {
    call: { class: 'ki-phone', paths: 4 },
    meeting: { class: 'ki-profile-user', paths: 4 },
    task: { class: 'ki-text-number', paths: 6 },
    company: { class: 'ki-bank', paths: 2 },
    contact: { class: 'ki-address-book', paths: 3 },
    deal: { class: 'ki-check-square', paths: 2 },
    quotation: { class: 'ki-document', paths: 6 },
    invoice: { class: 'ki-bill', paths: 6 },
    project: { class: 'ki-element-plus', paths: 5 },
    product: { class: 'ki-color-swatch', paths: 21 },
    dashboard: { class: 'ki-element-11', paths: 4 },
    account: { class: 'ki-wrench', paths: 2 },
    calendar: { class: 'ki-calendar', paths: 6 },
    statistics: { class: 'ki-chart-simple', paths: 4 },
    getStarted: { class: 'ki-rocket', paths: 2 },
    show: { class: 'ki-eye', paths: 3 },
    create: { class: 'ki-plus', paths: 0 },
    edit: { class: 'ki-pencil', paths: 2 },
    delete: { class: 'ki-trash', paths: 5 },
    restore: { class: 'ki-arrows-circle', paths: 2 },
    telephone: { class: 'ki-phone', paths: 4 },
    email: { class: 'ki-sms', paths: 2 },
    address: { class: 'ki-geolocation', paths: 2 },
    note: { class: 'ki-notepad-edit', paths: 2 },
    user: { class: 'ki-user', paths: 2 },
    users: { class: 'ki-profile-user', paths: 4 },
    tags: { class: 'ki-clipboard', paths: 3 },
}

// Calculated property for the icon class and paths
const iconDetails = computed(() => {
    const config = iconConfig[props.type] || { class: '', paths: 0 }
    return {
        class: `ki-${props.style} ${config.class}`,
        paths: Array.from({ length: config.paths }, (_, i) => i + 1) // Genereert een array met nummers voor elke path
    }
})
</script>

<template>
    <i :class="[iconDetails.class]">
        <template v-if="style === 'duotone'" v-for="path in iconDetails.paths" :key="path">
            <span :class="`path${path}`"></span>
        </template>
    </i>
</template>

<style scoped>

</style>
