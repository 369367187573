<template>
    <div class="menu-item me-0 me-lg-2">

        <a href="https://calendly.com/virtupply/virtupply-demo" class="btn btn-sm btn-light-primary me-2" target="_blank">
            <span class="menu-title">Plan Demo</span>
        </a>

    </div>
</template>

<script setup>

</script>
