<template>
    <div class="mb-6">

        <div class="row g-1 mb-1">

            <div class="col-7 d-flex align-items-center">
                <input v-model="addressLine1" type="text" class="form-control form-control-sm w-100" placeholder="Address line 1..."/>
            </div>

            <div class="col-4 d-flex align-items-center">
                <input v-model="addressLine2" type="text" class="form-control form-control-sm w-100" placeholder="Address line 2..."/>
            </div>

            <div class="col-1 d-flex align-items-center justify-content-center">
                <button type="button" class="btn btn-icon btn-sm btn-light-danger" @click.prevent="deleteAddress">
                    <Icon type="delete" class="fs-3"/>
                </button>
            </div>

        </div>

        <div class="row g-1 mb-1">

            <div class="col-4 d-flex align-items-center">
                <el-select
                    v-model="types"
                    multiple
                    reserve-keyword
                    collapse-tags
                    collapse-tags-tooltip
                    clearable
                    placeholder="Types..."
                >
                    <el-option label="primary" value="primary"/>
                    <el-option label="invoicing" value="invoicing"/>
                    <el-option label="delivery" value="delivery"/>
                    <el-option label="visiting" value="visiting"/>
                </el-select>
            </div>

            <div class="col-4 d-flex align-items-center">
                <input v-model="postalCode" type="text" class="form-control form-control-sm w-100" placeholder="Postal code..."/>
            </div>

            <div class="col-4 d-flex align-items-center">
                <input v-model="city" type="text" class="form-control form-control-sm w-100" placeholder="City..."/>
            </div>

        </div>

        <div class="row g-1 justify-content-end">

            <div class="col-4 d-flex align-items-center">
                <input v-model="state" type="text" class="form-control form-control-sm w-100" placeholder="State..."/>
            </div>

            <div class="col-6 d-flex align-items-center">
                <el-select v-model="country" clearable filterable placeholder="Country...">
                    <el-option
                        v-for="item in countryOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    />
                </el-select>
            </div>

        </div>

        <div v-if="props.errors" v-for="error in props.errors" v-text="error" class="text-danger small mt-1"/>

    </div>
</template>

<script setup>

// Define props
import { ref, watch } from 'vue'
import Icon from '@/Shares/General/Icon.vue'


const props = defineProps({
    countryOptions: Array,
    addressLine1: String,
    addressLine2: String,
    postalCode: String,
    city: String,
    state: String,
    country: String,
    types: Array,
    errors: Array
})


// Define refs
const addressLine1 = ref(props.addressLine1 ?? '')
const addressLine2 = ref(props.addressLine2 ?? '')
const postalCode = ref(props.postalCode ?? '')
const city = ref(props.city ?? '')
const state = ref(props.state ?? '')
const country = ref(props.country ?? '')
const types = ref(props.types ?? [])

const countryOptions = ref(props.countryOptions ?? [])


// Define emits
const emits = defineEmits(['update:address', 'delete:address'])


// Define watchers
watch(() => props.addressLine1, (value) => {
    addressLine1.value = value
})
watch(() => props.addressLine2, (value) => {
    addressLine2.value = value
})
watch(() => props.postalCode, (value) => {
    postalCode.value = value
})
watch(() => props.city, (value) => {
    city.value = value
})
watch(() => props.state, (value) => {
    state.value = value
})
watch(() => props.country, (value) => {
    country.value = value
})
watch(() => props.types, (value) => {
    types.value = value
})
watch(() => [addressLine1.value, addressLine2.value, postalCode.value, city.value, state.value, country.value, types.value], () => {
    emits('update:address', {
        'addressLine1': addressLine1.value,
        'addressLine2': addressLine2.value,
        'postalCode': postalCode.value,
        'city': city.value,
        'state': state.value,
        'country': country.value,
        'types': types.value
    })
})


// Define methods
function deleteAddress(){
    emits('delete:address')
}

</script>
