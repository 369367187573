<template>

    <label class="form-label fs-7">Expected closing date</label>

    <el-date-picker
        v-model="expectedClosingDate"
        type="date"
        placeholder="Select a date"
        class="me-2 w-100 hide-icon"
        format="DD MMM. YYYY"
        value-format="YYYY-MM-DD"
        :clearable="false"
    />

</template>

<script setup>
import {ref, watch} from 'vue'

// Define props and emits
const props = defineProps({
    expectedClosingDate: String,
})

const emits = defineEmits(['update:expectedClosingDate'])

let expectedClosingDate = ref(props.expectedClosingDate);

// Update the local expectedClosingDate when the prop changes
watch(() => props.expectedClosingDate, (newExpectedClosingDate) => {
    expectedClosingDate.value = newExpectedClosingDate;
});

// Watch for changes in the local expectedClosingDate and emit an event
watch(expectedClosingDate, (newExpectedClosingDate) => {
    if (newExpectedClosingDate !== props.expectedClosingDate) {
        emits('update:expectedClosingDate', newExpectedClosingDate);
    }
});
</script>
