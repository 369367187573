<template>
    <a href="#" class="d-flex align-items-center fs-7 text-primary" @click.prevent="addEmail">
        <i class="ki-duotone ki-plus fs-6 text-primary"></i>
        Add Email
    </a>
</template>

<script setup>

// Define emits
const emits = defineEmits(['addEmail'])

// Define methods
function addEmail() {
    emits('addEmail')
}

</script>

