<template>
    <Line
        v-for="(line, index) in lines"
        :key="index"
        :line="line"
        :productOptions="productOptions"
        :fetchLineProductsUrl="props.fetchLineProductsUrl"
        @update:line="updateLine(index, $event)"
        @delete:line="deleteLine(index)"
    />
</template>

<script setup>
import { ref, watch, onMounted } from 'vue'
import Line from '@/Shares/General/Form/LinesTable/Lines/Line.vue'
import axios from 'axios'


// Define props
const props = defineProps({
    lines: Array,
    fetchLineProductsUrl: String
})


// Define refs
let lines = ref(props.lines ?? [])
let productOptions = ref([])


// Define emits
const emits = defineEmits(['update:lines'])


// Define watchers
watch(() => props.lines, (value) => {
    lines.value = value
}, { deep: true })

watch(() => lines.value, (value) => {
    emits('update:lines', value)
}, { deep: true })


// Define methods
function updateLine(index, updatedLine) {
    lines.value[index] = updatedLine
}

function deleteLine(index) {
    lines.value.splice(index, 1)
}

</script>
