<template>

    <label class="form-label fs-7 required">Start at & duration </label>

    <div class="d-flex align-items-center">

        <el-date-picker
            v-model="date"
            type="date"
            placeholder="Select a date..."
            class="me-2 w-100 hide-icon"
            format="DD MMM. YYYY"
            value-format="YYYY-MM-DD"
            :clearable="false"
            @change="onDateChange"
        />

        <el-select
            v-model="startTime"
            placeholder="Select start..."
            class="hide-icon"
            filterable
            @change="onStartTimeChange"
        >
            <el-option
                v-for="option in startTimes"
                :key="option"
                :label="option"
                :value="option"
            />
        </el-select>

        <span class="mx-4">-</span>

        <el-select
            v-model="endTime"
            placeholder="Select end..."
            class="hide-icon"
            filterable
            @change="onEndTimeChange"
        >
            <el-option
                v-for="option in endTimes"
                :key="option.value"
                :label="option.label"
                :value="option.value"
            />
        </el-select>

    </div>

</template>

<script setup>
import { ref, watch, computed, onMounted, nextTick } from 'vue'
import moment from 'moment'
import { debounce } from 'lodash'


// Define variables
const intervalOptions = [
    { label: '5 min', minutes: 5 },
    { label: '10 min', minutes: 10 },
    { label: '15 min', minutes: 15 },
    { label: '30 min', minutes: 30 },
    { label: '45 min', minutes: 45 },
    { label: '1h', minutes: 60 },
    { label: '1h 15 min', minutes: 75 },
    { label: '1h 30 min', minutes: 90 },
    { label: '1h 45 min', minutes: 105 },
    { label: '2h', minutes: 120 },
    { label: '2h 30 min', minutes: 150 },
    { label: '3h', minutes: 180 },
    { label: '4h', minutes: 240 },
    { label: '5h', minutes: 300 },
    { label: '6h', minutes: 360 },
    { label: '7h', minutes: 420 },
    { label: '8h', minutes: 480 },
]


// Define props
const props = defineProps({
    startAt: Date,
    endAt: Date,
})


// Define emits
const emits = defineEmits(['update:startAt', 'update:endAt'])


// Define refs
let date = ref()
let startTime = ref()
let endTime = ref()

let previousStartTime = ref()


// Define computed
const startTimes = computed(() => {
    const times = [];
    const currentTime = moment();
    const roundedCurrentTime = currentTime.clone().startOf('day').add(Math.ceil(currentTime.minute() / 15) * 15, 'minutes');

    for (let m = roundedCurrentTime; m.isBefore(moment().endOf('day')); m.add(15, 'minutes')) {
        times.push(m.format('HH:mm'));
    }

    return times;
});
const endTimes = computed(() => {
    if (!startTime.value || !date.value) {
        return []
    }

    const selectedStartTime = moment(`${date.value}T${startTime.value}`);

    return intervalOptions.map((option) => {
        const endTimeMoment = selectedStartTime.clone().add(option.minutes, 'minutes');
        return {
            label: `${endTimeMoment.format('HH:mm')} (${option.label})`,
            value: endTimeMoment.format('HH:mm'),
        }
    })
})


// Define watchers
watch(() => props.startAt, (value) => {
    date.value = moment(value).format('YYYY-MM-DD')
    startTime.value = moment(value).format('HH:mm')

    previousStartTime.value = startTime.value
})
watch(() => props.endAt, (value) => {
    endTime.value = moment(value).format('HH:mm')
})


// Define methods
function onDateChange(value){
    emitUpdateDatesDebounced()
}

async function onStartTimeChange(value) {

    if (endTime.value) {
        const oldStartAt = moment(date.value + ' ' + previousStartTime.value);
        const currentEndAt = moment(date.value + ' ' + endTime.value);
        const durationDiff = moment.duration(currentEndAt.diff(oldStartAt));
        const newStartAt = moment(date.value + ' ' + value);
        const newEndAt = moment(newStartAt).add(durationDiff);
        endTime.value = newEndAt.format('HH:mm');
    }

    previousStartTime.value = value;

    emitUpdateDatesDebounced();

}

function onEndTimeChange(value){
    emitUpdateDatesDebounced()
}

const emitUpdateDatesDebounced = debounce(() => {
    emits('update:startAt', moment(date.value + ' ' + startTime.value));
    emits('update:endAt', moment(date.value + ' ' + endTime.value));
}, 150);

// Define on mounted
onMounted(() => {
    if (props.startAt){
        date.value = moment(props.startAt).format('YYYY-MM-DD')
        startTime.value = moment(props.startAt).format('HH:mm')
        previousStartTime.value = startTime.value
    }
    if (props.endAt){
        endTime.value = moment(props.endAt).format('HH:mm')
    }
})

</script>

<style>
.el-input__prefix {
    display: none !important;
}

.el-select__prefix {
    display: none !important;
}
</style>
