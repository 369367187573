<script setup>
import { router, usePage } from '@inertiajs/vue3'
import { watch, onMounted, ref } from 'vue'
import flasher from '@flasher/flasher'

// Prepare variables
const page = usePage()


// Define on mounted
onMounted(() => {
    if(page.props.messages){
        flasher.render(page.props.messages);
    }
});

router.on('finish', (event) => {
    if(page.props.messages){
        flasher.render(page.props.messages);
    }
})

</script>
