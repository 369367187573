<template>
    <div class="pe-5">

        <table class="table g-2 gs-0 fw-bold text-gray-700 ">

            <thead>
                <tr class="border-bottom fs-8 fw-bold text-gray-700 text-uppercase">
                    <th class="w-45px"></th>
                    <th class="w-250px">Item</th>
                    <th class="w-150px">Quantity</th>
                    <th class="w-100px text-end">Price</th>
                    <th class="w-100px text-end">Total</th>
                    <th class="w-75px text-end"></th>
                </tr>
            </thead>

        </table>

    </div>

    <el-scrollbar v-if="linesValue.length > 0 || discountsValue.length > 0" max-height="450" always class="pe-5">

        <Lines
            :lines="linesValue"
            :fetchLineProductsUrl="props.fetchLineProductsUrl"
            @update:lines="linesValue = $event"
        />

        <el-divider v-if="linesValue.length > 0 && discountsValue.length > 0" class="mt-8 mb-6">General discounts</el-divider>

        <Discounts
            :discounts="discountsValue"
            @update:discounts="discountsValue = $event"
        />

    </el-scrollbar>

    <el-empty v-else class="h-425px" description="No lines added yet." />

    <table class="table g-5 gs-0 fw-bold text-gray-700">

        <tfoot>

            <tr class="border-top">

                <th class="py-6"></th>

                <th colspan="2" class="p-0">
                    <div class="d-flex flex-column align-items-start">
                        <div class="fs-6 fw-bold text-gray-700">Subtotal</div>
                    </div>
                </th>

                <th colspan="2" class="p-0 text-end">
                    €<span v-text="calculatedSubTotal.toFixed(2)" />
                </th>

            </tr>

            <tr>

                <th class="p-0"></th>

                <th colspan="2" class="p-0">
                    <div class="d-flex flex-column align-items-start">
                        <div class="fs-7">Discount</div>
                    </div>
                </th>

                <th colspan="2" class="p-0 text-end">
                    €<span v-text="calculatedDiscount.toFixed(2)" />
                </th>

            </tr>

            <tr>

                <th class="p-0"></th>

                <th colspan="2" class="p-0">
                    <div class="d-flex flex-column align-items-start">
                        <div class="fs-7">Vat</div>

                    </div>
                </th>

                <th colspan="2" class="p-0 text-end">
                    €<span v-text="calculatedVat.toFixed(2)" />
                </th>

            </tr>

            <tr>

                <th class="py-6"></th>

                <th colspan="2" class="p-0">
                    <div class="d-flex flex-column align-items-start">
                        <div class="fs-4 fw-bold text-gray-700">Total</div>
                    </div>
                </th>

                <th colspan="2" class="p-0 text-end">
                    €<span v-text="calculatedTotal.toFixed(2)" />
                </th>

            </tr>


            <tr class="border-top align-top fw-bold text-gray-700">

                <th class="text-primary">

                    <LineAddButton @create:line="addLine"/>

                </th>

                <th colspan="3" class="border-bottom border-bottom-dashed ps-0">

                    <div class="d-flex flex-column align-items-end">

                        <DiscountAddButton @create:discount="addDiscount"/>

                    </div>

                </th>

            </tr>

        </tfoot>

    </table>

</template>

<script setup>
import LineAddButton from '@/Shares/General/Form/LinesTable/Lines/AddButton.vue'
import DiscountAddButton from '@/Shares/General/Form/LinesTable/Discounts/AddButton.vue'
import Discounts from '@/Shares/General/Form/LinesTable/Discounts.vue'
import Lines from '@/Shares/General/Form/LinesTable/Lines.vue'
import { ref, watch, computed } from 'vue'


// Define props
const props = defineProps({
    lines: Array,
    discounts: Array,
    fetchLineProductsUrl: String,
})


// Define refs
const linesValue = ref(props.lines ?? [])
const discountsValue = ref(props.discounts ?? [])


// Define emits
const emits = defineEmits(['update:lines', 'update:discounts'])


// Define watchers
watch(() => props.lines, (value) => {
    linesValue.value = value
})
watch(() => props.discounts, (value) => {
    discountsValue.value = value
})

watch(() => linesValue.value, (value) => {
    emits('update:lines', value)
}, { deep: true })

watch(() => discountsValue.value, (value) => {
    emits('update:discounts', value)
}, { deep: true })


// Define computed
const calculatedSubTotal = computed(() => {
    return linesValue.value.reduce((total, line) => {
        if (!line.product){
            return total
        }
        return total + parseFloat(line.sellingPriceAmount) * parseFloat(line.quantity)
    }, 0)
})
const calculatedDiscount = computed(() => {

    let calculatedDiscount = 0;

    linesValue.value.forEach((line, index)=>{

        if (!line.product){
            return;
        }

        if (line.discount){
            let subTotalProductPrice = 0;
            if (!line.sellingPriceAmount && (line.discount && line.discount.type === 'percentage')){
                return
            }
            subTotalProductPrice = parseFloat(line.sellingPriceAmount)
            const totalPriceWithoutDiscount = subTotalProductPrice * parseFloat(line.quantity);

            let discountAmount = 0;
            if (line.discount.type === 'price'){
                discountAmount = parseFloat(line.discount.value)
            } else if(line.discount.type === 'percentage') {
                discountAmount = totalPriceWithoutDiscount * (parseFloat(line.discount.value) / 100)
            }

            calculatedDiscount += discountAmount;
        }

    });

    calculatedDiscount += discountsValue.value.reduce((total, discount) => {
        if (discount.value){

            if (discount.type === 'price') {
                return total + parseFloat(discount.value)
            } else if (discount.type === 'percentage') {
                return total + (parseFloat(calculatedSubTotal.value) * parseFloat(discount.value) / 100)
            }

        }
        return total;
    }, 0)

    return calculatedDiscount
})
const calculatedVat = computed(() => {
    return linesValue.value.reduce((total, line) => {
        if(line.vat){
            return total + ((parseFloat(line.sellingPriceAmount) * parseFloat(line.quantity)) / 100 * parseFloat(line.vat.value))
        }
        return total
    }, 0)
})
const calculatedTotal = computed(() => {
    return calculatedSubTotal.value - calculatedDiscount.value + calculatedVat.value
})


// Define methods
function addLine() {
    linesValue.value.push({ 'product': null, 'description': null, 'quantity': null, 'discount': null })
}

function addDiscount() {
    discountsValue.value.push({ 'description': null, 'type': null, 'value': null })
}

</script>
