<template>

    <label class="form-label fs-7">Description</label>

    <textarea
        v-model="description"
        class="form-control form-control-sm"
        placeholder="Enter description..."
    />

</template>

<script setup>
import { ref, watch } from 'vue'


// Define props
const props = defineProps({
    description: String,
})


// Define emits
const emits = defineEmits(['update:description'])


// Define refs
let description = ref(props.description || '')


// Define watch
watch(() => props.description, (value) => {
    description.value = value;
})
watch(() => description.value, (value) => {
    emits('update:description', value)
})

</script>
