<template>

    <!--begin::Menu item-->
    <div class="menu-item">
        <a href="#" class="d-flex justify-content-between align-items-center bg-hover-gray-100 py-3 px-8 m-0 w-100" @click.prevent="performAction">

            <div class="d-flex justify-content-between align-items-center">
                <i :class="'ki-duotone ki-'+ icon.name +' me-3 fs-2 text-gray-600'">
                    <span v-for="n in icon.paths" :class="'path' + n"></span>
                </i>
                <span class="text-dark fs-5 fw-semibold">{{ name }}</span>
            </div>

            <span class="bg-gray-200 text-gray-700 fw-bold h-25px w-25px fs-6 rounded d-flex align-items-center justify-content-center text-uppercase">{{ keyBinding }}</span>

        </a>
    </div>
    <!--end::Menu item-->

</template>

<script setup>
import { onBeforeUnmount, onMounted } from 'vue'
import { useModalStore } from '@/Stores/modalStore';
import CompanyCreateModal from '@/Shares/App/Modules/Companies/Modals/Create.vue'
import ContactCreateModal from '@/Shares/App/Modules/Contacts/Modals/Create.vue'
import DealCreateModal from '@/Shares/App/Modules/Deals/Modals/Create.vue'
import CallCreateModal from '@/Shares/App/Modules/Calls/Modals/Create.vue'
import MeetingCreateModal from '@/Shares/App/Modules/Meetings/Modals/Create.vue'
import TaskCreateModal from '@/Shares/App/Modules/Tasks/Modals/Create.vue'

const modalStore = useModalStore();

let props = defineProps({
    name: String,
    keyBinding: String,
    icon: Object
})

const performAction = () => {

    if (props.keyBinding === 'p'){
        modalStore.setProps({
            storeUrl: `/companies/store`,
        })

        modalStore.openModal(CompanyCreateModal);
    }

    if (props.keyBinding === 'o'){
        modalStore.setProps({
            storeUrl: `/contacts/store`,
        })
        modalStore.openModal(ContactCreateModal);
    }

    if (props.keyBinding === 'i'){
        modalStore.setProps({
            storeUrl: `/deals/store`,
        })
        modalStore.openModal(DealCreateModal);
    }

    if (props.keyBinding === 'u'){
        modalStore.setProps({
            storeUrl: `/calls/store`,
        })
        modalStore.openModal(CallCreateModal);
    }

    if (props.keyBinding === 'y'){
        modalStore.setProps({
            storeUrl: `/meetings/store`,
        })
        modalStore.openModal(MeetingCreateModal);
    }

    if (props.keyBinding === 't'){
        modalStore.setProps({
            storeUrl: `/tasks/store`,
        })
        modalStore.openModal(TaskCreateModal);
    }

};

const handleKeyPress = (e) => {
    // Check if a modal is open
    if (modalStore.currentModal !== null) {
        return;
    }

    if (document.activeElement.tagName !== 'INPUT' && document.activeElement.tagName !== 'TEXTAREA') {
        if (e.key === props.keyBinding) {
            performAction();
        }
    }
};

onMounted(() => {
    window.addEventListener('keydown', handleKeyPress);
});

onBeforeUnmount(() => {
    window.removeEventListener('keydown', handleKeyPress);
});

</script>
