<template>

    <label class="form-label fs-7 required">Amount</label>

    <el-input
        :disabled="props.disabled"
        v-model="amountValue"
        placeholder="Enter amount..."
        :formatter="(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
        :parser="(value) => value.replace(/\$\s?|(,*)/g, '')"
    >
        <template #append>
            <el-select
                :disabled="props.disabled"
                v-model="amountCurrency"
                filterable
                reserve-keyword
                default-first-option
                placeholder="Select currency..."
                style="width: 80px"
            >
                <el-option
                    v-for="item in amountCurrencyOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                >{{ item.label }}
                </el-option>
            </el-select>
        </template>
    </el-input>

</template>

<script setup>
import {ref, watch, onMounted} from 'vue'

// Define props and emits
const props = defineProps({
    fetchUrl: String,
    amountValue: String,
    amountCurrency: String,
    disabled: { type: Boolean, default: false },
})

const emits = defineEmits(['update:amountValue', 'update:amountCurrency'])

// Define reactive properties using ref
const amountValue = ref(props.amountValue || '')
const amountCurrency = ref(props.amountCurrency || '')

// Watch for changes in formData and emit them
watch(() => amountValue.value, (newAmountValue) => {
    emits('update:amountValue', newAmountValue)
})
watch(() => props.amountValue, (newAmountValue) => {
    amountValue.value = newAmountValue
})

watch(() => amountCurrency.value, (newAmountCurrency) => {
    emits('update:amountCurrency', newAmountCurrency)
})
watch(() => props.amountCurrency, (newAmountCurrency) => {
    amountCurrency.value = newAmountCurrency
})

/**
 * Remote fetch
 * */
const amountCurrencyOptions = ref([])

// Fetch users data
onMounted(async () => {
    if (props.fetchUrl) {
        try {
            const response = await fetch(props.fetchUrl)
            if (response.ok) {
                const data = await response.json()
                amountCurrencyOptions.value = data.map(item => ({
                    value: item.value,
                    label: item.label,
                }))
            } else {
                console.error('Fetch failed:', response.statusText)
            }
        } catch (error) {
            console.error('Fetch error:', error)
        }
    }
})
</script>
