<template>

    <textarea
        v-model="value"
        class="form-control form-control-sm"
        :placeholder="computedPlaceholder"
        :required="props.required"
    />

</template>

<script setup>
import { ref, watch, computed } from 'vue'


// Define props
const props = defineProps({
    value: String,
    placeholder: String,
    required: Boolean,
})


// Define emits
const emits = defineEmits(['update:value'])


// Define refs
let value = ref(props.value || '')


// Define computed
const computedPlaceholder = computed(() => {
    return props.placeholder || 'Enter text...';
})


// Define watchers
watch(() => props.value, (newValue) => {
    value.value = newValue
})

watch(() => value.value, (newValue) => {
    emits('update:value', newValue)
})

</script>
