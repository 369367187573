<template>

    <el-select
        v-model="contact"
        ref="contactSelectRef"
        filterable
        clearable
        default-first-option
        :reserve-keyword="false"
        placeholder="Search for contact..."

        :remote="isRemoteEnabled"
        :remote-method="remoteMethod"
        :loading="loading"
        loading-text="Loading contacts..."
        no-data-text="No contacts found."

        @focus="updateBadge"
        @blur="updateBadge"
        @visible-change="updateBadge"
        @change="updateContact"

        @input="searchText = $event.target.value"

    >

        <el-option
            v-for="item in contactOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
        >
            {{ item.label }} <span v-if="item.isNew" class="new-badge ms-2 badge badge-sm badge-primary">New</span>
        </el-option>

        <template #footer v-if="searchText">
            <a class="btn btn-sm text-dark text-hover-primary m-0 p-0 d-flex align-items-center" @click="addAndSelectSearchText">
                <i class="ki-solid ki-plus me-0 text-dark"/> Add <span class="fw-bolder mx-1">'{{ searchText }}'</span>as new contact
            </a>
        </template>

    </el-select>

</template>

<script setup>
import { computed, nextTick, onMounted, ref, watch} from 'vue'


// Define props
const props = defineProps({
    fetchUrl: String,
    contact: String,
    company: String,
    customerType: String,
    contactIsNew: Boolean,
    companyIsNew: Boolean,
})


// Define refs
const contact = ref(props.contact || '')
const contactIsNew = ref(props.contactIsNew || false)

const searchText = ref('')
const loading = ref(false)

const contactSelectRef = ref()
const contactOptions = ref([])


// Define emits
const emit = defineEmits(['update:contact', 'update:contactIsNew'])


// Define computed
const isRemoteEnabled = computed(() => !props.company || props.companyIsNew)
const customerType = computed(() => props.company ? 'company' : 'contact')


// Define methods
const fetchContacts = async (query) => {
    try {
        const response = await fetch(`${props.fetchUrl}?${query}`)
        if (!response.ok) throw new Error('Network response was not ok')
        const data = await response.json()

        // Map contacts
        let contacts = [];
        if (Array.isArray(data)){
            contacts = data.map(item => ({
                value: item.id,
                label: item.name,
                isNew: false,
            }))
        } else {
            contacts = [{
                value: data.id,
                label: data.name,
                isNew: false,
            }]
        }

        // Check if current contact is new
        if (contactIsNew.value){
            contacts.push({
                value: contact.value,
                label: contact.value,
                isNew: true,
            })
        }

        return contacts

    } catch (error) {
        console.error('Error fetching contacts:', error)
        return []
    }
}

const remoteMethod = async (search) => {
    if (!search) return
    if (search) {
        loading.value = true
        contactOptions.value = await fetchContacts(`search=${search}`)
        loading.value = false
    }
}

const addAndSelectSearchText = () => {

    // Prepare new contact
    const newContact = searchText.value

    // Update refs
    contactIsNew.value = true
    contact.value = newContact
    searchText.value = null

    // Blur
    nextTick(() => {
        contactSelectRef.value.blur()
    })

    // Set isNew property to true for the new contact
    contactOptions.value.push({
        value: newContact,
        label: newContact,
        isNew: true,
    })

    // update badge
    updateBadge()

    // Emit changes
    emit('update:contact', contact.value)
    emit('update:contactName', contact.value)
    emit('update:contactIsNew', contactIsNew.value)
}

const updateContact = (value) => {

    // Filter out new
    contactOptions.value = contactOptions.value.filter(contactOption => !contactOption.isNew);

    // Find selected option
    const selectedOption = contactOptions.value.find(contactOption => contactOption.value === contact.value)

    // Update refs
    searchText.value = null
    contact.value = value
    contactIsNew.value = selectedOption?.isNew;

    // Emits
    emit('update:contact', value)
    emit('update:contactName', selectedOption?.label)
    emit('update:contactIsNew', contactIsNew.value)

}

const updateBadge = () => {
    nextTick(() => {

        // Get placeholder
        const placeholder = contactSelectRef.value?.$el.querySelector('.el-select__placeholder')
        if (!placeholder) return

        // Check if current contact is new
        const selectedOption = contactOptions.value.find(opt => opt.value === contact.value)
        if (contact.value && selectedOption && selectedOption.isNew) {

            // Set new contact to true
            contactIsNew.value = true

            // Add classes
            placeholder.classList.add('d-flex', 'align-items-center', 'justify-content-between')

            // Check if badge exist
            const existingBadge = placeholder.querySelector('.new-badge')
            if (existingBadge) {
                return
            }

            // Add new badge
            const newBadge = document.createElement('span')
            newBadge.className = 'new-badge ms-2 badge badge-sm badge-primary'
            newBadge.textContent = 'New'
            placeholder.appendChild(newBadge)

            return
        }

        // Set new contact to false
        contactIsNew.value = false

        // Remove existing badge
        const existingBadge = placeholder.querySelector('.new-badge')
        if (existingBadge) {
            placeholder.classList.remove('d-flex', 'align-items-center', 'justify-content-between')
            existingBadge.remove()
        }

    })
}


// Define watchers
watch(() => props.company, async (value) => {

    // Check if new
    if (!props.companyIsNew && value) {
        loading.value = true
        let query = 'company=' + props.company
        contactOptions.value = await fetchContacts(query)
        loading.value = false
    } else {

        contactOptions.value = contactOptions.value.filter(contactOption => contactOption.isNew);

    }

})


// Define on mounted
onMounted(async () => {

    if (props.contact) {

        // Check if new
        if (!props.contactIsNew) {
            loading.value = true
            let query = 'contact=' + props.contact
            contactOptions.value = await fetchContacts(query)
            loading.value = false
            return;
        }

        // Add contact as option
        contactOptions.value.push({
            value: props.contact,
            label: props.contact,
            isNew: true,
        })

        updateBadge()

    } else if (props.company && !props.companyIsNew) {
        loading.value = true
        let query = 'company=' + props.company
        contactOptions.value = await fetchContacts(query)
        loading.value = false
    }

})


</script>
