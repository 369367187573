<template>

    <label class="form-label fs-7">Guests</label>

    <el-select
        v-model="guests"
        multiple
        filterable
        remote
        reserve-keyword
        placeholder="Add guests from your contacts"
        :remote-method="remoteMethod"
        :loading="loading"
    >
        <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
            class="d-flex d-flex align-items-center"
        />
    </el-select>

</template>

<script setup>
import {ref, watch} from 'vue'


// Define props
const props = defineProps({
    fetchUrl: String,
    guests: Array,
})


// Define emits
const emits = defineEmits(['update:guests'])


// Define refs
const guests = ref(props.guests || [])
const options = ref([])
const loading = ref(false)


// Define watchers
watch(() => props.guests, async (value) => {

    // Check if lists are equal
    if (JSON.stringify(value) === JSON.stringify(guests.value)) {
        return;
    }

    if (value) {

        // If new guests is a value in options map then select it directly
        if (options.value.some(option => option.value === value)) {
            guests.value = value
            return
        }

        // If not then fetch it with fetchGuests function and add guest as additional query
        loading.value = true
        const query = 'guests=' + value;
        options.value = await fetchGuests(query)
        loading.value = false
    }

    guests.value = value

})
watch(() => guests.value, (value) => {

    emits('update:guests', value)
})


// Define functions
const fetchGuests = async (query, additionalQuery) => {

    if (additionalQuery) {
        query += '&' + additionalQuery
    }

    const response = await fetch(props.fetchUrl + `?${query}`)
    if (!response.ok) throw new Error('Netwerk respons was niet ok')
    let data = await response.json();

    // If data is an object (and not an array), convert it to an array with that object
    if (!Array.isArray(data)) {
        data = [data];
    }

    return data.map(item => ({
        value: item.id,
        label: item.name,
    }))

}

const remoteMethod = async (search) => {
    if (search) {
        loading.value = true

        const query = 'search=' + search
        options.value = await fetchGuests(query)

        loading.value = false
    } else {
        options.value = []
    }
}

</script>
