<template>

    <div class="card p-0 mb-1 rounded-1 border-gray-300" >

        <div class="card-body px-4 py-2 d-flex d-flex">

            <div class="w-450px d-flex align-items-center pe-2">

                <input
                    v-model="description"
                    type="text"
                    class="form-control form-control-sm"
                    placeholder="Enter description..."
                >

            </div>

            <div class="d-flex align-items-center pe-2">

                <el-input
                    v-model="value"
                    placeholder="Please input"
                    class="input-with-select"
                >
                    <template #prepend>

                        <el-select v-model="type" placeholder="Select" style="width: 75px">
                            <el-option label="€" value="price"/>
                            <el-option label="%" value="percentage"/>
                        </el-select>

                    </template>

                </el-input>

            </div>

            <div class="d-flex align-items-center justify-content-end">

                <button type="button" class="btn btn-icon btn-sm btn-light-danger" @click="deleteDiscount">
                    <Icon type="delete"/>
                </button>

            </div>
        </div>

    </div>
</template>

<script setup>
import Icon from '@/Shares/General/Icon.vue'
import { onMounted, ref, watch } from 'vue'

// Define props
const props = defineProps({
    id: String,
    description: String,
    value: Number,
    type: String
})


// Define refs
const description = ref()
const value = ref()
const type = ref()


// Define emits
const emits = defineEmits(['update:discount', 'delete:discount'])


// Define watchers
watch(() => props.description, (newValue) => {
    description.value = newValue
})
watch(() => props.value, (newValue) => {
    value.value = newValue
})
watch(() => props.type, (newValue) => {
    type.value = newValue
})
watch(() => [description.value, value.value, type.value], () => {
    emits('update:discount', {
        'id': props.id,
        'description': description.value,
        'value': value.value,
        'type': type.value,
    })
})


// Define methods
function deleteDiscount() {
    emits('delete:discount')
}


// Define on mounted
onMounted(() => {
    description.value = props.description ?? ''
    value.value = props.value ?? 0
    type.value = props.type ?? 'price'
})

</script>
