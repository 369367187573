<template>

    <label class="form-label fs-7">Extra users</label>

    <el-select
        v-model="extraUsers"
        multiple
        filterable
        collapse-tags
        collapse-tags-tooltip
        clearable
        placeholder="Select"
        @change="updateUsers"
    >
        <el-option
            v-for="user in availableOptions"
            :key="user.value"
            :label="user.label"
            :value="user.value"
            class="d-flex align-items-center"
        >
            <div class="me-2 d-flex d-flex align-items-center">

                <div v-if="user.image" class="symbol symbol-25px symbol-circle">
                    <img alt="Pic" :src="user.image">
                </div>

                <div v-else class="symbol symbol-25px symbol-circle">
                    <span class="symbol-label bg-light-primary text-primary fw-bold fs-8 text-uppercase">{{ user.initials }}</span>
                </div>

            </div>

            <span>{{ user.label }}</span>
        </el-option>
    </el-select>

</template>

<script setup>
import { onMounted, ref, watch, computed } from 'vue';


// Define props
const props = defineProps({
    fetchUrl: String,
    extraUsers: Array,
    assignedTo: String,
});


// Define emits
const emits = defineEmits(['update:extraUsers']);


// Define refs
const extraUsers = ref([]);

const options = ref([]);


// Define computed
const availableOptions = computed(() => {
    if (props.assignedTo){
        return options.value.filter(option =>
            !extraUsers.value.includes(option.value) && option.value !== props.assignedTo
        );
    }
    return options.value
});


// Define watchers
watch(() => props.extraUsers, (value) => {
    extraUsers.value = value;
});

watch(() => props.assignedTo, (value) => {
    extraUsers.value = extraUsers.value.filter(user => user !== value);
});


// Define methods
const updateUsers = (value) => {
    emits('update:extraUsers', value);
};


// Define on mounted
onMounted(async () => {
    try {
        const response = await fetch(props.fetchUrl);
        if (response.ok) {
            const data = await response.json();
            options.value = data.map(item => ({
                value: item.id,
                label: item.name,
                initials: item.initials,
                image: item.image,
            }));

            // Filter na laden om assignedTo te verwijderen als het al geselecteerd is
            extraUsers.value = (props.extraUsers || []).filter(user => user !== props.assignedTo);
        } else {
            console.error('Fetch failed:', response.statusText);
        }
    } catch (error) {
        console.error('Fetch error:', error);
    }
});
</script>
