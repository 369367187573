<template>

    <div
        ref="modalRef"
        class="modal"
        :class="{ [`modal-${size}`]: true, fade: modalStore.useFade }"
        tabindex="-1"
        aria-labelledby="modalTitle"
        aria-hidden="true"
    >

        <div class="modal-dialog modal-dialog-centered">

            <div class="modal-content">

                <div v-if="!withoutHeader" class="modal-header py-4">

                    <h3 id="modalTitle" class="modal-title fs-3">{{ title }}</h3>

                    <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    />

                </div>

                <slot />

            </div>

        </div>

    </div>

</template>

<script setup>
import { onMounted, onBeforeUnmount, ref, watch } from 'vue';
import { useModalStore } from '@/Stores/modalStore'

// Define variables
let modal = null;

// Define props
const props = defineProps({
    size: { type: String, default: 'md' },
    title: String,
    withoutHeader: Boolean,
});

// Define emits
const emits = defineEmits(['modal-show', 'modal-hide']);

// Define refs
const modalRef = ref(null);
const modalStore = useModalStore();

onMounted(() => {

    modal = new bootstrap.Modal(modalRef.value, {
        keyboard: true,
    });

    modal.show();

    modalRef.value.addEventListener('shown.bs.modal', () => emits('modal-show'));
    modalRef.value.addEventListener('hidden.bs.modal', () => {
        emits('modal-hide');
        modalStore.closeModal();
    });

});

onBeforeUnmount(() => {
    if (modal) {
        modal.dispose();
    }
});

</script>

<style>
@media (min-width: 992px) {
    [data-kt-app-header-fixed="true"].modal-open .app-header {
        padding-right: 0 !important;
    }
}
</style>
