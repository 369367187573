<template>

    <div class="mb-2">

        <div class="row g-1">

            <div class="col-7 d-flex align-items-center">
                <input v-model="number" type="tel" class="form-control form-control-sm w-100" placeholder="Number..."/>
            </div>

            <div class="col-4 d-flex align-items-center">
                <el-select
                    v-model="types"
                    multiple
                    reserve-keyword
                    collapse-tags
                    collapse-tags-tooltip
                    clearable
                    placeholder="Select types..."
                >
                    <el-option label="Phone" value="phone"/>
                    <el-option label="Telephone" value="telephone"/>
                </el-select>
            </div>

            <div class="col-1 d-flex align-items-center justify-content-center">
                <button type="button" class="btn btn-icon btn-sm btn-light-danger" @click.prevent="deleteTelephone">
                    <Icon type="delete" class="fs-3"/>
                </button>
            </div>

        </div>

        <div v-if="props.errors" v-for="error in props.errors" v-text="error" class="text-danger small mt-1"/>

    </div>

</template>

<script setup>

// Define props
import { ref, watch } from 'vue'
import Icon from '@/Shares/General/Icon.vue'
import Contacts from '@/Shares/App/Modules/Companies/Modals/Partials/Form/Contacts.vue'


const props = defineProps({
    number: String,
    types: Array,
    errors: Array
})


// Define refs
const number = ref(props.number ?? '')
const types = ref(props.types ?? [])


// Define emits
const emits = defineEmits(['update:telephone', 'delete:telephone'])


// Define watchers
watch(() => props.number, (value) => {
    number.value = value
})
watch(() => props.types, (value) => {
    types.value = value
})
watch(() => [number.value, types.value], () => {
    emits('update:telephone', { 'number': number.value, 'types': types.value })
})


// Define methods
function deleteTelephone() {
    emits('delete:telephone')
}

</script>
