<template>

    <label class="form-label fs-7">Sector</label>

    <el-select
        v-model="sector"
        filterable
        reserve-keyword
        clearable
        placeholder="Select sector..."
    >
        <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
        >{{ item.label }}
        </el-option>
    </el-select>

</template>

<script setup>
import {ref, watch, onMounted} from 'vue'

// Define props and emits
const props = defineProps({
    fetchUrl: String,
    sector: String,
})

const emits = defineEmits(['update:sector'])

// Define reactive properties using ref
const sector = ref(props.sector || '')

// Watch for changes in formData and emit them
watch(() => sector.value, (newSector) => {
    emits('update:sector', newSector)
})

/**
 * Remote fetch
 * */
const options = ref([])

// Fetch users data
onMounted(async () => {
    if (props.fetchUrl) {
        try {
            const response = await fetch(props.fetchUrl)
            if (response.ok) {
                const data = await response.json()
                options.value = data.map(item => ({
                    value: item.value,
                    label: item.label,
                }))
            } else {
                console.error('Fetch failed:', response.statusText)
            }
        } catch (error) {
            console.error('Fetch error:', error)
        }
    }
})
</script>
