<template>

    <label class="form-label fs-7">Height</label>

    <input
        v-model="height"
        type="text"
        class="form-control form-control-sm"
        placeholder="Enter height..."
    />
</template>

<script setup>
import { ref, watch } from 'vue'

// Define props
const props = defineProps({
    height: String,
})


// Define emits
const emits = defineEmits(['update:height'])


// Define refs
let height = ref(props.height || '')


// Update the local height when the prop changes
watch(() => props.height, (newHeight) => {
    height.value = newHeight
})


// Watch for changes and emit them
watch(() => height.value, (newHeight) => {
    emits('update:height', newHeight)
})
</script>
