<template>
    <FullCalendar ref="fullCalendar" :options="calendarOptions" class="activity-calendar">

        <template v-slot:eventContent='arg'>

            <div class="d-flex fw-bold">

                <Icon
                    v-if="['call', 'task', 'meeting'].includes(arg.event.extendedProps.type)"
                    :type="getEventIconType(arg.event.extendedProps.type)"
                    class="text-white fs-3 me-1"
                />

                <div class="d-flex flex-column">
                    <span>{{ arg.timeText }}</span>
                    <span v-if="arg.event.extendedProps.type === 'microsoft_calendar'" class="fw-bolder">(Microsoft Calendar)</span>
                </div>

            </div>

            <span class="fs-7 fw-semibold text-white">{{ arg.isMirror ? givenEventName : arg.event.title }}</span>

        </template>

    </FullCalendar>

</template>

<script setup>
import { ref, onMounted, watch, computed, reactive, nextTick } from 'vue'
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import moment from 'moment'
import interactionPlugin from '@fullcalendar/interaction'
import { usePage } from '@inertiajs/vue3'
import Icon from '@/Shares/General/Icon.vue'
import axios from 'axios'
import { debounce } from 'lodash'
import momentTimezonePlugin from '@fullcalendar/moment-timezone'


// Define variables
const page = usePage()
const now = moment()


// Define props
const props = defineProps({
    fetchOtherEventsUrl: String,
    additionalParameters: { type: Object, default: {} },
    type: String,
    name: String,
    startAt: Date,
    endAt: Date,
})


// Define refs
const fullCalendar = ref()

const givenEventName = ref(props.name || '')
const givenEventStartAt = ref(props.startAt || '')
const givenEventEndAt = ref(props.endAt || '')

const otherEvents = ref([])


// Define emits
const emits = defineEmits(['update:startAt', 'update:endAt'])


// Define computed
const events = computed(() => {
    if (givenEventStartAt.value && givenEventEndAt.value) {
        return [...otherEvents.value, {
            title: givenEventName.value ?? '',
            start: givenEventStartAt.value,
            end: givenEventEndAt.value,
            givenEvent: true,
            type: props.type
        }]
    }
    return [...otherEvents.value]
})


// Define watchers
watch(() => props.startAt, async (newValue, oldValue) => {

    if (newValue !== oldValue) {
        givenEventStartAt.value = newValue

        if (newValue){

            const newDate = moment(newValue).format('YYYY-MM-DD')
            const newTime = moment(newValue).subtract(2, 'hours').format('HH:mm:ss')
            const calendarApi = fullCalendar.value.getApi()

            if (calendarApi && newDate !== calendarApi.getDate()) {
                calendarApi.gotoDate(newDate)

                await nextTick()

                calendarApi.scrollToTime(newTime)
            }

        }

    }
})
watch(() => props.endAt, (newValue, oldValue) => {
    if (newValue !== oldValue) {
        givenEventEndAt.value = newValue
    }
})
watch(() => props.name, (newValue, oldValue) => {
    if (newValue !== oldValue) {
        givenEventName.value = newValue
    }
})
watch(() => [props.fetchOtherEventsUrl, props.additionalParameters], () => {
    fetchOtherEvents()
})


// Define methods
function fetchOtherEvents() {

    otherEvents.value = []

    if (Object.keys(props.additionalParameters).length === 0) {
        return
    }

    const calendarApi = fullCalendar.value.getApi()
    const currentView = calendarApi.view
    const start = currentView.activeStart.toISOString()
    const end = currentView.activeEnd.toISOString()

    axios.get(
        props.fetchOtherEventsUrl,
        { params: Object.assign({}, { start: start, end: end }, props.additionalParameters) }
    )
        .then(response => {
            const data = response.data

            data.forEach((event) => {

                // Set default parameters
                event.editable = false

                // Switch op basis van het event type
                switch (event.type) {
                    case 'call':
                        event.backgroundColor = '#f1416c'
                        event.borderColor = '#f1416c'
                        break
                    case 'meeting':
                        event.backgroundColor = '#50cd89'
                        event.borderColor = '#50cd89'
                        break
                    case 'task':
                        event.backgroundColor = '#ffc700'
                        event.borderColor = '#ffc700'
                        break
                    case 'microsoft_calendar':
                        event.backgroundColor = '#287dc0'
                        event.borderColor = '#287dc0'
                        break
                }

                otherEvents.value.push(event)

            })

        })
        .catch(error => {
            console.error('Fout bij het ophalen van gegevens', error)
        })

}

const emitUpdateGivenEventDebounced = debounce(() => {

}, 150);


// Define full calendar methods
function handleEventChange(eventResizeInfo) {

    // This function is called when given event is changed

    const event = eventResizeInfo.event

    givenEventStartAt.value = event.start
    givenEventEndAt.value = event.end

    emitUpdateGivenEventDebounced()

}

function handleSelect(selectInfo) {

    // This function is called when new given event is set

    givenEventStartAt.value = selectInfo.start
    givenEventEndAt.value = selectInfo.end

    const calendarApi = fullCalendar.value.getApi()
    calendarApi.unselect()

    emitUpdateGivenEventDebounced()

}

function handleSelectAllow(selectInfo) {

    // This function is called when there needs to be checked if selection is allowed

    const calendarApi = fullCalendar.value.getApi()

    const givenEvent = calendarApi.getEvents().find(item => item.extendedProps && item.extendedProps.givenEvent === true)
    if (givenEvent) {
        givenEvent.remove()
    }

    return true
}

function handleDatesSet(info) {

    // This function is called when changing from day
    // This function is called when changing from day

    const startTime = moment(givenEventStartAt.value).format('HH:mm:ss')
    const endTime = moment(givenEventEndAt.value).format('HH:mm:ss')

    const newStart = moment(info.startStr).format('YYYY-MM-DD') + 'T' + startTime
    const newEnd = moment(info.endStr).subtract(1, 'days').format('YYYY-MM-DD') + 'T' + endTime

    givenEventStartAt.value = newStart
    givenEventEndAt.value = newEnd

    // Fetch other events for the new day
    fetchOtherEvents()

    emitUpdateGivenEventDebounced()

}


function getEventIconType(type) {
    switch (type) {
        case 'call':
            return 'call'
        case'meeting':
            return 'meeting'
        case 'task':
            return 'task'
    }
}

function getEventBackgroundColor() {
    switch (props.type) {
        case 'call':
            return '#f1416c'
        case'meeting':
            return '#50cd89'
        case 'task':
            return '#ffc700'
    }
}

function getEventBorderColor() {
    switch (props.type) {
        case 'call':
            return '#f1416c'
        case'meeting':
            return '#50cd89'
        case 'task':
            return '#ffc700'
    }
}


// Define calendar options
const calendarOptions = ref({
    plugins: [
        dayGridPlugin,
        timeGridPlugin,
        listPlugin,
        interactionPlugin,
        momentTimezonePlugin
    ],
    timeZone: page.props.auth.user.time_zone,
    headerToolbar: {
        left: '',
        center: 'title',
        right: 'today prev,next',
    },

    height: '100%',
    contentHeight: '100%',
    aspectRatio: 3,
    eventMinHeight: 26,
    snapDuration: '00:05',
    slotLabelFormat: {hour: 'numeric', minute: '2-digit', hour12: false},
    eventTimeFormat: { hour: 'numeric', minute: '2-digit', hour12: false},

    nowIndicator: true,
    now: new Date(),
    scrollTime: now.subtract(3, 'h').format('HH:mm:ss'),
    scrollTimeReset: false,

    initialView: 'timeGridDay',
    initialDate: now.format('YYYY-MM-DD'),

    events: events,
    eventClassNames: ['overflow-hidden'],

    editable: true,
    dayMaxEvents: true,
    navLinks: true,
    selectable: true,
    selectMirror: true,
    weekends: true,

    eventBackgroundColor: getEventBackgroundColor(),
    eventBorderColor: getEventBorderColor(),

    eventChange: handleEventChange,
    datesSet: handleDatesSet,
    select: handleSelect,
    selectAllow: handleSelectAllow,

})


// Define on mounted
onMounted(() => {

    setTimeout(function () {

        window.dispatchEvent(new Event('resize'))

        fetchOtherEvents()

    }, 1)

})

</script>

<style>
.activity-calendar .fc-toolbar-title {
    font-size: 14px !important;
}

.activity-calendar .fc-button {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.activity-calendar .fc-header-toolbar {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
}

.activity-calendar .fc-header-toolbar .fc-toolbar-chunk:nth-child(1) {
    display: none;
}

.activity-calendar .fc-scrollgrid {
    border-radius: 0 !important;
}

.activity-calendar .fc-day-today {
    background: none !important;
}
</style>

