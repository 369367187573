<template>

    <Modal :size="modalSize" title="Add Meeting">

        <div class="modal-body py-0 px-0">

            <div class="row g-0">

                <form
                    @submit.prevent="submit"
                    class="form py-4"
                    :class="{ 'col-7 border border-top-0 border-bottom-0 border-left-0 border-right-1 border-gray-300 px-0': showStartTimeAndDurationInputs }"
                >

                    <div class="px-6">

                        <div v-if="!modalStore.props.hideCompany || !modalStore.props.hideContact" class="row mb-3">

                            <div class="col-md-12">

                                <Customer

                                    v-if="
                                        $hasAccessToSubscriptionFeatures(['companies', 'contacts']) &&
                                        $hasPermissions(['companies_index', 'contacts_index'])
                                    "

                                    fetch-url-companies="/meetings/create/get/companies"
                                    fetch-url-contacts="/invoices/create/get/contacts"

                                    :company="form.company"
                                    :contact="form.contact"

                                    @update:customerType="customerType = $event"
                                    @update:customerName="customerName = $event"

                                    @update:company="form.company = $event"
                                    @update:companyIsNew="companyIsNew = $event"

                                    @update:contact="form.contact = $event"
                                    @update:contactIsNew="contactIsNew = $event"

                                />
                                <div v-if="form.errors.company" v-text="form.errors.company" class="text-danger small mt-1" />
                                <div v-if="form.errors.contact" v-text="form.errors.contact" class="text-danger small mt-1" />

                            </div>

                        </div>

                        <el-divider v-if="!modalStore.props.hideCompany || !modalStore.props.hideContact" class="my-3"/>

                        <div v-if="!modalStore.props.hideName" class="row mb-3">

                            <div class="col-md-12">

                                <Name :name="form.name" @update:name="form.name = $event"/>
                                <div v-if="form.errors.name" v-text="form.errors.name" class="text-danger small mt-1" />
                                <div class="d-flex flex-row-reverse">
                                    <span v-if="!showStartTimeAndDurationInputs" class="text-primary fs-8 cursor-pointer" @click="toggleSchedule">Schedule meeting</span>
                                </div>

                            </div>

                        </div>

                        <div v-if="showStartTimeAndDurationInputs && !modalStore.props.hideStartAt && !modalStore.props.hideEndAt" class="row">

                            <div class="col-md-12">

                                <StartTimeAndDurationInputs
                                    :startAt="form.startAt"
                                    :endAt="form.endAt"
                                    @update:startAt="startAt => updateFormTimes(startAt, form.endAt)"
                                    @update:endAt="endAt => updateFormTimes(form.startAt, endAt)"
                                />
                                <div v-if="form.errors.startAt" v-text="form.errors.startAt" class="text-danger small mt-1" />
                                <div v-if="form.errors.endAt" v-text="form.errors.endAt" class="text-danger small mt-1" />
                                <div class="d-flex flex-row-reverse">
                                    <span class="text-end text-primary fs-8 cursor-pointer" @click="toggleSchedule">Do not schedule meeting</span>
                                </div>

                            </div>

                        </div>

                        <el-divider class="my-3"/>

                        <div v-if="showGuests === false || showLocation === false || showExtraUsers === false" class="row mb-3">

                            <div class="col-md-12">

                                <div class="d-flex align-items-center">

                                    <i class="ki-duotone ki-plus fs-1 me-6"></i>

                                    <div>
                                        <span class="me-1">Add</span>
                                        <span v-if="showGuests === false" class="text-primary fs-6 fw-semibold cursor-pointer" @click="showGuests = true">guests</span>
                                        <span v-if="showGuests === false && (showLocation === false || showExtraUsers === false)" class="me-2">,</span>
                                        <span v-if="showLocation === false" class="text-primary fs-6 fw-semibold cursor-pointer" @click="showLocation = true">location</span>
                                        <span v-if="showLocation === false && showExtraUsers === false" class="me-2">,</span>
                                        <span v-if="showExtraUsers === false" class="text-primary fs-6 fw-semibold cursor-pointer" @click="showExtraUsers = true">extra users</span>
                                    </div>

                                </div>

                            </div>

                        </div>

                        <div v-if="showGuests && !modalStore.props.hideGuests" class="row mb-3">

                            <div class="col-md-12">

                                <Guests
                                    fetch-url="/meetings/create/get/guests"
                                    :guests="form.guests"
                                    @update:guests="form.guests = $event"
                                />
                                <div v-if="form.errors.guests" v-text="form.errors.guests" class="text-danger small mt-1" />

                            </div>

                        </div>

                        <div v-if="(showLocation && !modalStore.props.hideLocation) || (showExtraUsers && !modalStore.props.hideExtraUsers)" class="row mb-3">

                            <div v-if="showLocation && !modalStore.props.hideLocation" class="col-md-6">

                                <Location
                                    :location="form.location"
                                    :location-type="form.locationType"
                                    :company="form.company"
                                    :contact="form.contact"
                                    :fetchUrl="`/meetings/create/get/locations`"
                                    @update:location="form.location = $event"
                                    @update:locationType="form.locationType = $event"
                                />
                                <div v-if="form.errors.location" v-text="form.errors.location" class="text-danger small mt-1" />

                            </div>

                            <div v-if="showExtraUsers && !modalStore.props.hideExtraUsers" class="col-md-6">

                                <ExtraUsers
                                    fetch-url="/meetings/create/get/users"
                                    :extra-users="form.extraUsers"
                                    :assignedTo="form.assignedTo"
                                    @update:extraUsers="form.extraUsers = $event"
                                />
                                <div v-if="form.errors.extraUsers" v-text="form.errors.extraUsers" class="text-danger small mt-1" />

                            </div>

                        </div>

                        <div v-if="!modalStore.props.hideDescription" class="row">

                            <div class="col-md-12">

                                <Description
                                    :description="form.description"
                                    @update:description="form.description = $event"
                                />
                                <div v-if="form.errors.description" v-text="form.errors.description" class="text-danger small mt-1" />

                            </div>

                        </div>

                        <el-divider class="my-3"/>

                        <div v-if="!modalStore.props.hideAssignedTo || !modalStore.props.hideTags" class="row" :class="{ 'mb-3' : form.assignedTo === currentUser.id }">

                            <div v-if="!modalStore.props.hideAssignedTo" class="col-md-6">

                                <AssignedTo
                                    fetch-url="/meetings/create/get/users"
                                    :assigned-to="form.assignedTo"
                                    @update:assignedTo="form.assignedTo = $event"
                                />
                                <div v-if="form.errors.assignedTo" v-text="form.errors.assignedTo" class="text-danger small mt-1" />

                            </div>

                            <div v-if="!modalStore.props.hideTags" class="col-md-6">

                                <Tags
                                    fetch-url="/meetings/create/get/tags"
                                    :tags="form.tags"
                                    @update:tags="form.tags = $event"
                                />
                                <div v-if="form.errors.tags" v-text="form.errors.tags" class="text-danger small mt-1"/>

                            </div>

                        </div>

                        <div v-if="!modalStore.props.hideDeal || !modalStore.props.hideProject" class="row mb-3">

                            <div v-if="$hasAccessToSubscriptionFeature('deals') && $hasPermission('deals_index') && !modalStore.props.hideDeal" class="col-md-6">

                                <Deal

                                    fetch-url="/meetings/create/get/deals"

                                    :company="form.company"
                                    :companyIsNew="companyIsNew"

                                    :contact="form.contact"
                                    :contactIsNew="contactIsNew"

                                    :project="form.project"
                                    :projectIsNew="projectIsNew"

                                    :deal="form.deal"

                                    @update:deal="form.deal = $event"
                                    @update:dealIsNew="dealIsNew = $event"

                                    @update:company="form.company = $event"
                                    @update:contact="form.contact = $event"

                                />
                                <div v-if="form.errors.deal" v-text="form.errors.deal" class="text-danger small mt-1" />

                            </div>

                            <div v-if="$hasAccessToSubscriptionFeature('projects') && $hasPermission('projects_index') && !modalStore.props.hideProject" class="col-md-6">

                                <Project

                                    v-if="$hasAccessToSubscriptionFeature('projects') && $hasPermission('projects_index')"

                                    fetch-url="/meetings/create/get/projects"

                                    :company="form.company"
                                    :companyIsNew="companyIsNew"

                                    :contact="form.contact"
                                    :contactIsNew="contactIsNew"

                                    :deal="form.deal"
                                    :dealIsNew="dealIsNew"

                                    :project="form.project"

                                    @update:project="form.project = $event"
                                    @update:projectIsNew="projectIsNew = $event"

                                    @update:company="form.company = $event"
                                    @update:contact="form.contact = $event"
                                    @update:deal="form.deal = $event"
                                />
                                <div v-if="form.errors.project" v-text="form.errors.project" class="text-danger small mt-1" />

                            </div>

                            <CustomFields
                                :fetchUrl="`/meetings/create/get/custom-fields`"
                                :customFields="form.customFields"
                                :errors="form.errors"
                                @update:customFields="form.customFields = $event"
                            />

                        </div>

                    </div>

                    <el-divider class="mb-4"></el-divider>

                    <div class="d-flex justify-content-end px-8 py-0">

                        <button
                            type="submit"
                            class="btn btn-sm btn-primary"
                            :disabled="form.processing"
                            :data-kt-indicator="form.processing ? 'on' : 'off'"
                        >

                            <span class="indicator-label">
                                <i class="ki-duotone ki-plus fs-8 text-white"></i> Create meeting
                            </span>

                            <span class="indicator-progress">
                                Please wait... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>

                        </button>

                    </div>

                </form>

                <div v-if="showStartTimeAndDurationInputs" class="col-5 pt-0 ps-0 pb-0 pe-3">

                    <ActivityCalendar
                        type="meeting"
                        fetchOtherEventsUrl="/meetings/create/get/events"
                        :additionalParameters="activityCalendarAdditionalParameters"
                        :name="form.name"
                        :startAt="form.startAt"
                        :endAt="form.endAt"
                        @update:startAt="startAt => updateFormTimes(startAt, form.endAt)"
                        @update:endAt="endAt => updateFormTimes(form.startAt, endAt)"
                    />

                </div>

            </div>

        </div>

    </Modal>

</template>

<script setup>
import Modal from '@/Shares/General/Modal.vue'
import { useForm, usePage } from '@inertiajs/vue3'
import { computed, onMounted, ref, watch } from 'vue'
import Name from '@/Shares/App/Modules/Meetings/Modals/Partials/Form/Name.vue'
import Description from '@/Shares/App/Modules/Meetings/Modals/Partials/Form/Description.vue'
import StartTimeAndDurationInputs from '@/Shares/General/Form/StartTimeAndDurationInputs.vue'
import Customer from '@/Shares/General/Form/Customer.vue'
import AssignedTo from '@/Shares/General/Form/AssignedTo.vue'
import Deal from '@/Shares/General/Form/Deal.vue'
import Project from '@/Shares/General/Form/Project.vue'
import ExtraUsers from '@/Shares/App/Modules/Meetings/Modals/Partials/Form/ExtraUsers.vue'
import Guests from '@/Shares/App/Modules/Meetings/Modals/Partials/Form/Guests.vue'
import Location from '@/Shares/App/Modules/Meetings/Modals/Partials/Form/Location.vue'
import { useModalStore } from '@/Stores/modalStore.js'
import Tags from '@/Shares/General/Form/Tags.vue'
import ActivityCalendar from '@/Shares/General/Form/ActivityCalendar.vue'
import moment from 'moment'
import axios from 'axios'
import CustomFields from '@/Shares/General/Form/CustomFields.vue'


// Define variables
const modalStore = useModalStore()
const page = usePage();
const currentUser = page.props.auth.user

const roundUpToNext5Min = (date) => {
    if (date.minute() % 5 !== 0) {
        const minutesToAdd = 5 - (date.minute() % 5);
        date.add(minutesToAdd, 'minutes');
    }
    return date;
};


// Define form
const form = useForm({
    name: '',
    startAt: roundUpToNext5Min(moment()),
    endAt: roundUpToNext5Min(moment()).add(30, 'minutes'),
    guests: [],
    location: '',
    locationType: '',
    extraUsers: [],
    description: '',
    assignedTo: '',
    company: '',
    contact: '',
    deal: '',
    project: '',
    tags: [],
    customFields: {},
})


// Define refs
let showStartTimeAndDurationInputs = ref(true)

let showGuests = ref(false)
let showLocation = ref(false)
let showExtraUsers = ref(false)

let customerType = ref('')
let customerName = ref('')

let companyIsNew = ref(false);
let contactIsNew = ref(false);
let dealIsNew = ref(false);
let projectIsNew = ref(false);


// Define watchers
watch(() => customerName.value, () => {
    if (!form.name && customerName.value) {
        form.name = 'Meeting ' + customerName.value
    }
})


// Define computed
const modalSize = computed(() => {
    return showStartTimeAndDurationInputs.value ? 'lg' : 'md'
})

const activityCalendarAdditionalParameters = computed(() => {
    if (!form.assignedTo) {
        return {}
    }

    return {
        user: form.assignedTo,
    }
})


// Define methods
function toggleSchedule(){

    if (!form.startAt || form.endAt){
        form.startAt = roundUpToNext5Min(moment())
        form.endAt = roundUpToNext5Min(moment()).add(30, 'minutes')
    }

    showStartTimeAndDurationInputs.value = !showStartTimeAndDurationInputs.value
}

const updateFormTimes = (startAt, endAt) => {
    if (form.startAt !== startAt) {
        form.startAt = startAt
    }
    if (form.endAt !== endAt) {
        form.endAt = endAt
    }
}

function submit() {

    // Check if it needs to be scheduled
    if (!showStartTimeAndDurationInputs.value) {
        form.startAt = null
        form.endAt = null
    }

    form.post(modalStore.props.storeUrl, {
        preserveState: (page) => Object.keys(page.props.errors).length > 0,
        onSuccess: () => modalStore.closeModal(),
    })
}

async function fetchDefaultValues() {

    // Determine if it is necessary to retrieve the default values
    const shouldFetchDefaultValues = !form.name || !form.assignedTo || !form.location || !form.locationType || !form.tags || !form.description;

    // Fetch default values only if necessary
    if (shouldFetchDefaultValues) {
        await axios.get('/meetings/create/get/default-values').then(response => {
            const data = response.data

            // Only fill in the values if they are not already filled in
            form.name = form.name || data.name
            form.assignedTo = form.assignedTo || data.assigned_to
            form.location = form.location ||  data.location
            form.locationType = form.locationType || data.location_type
            form.tags = form.tags || data.tags
            form.description = form.description || data.description

            // TODO Duration

        }).catch(error => {
            console.error('Fout bij het ophalen van gegevens', error)
        })
    }

}


// Define on mounted
onMounted(async () => {

    // Check if any form value is predefined
    Object.keys(modalStore.props).forEach(key => {
        if (form.hasOwnProperty(key)) {
            form[key] = modalStore.props[key]
        }
    })

    if (modalStore.props.customerName) {
        customerName.value = modalStore.props.customerName
    }

    if (modalStore.props.customerType) {
        customerType.value = modalStore.props.customerType
    }

    if (!form.startAt || !form.endAt) {
        showStartTimeAndDurationInputs.value = false
    }

    // Fetch default values
    await fetchDefaultValues()

})

</script>
