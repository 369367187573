<script setup>

</script>

<template>

    <div class="w-lg-500px d-flex flex-stack px-10 mx-auto">

        <div class="d-flex fw-semibold text-primary fs-base gap-5">
            <a href="https://virtupply.com/prijs" target="_blank">Plans</a>
            <a href="https://virtupply.com/contact" target="_blank">Contact Us</a>
        </div>

    </div>

</template>

<style scoped>

</style>
