<template>
    <a href="#" class="d-flex align-items-center fs-7 text-primary" @click.prevent="addTelephone">
        <i class="ki-duotone ki-plus fs-6 text-primary"></i>
        Add Telephone
    </a>
</template>

<script setup>

// Define emits
const emits = defineEmits(['addTelephone'])

// Define methods
function addTelephone() {
    emits('addTelephone')
}

</script>

