<template>

    <Modal size="lg" title="Add Products">

        <div class="modal-body py-0 px-0">

            <form
                @submit.prevent="submit"
                class="row g-0 form"
            >

                <div class="col-7 border border-top-0 border-bottom-0 border-left-0 border-right-1 border-gray-300 py-4 px-0">

                    <div class="px-6">

                        <div v-if="!modalStore.props.hideName" class="row mb-3">

                            <div class="col-md-12">

                                <Name :name="form.name" @update:name="form.name = $event"/>
                                <div v-if="form.errors.name" v-text="form.errors.name" class="text-danger small mt-1"/>

                            </div>

                        </div>

                        <div v-if="!modalStore.props.hideDescription" class="row mb-3">

                            <div class="col-md-12">

                                <Description :description="form.description" @update:description="form.description = $event"/>
                                <div v-if="form.errors.description" v-text="form.errors.description" class="text-danger small mt-1"/>

                            </div>

                        </div>

                        <div v-if="!modalStore.props.hideSellingPriceAmount" class="row mb-3">

                            <div class="col-md-12">

                                <label class="form-label fs-7">Selling price</label>

                                <el-input
                                    v-model="form.sellingPriceAmount"
                                    placeholder="Enter amount..."
                                    class="input-with-select"
                                >
                                    <template v-if="!modalStore.props.SellingPriceCurrency" #prepend>
                                        <SellingPriceCurrency
                                            fetchUrl="/products/create/get/currencies"
                                            :sellingPriceCurrency="form.sellingPriceCurrency"
                                            @update:sellingPriceCurrency="form.sellingPriceCurrency = $event"
                                        />
                                    </template>
                                    <template v-if="!modalStore.props.SellingPriceUnit" #append>
                                        <SellingPriceUnit
                                            fetchUrl="/products/create/get/units"
                                            :sellingPriceUnit="form.sellingPriceUnit"
                                            @update:sellingPriceUnit="form.sellingPriceUnit = $event"
                                        />
                                    </template>
                                </el-input>
                                <div v-if="form.errors.sellingPriceCurrency" v-text="form.errors.sellingPriceCurrency" class="text-danger small mt-1"/>
                                <div v-if="form.errors.sellingPriceAmount" v-text="form.errors.sellingPriceAmount" class="text-danger small mt-1"/>
                                <div v-if="form.errors.sellingPriceUnit" v-text="form.errors.sellingPriceUnit" class="text-danger small mt-1"/>

                            </div>

                        </div>

                        <div v-if="!modalStore.props.hideTags || !modalStore.props.hideCategories" class="row mb-3">

                            <div v-if="!modalStore.props.hideTags" class="col-md-6">

                                <Tags
                                    fetchUrl="/products/create/get/tags"
                                    :tags="form.tags"
                                    @update:tags="form.tags = $event"
                                />
                                <div v-if="form.errors.tags" v-text="form.errors.tags" class="text-danger small mt-1"/>

                            </div>

                            <div v-if="!modalStore.props.hideCategories" class="col-md-6">

                                <Categories
                                    fetchUrl="/products/create/get/categories"
                                    :categories="form.categories"
                                    @update:categories="form.categories = $event"
                                />
                                <div v-if="form.errors.categories" v-text="form.errors.categories" class="text-danger small mt-1"/>

                            </div>

                        </div>

                        <div v-if="!modalStore.props.hideVat || !modalStore.props.hideImage" class="row mb-3">

                            <div v-if="!modalStore.props.hideVat" class="col-md-6">

                                <Vat
                                    fetchUrl="/products/create/get/vats"
                                    :vat="form.vat"
                                    @update:vat="form.vat = $event"
                                />
                                <div v-if="form.errors.vat" v-text="form.errors.vat" class="text-danger small mt-1"/>

                            </div>

                            <div v-if="!modalStore.props.hideImage" class="col-md-6">

                                <Image :image="form.image" @update:image="form.image = $event"/>
                                <div v-if="form.errors.image" v-text="form.errors.image" class="text-danger small mt-1"/>

                            </div>

                        </div>

                        <el-collapse-transition>

                            <div v-if="showMoreDetails">

                                <el-divider border-style="dashed" content-position="left">Inventory</el-divider>

                                <div v-if="!modalStore.props.hideSku" class="row mb-3">

                                    <div class="col-md-12">

                                        <Sku :sku="form.sku" @update:sku="form.sku = $event"/>
                                        <div v-if="form.errors.sku" v-text="form.errors.sku" class="text-danger small mt-1"/>

                                    </div>

                                </div>

                                <div v-if="!form.manageStock" class="row mb-3">

                                    <div class="col-md-12">

                                        <ManageStock :manageStock="form.manageStock" @update:manageStock="form.manageStock = $event"/>
                                        <div v-if="form.errors.manageStock" v-text="form.errors.manageStock" class="text-danger small mt-1"/>

                                    </div>

                                </div>

                                <div v-if="form.manageStock">

                                    <div v-if="!modalStore.props.hideStock || !modalStore.props.hideBackordersAllowed" class="row">

                                        <div v-if="!modalStore.props.hideStock" class="col-md-6">

                                            <Stock :stock="form.stock" @update:stock="form.stock = $event"/>
                                            <div v-if="form.errors.stock" v-text="form.errors.stock" class="text-danger small mt-1"/>

                                        </div>

                                        <div v-if="!modalStore.props.hideBackordersAllowed" class="col-md-6">

                                            <BackordersAllowed :backorders-allowed="form.backordersAllowed" @update:backordersAllowed="form.backordersAllowed = $event"/>
                                            <div v-if="form.errors.backordersAllowed" v-text="form.errors.backordersAllowed" class="text-danger small mt-1"/>

                                        </div>

                                    </div>

                                    <div class="text-end">
                                        <span class="text-primary fs-8 cursor-pointer" @click="form.manageStock = !form.manageStock">Do not manage stock</span>
                                    </div>

                                </div>

                                <el-divider border-style="dashed" content-position="left">Shipping</el-divider>

                                <div v-if="!modalStore.props.hideWeight" class="row mb-3">

                                    <div class="col-md-12">

                                        <Weight :weight="form.weight" @update:weight="form.weight = $event"/>
                                        <div v-if="form.errors.weight" v-text="form.errors.weight" class="text-danger small mt-1"/>

                                    </div>

                                </div>

                                <div v-if="!modalStore.props.hideWidth || !modalStore.props.hideHeight ||! modalStore.props.hideLength" class="row mb-3">

                                    <div v-if="!modalStore.props.hideWidth" class="col-md-4">

                                        <Width :width="form.width" @update:width="form.width = $event"/>
                                        <div v-if="form.errors.width" v-text="form.errors.width" class="text-danger small mt-1"/>

                                    </div>

                                    <div v-if="!modalStore.props.hideHeight" class="col-md-4">

                                        <Height :height="form.height" @update:height="form.height = $event"/>
                                        <div v-if="form.errors.height" v-text="form.errors.height" class="text-danger small mt-1"/>

                                    </div>

                                    <div v-if="!modalStore.props.hideLength" class="col-md-4">

                                        <Length :length="form.length" @update:length="form.length = $event"/>
                                        <div v-if="form.errors.length" v-text="form.errors.length" class="text-danger small mt-1"/>

                                    </div>

                                </div>

                            </div>

                            <CustomFields
                                :fetchUrl="`/products/create/get/custom-fields`"
                                :customFields="form.customFields"
                                :errors="form.errors"
                                @update:customFields="form.customFields = $event"
                            />

                        </el-collapse-transition>

                    </div>

                    <el-divider class="mb-4"></el-divider>

                    <div
                        class="d-flex align-items-center px-8 py-0 justify-content-between"
                    >

                        <div
                            v-if="showMoreDetails === false"
                            class="text-primary cursor-pointer"
                            @click="showMoreDetails = true"
                        >
                            More details <i class="ki-duotone ki-down text-primary"></i>
                        </div>

                        <div
                            v-if="showMoreDetails === true"
                            class="text-primary cursor-pointer"
                            @click="showMoreDetails = false"
                        >
                            Less details <i class="ki-duotone ki-up text-primary"></i>
                        </div>

                        <button
                            type="submit"
                            class="btn btn-sm btn-primary"
                            :disabled="form.processing"
                            :data-kt-indicator="form.processing ? 'on' : 'off'"
                        >

                            <span class="indicator-label">
                                <i class="ki-duotone ki-plus fs-6 text-white"></i> Create product
                            </span>

                            <span class="indicator-progress">
                                Please wait... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>

                        </button>

                    </div>

                </div>

                <div class="col-5 py-0 px-6">

                    <el-divider border-style="dashed" content-position="left">Files</el-divider>

                    <el-upload
                        v-model:file-list="form.files"
                        drag
                        :auto-upload="false"
                        multiple
                    >
                        <el-icon class="el-icon--upload">
                            <upload-filled/>
                        </el-icon>
                        <div class="el-upload__text">
                            Drop file here or <em>click to upload</em>
                        </div>
                        <template #tip>
                            <div class="el-upload__tip">
                                jpg/png files with a size less than 500kb
                            </div>
                        </template>
                    </el-upload>
                    <div v-if="form.errors.files" v-text="form.errors.files" class="text-danger small mt-1"/>

                </div>

            </form>

        </div>

    </Modal>

</template>

<script setup>
import Modal from '@/Shares/General/Modal.vue'
import { useForm } from '@inertiajs/vue3'
import { ref, onMounted } from 'vue'
import Name from '@/Shares/App/Modules/Products/Modals/Partials/Form/Name.vue'
import Description from '@/Shares/App/Modules/Products/Modals/Partials/Form/Description.vue'
import Vat from '@/Shares/App/Modules/Products/Modals/Partials/Form/Vat.vue'
import Sku from '@/Shares/App/Modules/Products/Modals/Partials/Form/Sku.vue'
import Stock from '@/Shares/App/Modules/Products/Modals/Partials/Form/Stock.vue'
import BackordersAllowed from '@/Shares/App/Modules/Products/Modals/Partials/Form/BackordersAllowed.vue'
import Weight from '@/Shares/App/Modules/Products/Modals/Partials/Form/Weight.vue'
import Width from '@/Shares/App/Modules/Products/Modals/Partials/Form/Width.vue'
import Height from '@/Shares/App/Modules/Products/Modals/Partials/Form/Height.vue'
import Length from '@/Shares/App/Modules/Products/Modals/Partials/Form/Length.vue'
import SellingPriceCurrency from '@/Shares/App/Modules/Products/Modals/Partials/Form/SellingPriceCurrency.vue'
import SellingPriceUnit from '@/Shares/App/Modules/Products/Modals/Partials/Form/SellingPriceUnit.vue'
import { useModalStore } from '@/Stores/modalStore.js'
import Tags from '@/Shares/General/Form/Tags.vue'
import Categories from '@/Shares/App/Modules/Products/Modals/Partials/Form/Categories.vue'
import { UploadFilled } from '@element-plus/icons-vue'
import Image from '@/Shares/App/Modules/Products/Modals/Partials/Form/Image.vue'
import ManageStock from '@/Shares/App/Modules/Products/Modals/Partials/Form/ManageStock.vue'
import axios from 'axios'
import CustomFields from '@/Shares/General/Form/CustomFields.vue'


// Define variables
const modalStore = useModalStore()


// Define form
const form = useForm({
    name: '',
    description: '',
    sellingPriceAmount: '',
    sellingPriceCurrency: '',
    sellingPriceUnit: '',
    image: '',
    vat: '',
    sku: '',
    manageStock: false,
    stock: '',
    backordersAllowed: false,
    weight: '',
    width: '',
    height: '',
    length: '',
    categories: [],
    tags: [],
    files: [],
    customFields: {},
})


// Define refs
const uploadRef = ref([])
let showMoreDetails = ref(false)


// Function to handle form submission
function submit() {
    form.post(modalStore.props.storeUrl, {
        preserveState: (page) => Object.keys(page.props.errors).length > 0,
        onSuccess: () => modalStore.closeModal(),
    })
}

async function fetchDefaultValues() {

    // Determine if it is necessary to retrieve the default values
    const shouldFetchDefaultValues = !form.name || !form.description || !form.vat || !form.sellingPriceCurrency || !form.sellingPriceUnit || !form.manageStock || !form.backordersAllowed || !form.tags || !form.categories;

    // Fetch default values only if necessary
    if (shouldFetchDefaultValues) {
        await axios.get('/products/create/get/default-values').then(response => {
            const data = response.data

            // Only fill in the values if they are not already filled in
            form.name = form.name || data.name
            form.description = form.description || data.description
            form.vat = form.vat || data.vat
            form.sellingPriceCurrency = form.sellingPriceCurrency || data.selling_price_currency
            form.sellingPriceUnit = form.sellingPriceUnit || data.selling_price_unit
            form.manageStock = form.manageStock || data.manage_stock
            form.backordersAllowed = form.backordersAllowed || data.backorders_allowed
            form.tags = form.tags || data.tags
            form.categories = form.categories || data.categories

        }).catch(error => {
            console.error('Fout bij het ophalen van gegevens', error)
        })
    }

}

// Define on mounted
onMounted(async () => {

    // Check if any form value is predefined
    Object.keys(modalStore.props).forEach(key => {
        if (form.hasOwnProperty(key)) {
            form[key] = modalStore.props[key]
        }
    })

    // Fetch default values
    await fetchDefaultValues()

})

</script>
