<template>

    <label class="form-label fs-7">Document template</label>

    <el-select
        v-model="documentTemplate"
        filterable
        reserve-keyword
        placeholder="Select template..."
        @change="updateDocumentTemplate"
    >
        <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
        >{{ item.label }}
        </el-option>
    </el-select>

</template>

<script setup>
import { onMounted, ref, watch } from 'vue'
import axios from 'axios'


// Define props
const props = defineProps({
    fetchUrl: String,
    type: String,
    documentTemplate: String,
})


// Define emits
const emits = defineEmits(['update:documentTemplate'])


// Define refs
const documentTemplate = ref(props.documentTemplate || '')
const options = ref([])


// Define watchers
watch(() => props.documentTemplate, (newDocumentTemplate) => {
    documentTemplate.value = newDocumentTemplate
})

watch(() => [props.type, props.fetchUrl], () => {
    fetchData()
})


// Define methods
async function fetchData() {

    if (props.fetchUrl) {

        axios.get(props.fetchUrl, { params: { type: props.type } }).then(response => {
            const data = response.data

            options.value = data.map(item => ({
                value: item.id,
                label: item.name,
                status: item.status
            }))

            const foundedItem = data.some(item => item.id === documentTemplate.value);
            if (!foundedItem) {
                documentTemplate.value = '';
            }
        }).catch(error => {
            console.error('Fout bij het ophalen van gegevens', error)
        })

    }
}

const updateDocumentTemplate = () => {
    emits('update:documentTemplate', documentTemplate.value)
}


// Define on mounted
onMounted(async () => {
    await fetchData()
})
</script>
