<template>

    <label class="form-label fs-7 required">Start at</label>

    <el-date-picker
        v-model="startAt"
        type="date"
        placeholder="Select a date"
        class="me-2 w-100 hide-icon"
        format="DD MMM. YYYY"
        value-format="YYYY-MM-DD"
        :clearable="false"
        :disabled-date="datePickerOptions.disabledDate"
    />

</template>
<script setup>
import { ref, watch, reactive } from 'vue'


// Define props
const props = defineProps({
    startAt: [String, Date],
    endAt: [String, Date],
})

// Define emits
const emits = defineEmits(['update:startAt'])


// Define refs
let startAt = ref(formatDate(new Date(props.startAt)))
let endAt = ref(props.endAt || '')
const datePickerOptions = reactive({
    disabledDate(date) {
        return date > new Date(endAt.value);
    }
});

// Define watchers
watch(() => props.startAt, (newStartAt) => {
    startAt.value = newStartAt
})
watch(() => props.endAt, (newEndAt) => {
    endAt.value = newEndAt
})

watch(() => startAt.value, (newStartAt) => {
    emits('update:startAt', newStartAt)
})

function formatDate(value) {
    const year = value.getFullYear().toString()
    const month = (value.getMonth() + 1).toString().padStart(2, '0') // Maanden zijn 0-geïndexeerd
    const day = value.getDate().toString().padStart(2, '0')
    return `${year}-${month}-${day}`
}

</script>
