<template>

    <el-select
        v-model="product"
        ref="productSelectRef"
        filterable
        :reserve-keyword="false"
        default-first-option
        placeholder="Search for product..."

        remote
        :remote-method="remoteMethod"
        :loading="loading"
        loading-text="Loading products..."
        no-data-text="No products found."

        @focus="updateBadge"
        @blur="updateBadge"
        @visible-change="updateBadge"
        @change="updateProduct"

        @input="searchText = $event.target.value"
    >

        <el-option
            v-for="item in productOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
        >
            {{ item.label }} <span v-if="item.isNew" class="new-badge ms-2 badge badge-sm badge-primary">New</span>
        </el-option>

        <template #footer v-if="searchText" class="p-0">
            <a class="btn btn-sm text-dark text-hover-primary m-0 p-0 d-flex align-items-center" @click="addAndSelectSearchText">
                <i class="ki-solid ki-plus me-0 text-dark"/>
                Add <span class="fw-bolder mx-1">'{{ searchText }}'</span>as new product
            </a>
        </template>

    </el-select>

</template>

<script setup>
import { ref, watch, onMounted, nextTick } from 'vue'


// Define props
const props = defineProps({
    product: String,
    fetchUrl: String,
})


// Define emits
const emits = defineEmits([
    'update:product',
])


// Define refs
const product = ref(props.product || '')
const productIsNew = ref(false);

const searchText = ref('')
const loading = ref(false)

const productSelectRef = ref()
const productOptions = ref([])


// Define methods
const fetchProducts = async (query) => {
    try {
        const response = await fetch(`${props.fetchUrl}?${query}`)
        if (!response.ok) throw new Error('Network response was not ok')
        const data = await response.json()

        // Map products
        let products = [];
        if (Array.isArray(data)){
            products = data.map(item => ({
                value: item.id,
                label: item.name,
                description: item.description,
                sellingPriceAmount: item.selling_price_amount,
                sellingPriceCurrency: item.selling_price_currency,
                sellingPriceUnit: item.selling_price_unit,
                manageStock: item.manage_stock,
                stock: item.stock,
                backordersAllowed: item.backorders_allowed,
                vat: item.vat,
                isNew: false,
            }))
        } else {
            products = [{
                value: data.id,
                label: data.name,
                description: data.description,
                sellingPriceAmount: data.selling_price_amount,
                sellingPriceCurrency: data.selling_price_currency,
                sellingPriceUnit: data.selling_price_unit,
                manageStock: data.manage_stock,
                stock: data.stock,
                backordersAllowed: data.backorders_allowed,
                vat: data.vat,
                isNew: false,
            }]
        }

        return products
    } catch (error) {
        console.error('Error fetching products:', error)
        return []
    }
}

const remoteMethod = async (search) => {
    searchText.value = search
    if (search) {
        loading.value = true
        productOptions.value = await fetchProducts(`search=${search}`)
        loading.value = false
    }
}

const addAndSelectSearchText = () => {

    // Prepare new company
    const newProduct = searchText.value

    // Update refs
    productIsNew.value = true
    product.value = newProduct
    searchText.value = null

    // Blur
    nextTick(() => {
        productSelectRef.value.blur()
    })

    // Set isNew property to true for the new product
    productOptions.value.push({
        value: newProduct,
        label: newProduct,
        isNew: true,
    })

    // update badge
    updateBadge()

    // Emit changes
    emits('update:product', {
        'id' : product.value,
        'isNew': productIsNew.value,
        'name' : product.value,
        'description': null,
        'sellingPriceAmount': null,
        'sellingPriceCurrency': null,
        'sellingPriceUnit': null,
        'manageStock': null,
        'stock': null,
        'backordersAllowed': null,
        'vat': null,
    })
}

const updateProduct = (value) => {

    // Filter out new
    productOptions.value = productOptions.value.filter(productOption => !productOption.isNew);

    // Find selected option
    const selectedOption = productOptions.value.find(productOption => productOption.value === product.value)

    // Update refs
    searchText.value = null
    product.value = value
    productIsNew.value = selectedOption?.isNew;

    // Emits
    if (value){
        emits('update:product', {
            'id' : value,
            'isNew': productIsNew.value,
            'name': selectedOption?.label,
            'description': selectedOption?.description,
            'sellingPriceAmount': selectedOption?.sellingPriceAmount,
            'sellingPriceCurrency': selectedOption?.sellingPriceCurrency,
            'sellingPriceUnit': selectedOption?.sellingPriceUnit,
            'manageStock': selectedOption?.manageStock,
            'stock': selectedOption?.stock,
            'backordersAllowed': selectedOption?.backordersAllowed,
            'vat': selectedOption?.vat ? {
                id: selectedOption?.vat.id,
                value: selectedOption?.vat.value
            } : null,
        })
    } else {
        emits('update:product', null)
    }
}

const updateBadge = () => {
    nextTick(() => {

        // Get placeholder
        const placeholder = productSelectRef.value.$el.querySelector('.el-select__placeholder')
        if (!placeholder) return

        // Check if current product is new
        const selectedOption = productOptions.value.find(opt => opt.value === product.value)
        if (product.value && selectedOption && selectedOption.isNew) {

            // Add classes
            placeholder.classList.add('d-flex', 'align-items-center', 'justify-content-between')

            // Check if badge exist
            const existingBadge = placeholder.querySelector('.new-badge')
            if (existingBadge) {
                return
            }

            // Add new badge
            const newBadge = document.createElement('span')
            newBadge.className = 'new-badge ms-2 badge badge-sm badge-primary'
            newBadge.textContent = 'New'
            placeholder.appendChild(newBadge)

            return
        }

        // Remove existing badge
        const existingBadge = placeholder.querySelector('.new-badge')
        if (existingBadge) {
            placeholder.classList.remove('d-flex', 'align-items-center', 'justify-content-between')
            existingBadge.remove()
        }

    })
}


// Define watchers
watch(() => props.product, async (value) => {

    if (value) {

        if (value === product.value){
            return;
        }

        loading.value = true
        productOptions.value = await fetchProducts(`product=${value}`)
        loading.value = false
    }

    updateProduct(value)

})


// Define on mounted
onMounted(async () => {

    if (props.product) {
        loading.value = true
        productOptions.value = await fetchProducts(`product=${props.product}`)
        loading.value = false
        updateProduct(props.product)
    }

})

</script>
