<template>

    <label class="form-label fs-7 required">Type</label>

    <el-select
        v-model="type"
        filterable
        reserve-keyword
        placeholder="Type..."
    >
        <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
        >{{ item.label }}
        </el-option>
    </el-select>

</template>

<script setup>
import { ref, watch, onMounted } from 'vue'

// Define props and emits
const props = defineProps({
    fetchUrl: String,
    type: String,
})

const emits = defineEmits(['update:type', 'update:multipleTypes'])

// Define reactive properties using ref
const type = ref(props.type || '')

// Watch for changes in formData and emit them
watch(() => type.value, (newType) => {
    emits('update:type', newType)
})

/**
 * Remote fetch
 * */
const options = ref([])

// Fetch users data
onMounted(async () => {
    if (props.fetchUrl) {
        try {
            const response = await fetch(props.fetchUrl)
            if (response.ok) {
                const data = await response.json()
                if (data.length > 1) {
                    options.value = data.map(item => ({
                        value: item.id,
                        label: item.name,
                    }))
                } else if (data.length === 1) {
                    emits('update:type', data[0].id)
                    emits('update:multipleTypes', false)
                }
            } else {
                console.error('Fetch failed:', response.statusText)
            }
        } catch (error) {
            console.error('Fetch error:', error)
        }
    }
})
</script>
