<script setup>

</script>

<template>

    <div id="aside" class="d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2">

        <div class="d-flex flex-column flex-center py-7 py-lg-15 px-5 px-md-15 w-100">

            <div class="mb-0 mb-lg-12">
                <img alt="Logo" src="/assets/media/logos/default_wit.svg" class="h-60px h-lg-75px" />
            </div>

            <img class="d-none d-lg-block mx-auto w-275px w-md-50 w-xl-500px mb-10 mb-lg-20" src="/assets/media/svg/auth.svg" alt="" />

            <h1 class="d-none d-lg-block text-white fs-2qx fw-bolder text-center mb-7">Everything on one place</h1>

            <div class="d-none d-lg-block text-white fs-base text-center">The central place for all your daily work processes. Work from one online environment and easily manage all your relationships.</div>


        </div>

    </div>

</template>

<style scoped>
#aside{
    background-image: url('/assets/media/backgrounds/default.jpg')
}
</style>
