<template>

    <div class="app-navbar-item ms-1 ms-md-4" id="kt_header_user_menu_toggle">

        <div
            class="cursor-pointer symbol symbol-35px"
             data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
             data-kt-menu-attach="parent"
             data-kt-menu-placement="bottom-end"
        >
            <img v-if="$page.props.auth.user.image" :src="$page.props.auth.user.image" class="rounded-3" />
            <span v-else class="symbol-label fs-6 fw-semibold text-primary bg-light-primary text-uppercase rounded-3">
                {{ $page.props.auth.user.initials }}
            </span>
        </div>

        <div
            class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-color fw-semibold py-4 fs-6 w-275px"
            data-kt-menu="true"
        >

            <div class="menu-item px-3">

                <div class="menu-content d-flex align-items-center px-3">

                    <div class="symbol symbol-50px me-5 overflow-hidden">
                        <img v-if="$page.props.auth.user.image" :src="$page.props.auth.user.image" class="rounded-3" />
                        <span v-else class="symbol-label fs-6 fw-semibold text-primary bg-light-primary text-uppercase rounded-3">
                            {{ $page.props.auth.user.initials }}
                        </span>
                    </div>

                    <div class="d-flex flex-column">
                        <div class="fw-bold d-flex align-items-center fs-5 text-capitalize">
                            {{ $page.props.auth.user.first_name + ' ' + $page.props.auth.user.last_name }}
                        </div>
                        <span class="fw-semibold text-muted fs-7">{{ $page.props.auth.user.email }}</span>
                    </div>

                </div>
            </div>

            <div class="separator my-2"></div>

            <div v-if="!trialExpired" class="menu-item px-5">
                <Link href="/profile" class="menu-link px-5">My Profile</Link>
            </div>

            <div v-if="!trialExpired" class="menu-item px-5">
                <Link href="/colleagues" class="menu-link px-5">My Colleagues</Link>
            </div>

            <div v-if="!trialExpired" class="separator my-2"></div>

            <ThemeMode />

            <div class="menu-item px-5">
                <Link href="/logout" method="post" class="menu-link px-5">Sign Out</Link>
            </div>

        </div>

    </div>
</template>

<script setup>
import ThemeMode from "@/Layouts/App/Layout/Header/Navbar/UserMenu/ThemeMode.vue";
import { usePage } from '@inertiajs/vue3'
import { computed } from 'vue'

// Define variables
const page = usePage();

// Define computed
const trialExpired = computed(() => {
    return page.props.auth.account.subscription.status === 'trial_expired'
})

</script>
