<template>

    <label class="form-label fs-7">Work type</label>

    <el-select
        v-model="workType"
        filterable
        placeholder="Select work type..."
    >
        <el-option
            v-for="option in options"
            :key="option.value"
            :label="option.label"
            :value="option.value"
        >{{ option.label }}</el-option>
    </el-select>

</template>

<script setup>
import {ref, watch, onMounted} from 'vue'


// Define props
const props = defineProps({
    fetchUrl: String,
    workType: String,
})


// Define emits
const emits = defineEmits(['update:workType'])


// Define refs
const workType = ref(props.workType || '')
const options = ref([])


// Define watchers
watch(() => props.workType, (newWorkType) => {
    workType.value = newWorkType
})
watch(() => workType.value, (newWorkType) => {
    emits('update:workType', newWorkType)
})


// Define on mounts
onMounted(async () => {
    if (props.fetchUrl) {
        try {
            const response = await fetch(props.fetchUrl)
            if (response.ok) {
                const data = await response.json()
                options.value = data.map(item => ({
                    value: item.id,
                    label: item.name,
                }))
            } else {
                console.error('Fetch failed:', response.statusText)
            }
        } catch (error) {
            console.error('Fetch error:', error)
        }
    }
})

</script>

