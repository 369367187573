<template>

    <label class="form-label fs-7 required">Number</label>

    <input
        v-model="number"
        type="text"
        class="form-control form-control-sm"
        placeholder="Enter number..."
    />

</template>

<script setup>
import { ref, watch } from 'vue'


// Define props
const props = defineProps({
    number: String,
})


// Define emits
const emits = defineEmits(['update:number'])


// Define refs
let number = ref(props.number || '')


// Update the local number when the prop changes
watch(() => props.number, (newNumber) => {
    number.value = newNumber
})


// Watch for changes and emit them
watch(() => number.value, (newNumber) => {
    emits('update:number', newNumber)
})
</script>
